import * as RegisterInput from './register-input'

export interface RegisterCommand extends RegisterInput.RegisterInput {
}

export enum RegisterCommandResultCode {
  registrationSucceeded,
  registrationAndEnrollmentSucceeded,
  validationError
}

export interface RegisterCommandResult {
  code: RegisterCommandResultCode,
  validation?: RegisterInput.RegisterValidationResult
}
