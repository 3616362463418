import * as Api from '../api'

export function numberErrorDisplay (error: string) {
  let asString: Api.NumberValidationResult = error as Api.NumberValidationResult
  if (asString === 'cannot-parse') {
    return 'A megadott számformátum nem megfelelő'
  } else if (asString === 'smaller-than-min') {
    return 'A megadott szám kisebb, mint a megengedett minimum'
  } else if (asString === 'larger-than-max') {
    return 'A megadott szám nagyobb, mint a megengedett maximum'
  }

  return null
}
