import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as CourseCodeApi from 'course-code/api'
import { fontAwesome } from 'ui/icon'
import * as Rx from 'rxjs'
import * as Validation from 'validation/ui'
import * as ErrorHandler from 'error-handler/ui'
import { NavLink } from 'react-router-dom'

const VALIDATE_COURSE_CODE_MUTATION =
  gql`mutation validateCourseCode($code: String!) { validateCourseCode(code: $code) {
    code
    courseName
    courseType
    validation
  } }
  `

interface ValidateCourseCodeResponse {
  validateCourseCode: CourseCodeApi.ValidateCourseCodeCommandResult
}

type ValidateCourseCodeVariables = CourseCodeApi.ValidateCourseCodeCommand

export enum ChangeType {
  validCode,
  invalidCode
}

export interface ChangeParams {
  type: ChangeType
  courseType?: string
}

type InputProps = {
  field: string
  formApi: Form.FormApi<string>
  onChange? (params: ChangeParams): void
}
type Props = ReactApollo.ChildMutateProps<InputProps, ValidateCourseCodeResponse, ValidateCourseCodeVariables>

enum ValidationResultType {
  none,
  success,
  error
}

interface ValidationResult {
  type: ValidationResultType
  result?: CourseCodeApi.ValidateCourseCodeCommandResult
}

function emptyValidationResult () {
  return {
    type: ValidationResultType.none,
    result: undefined
  }
}

interface State {
  isValidating: boolean
  result: ValidationResult
}

class CourseCodeFieldsBase extends React.Component<Props, State> {

  private codeSubject: Rx.Subject<string>
  private validationStartObservable: Rx.Observable<string>
  private validatedCodeObservable: Rx.Observable<ValidationResult>

  private validationStartSubscribtion: Rx.Subscription
  private validatedSubscribtion: Rx.Subscription

  constructor (props: Props) {
    super(props)

    this.state = {
      isValidating: false,
      result: emptyValidationResult()
    }
    this.codeSubject = new Rx.Subject<string>()
    this.createObservables()
  }

  componentDidMount () {

    // when the validation starts
    this.validationStartSubscribtion = this.validationStartObservable.subscribe((x) => {
      this.setState({
        isValidating: true,
        result: emptyValidationResult()
      })

      this.clearValidationResult()
    })

    // when the validation finished
    this.validatedSubscribtion = this.validatedCodeObservable.subscribe((x) => {

      this.setState({
        isValidating: false,
        result: x
      })

      this.showValidationResult(x)
      this.sendOnChange(x)
    })
  }

  componentWillUnmount () {
    this.validatedSubscribtion.unsubscribe()
    this.validationStartSubscribtion.unsubscribe()
  }

  render () {
    return (
      <React.Fragment>
        <Form.InputField
          id={this.props.field}
          field="courseCode"
          label="Kurzuskód:"
          type="text"
          rightIcon={this.rightIcon()}
          horizontal={true}
          placeholder="Kurzuskód"
          help={this.help()}
          autoComplete="off"
          onChange={this.handleChange.bind(this)}
          validate={Validation.composeValidator(Validation.requiredValidator, this.validate.bind(this))}
          validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay, this.validationError)}
        />
      </React.Fragment>)
  }

  private sendOnChange (validationResult: ValidationResult) {

    if (!this.props.onChange) {
      return
    }

    if (validationResult.type !== ValidationResultType.success) {
      // no result arrived
      this.props.onChange({ type: ChangeType.invalidCode })
      return
    }

    let result = validationResult.result!
    switch (result.code) {
      case CourseCodeApi.ValidateCourseCodeCommandResultCode.ok:
        this.props.onChange({ type: ChangeType.validCode, courseType: result.courseType })
        break
      default:
        this.props.onChange({ type: ChangeType.invalidCode })
    }
  }

  private validationResult (validationResult: ValidationResult) {

    if (validationResult.type !== ValidationResultType.success) {
      return { success: null, error: null }
    }

    switch (validationResult.result!.code) {
      case CourseCodeApi.ValidateCourseCodeCommandResultCode.ok:
        return { success: 'A megadott kód helyes', error: null }
      case CourseCodeApi.ValidateCourseCodeCommandResultCode.validationError:
        return { success: null, error: validationResult.result!.validation! }
    }
  }

  private validationError (code: CourseCodeApi.CourseCodeValidationResult | string) {

    switch (code) {
      case 'invalid-format':
        return <span>A megadott kód formátuma helytelen</span>
      case 'already-used':
        return <span>A megadott kurzuskódot már felhasználták. Amennyiben Ön használta fel, kérem <NavLink to="/belepes">jelentkezzen be</NavLink>!</span>
      case 'not-found-code':
        return <span>A megadott kód helytelen, kérem, ellenőrizze</span>
      case 'code-expired':
        return <span>A megadott kurzuskód felhasználási határideje lejárt, kérem, lépjen kapcsolatba munkatársunkkal a <NavLink to="/kapcsolat">Kapcsolat</NavLink> oldalon!</span>
      default:
        return code
    }
  }

  private clearValidationResult () {
    let field = this.props.field
    this.props.formApi.setError(field, '')
    this.props.formApi.setWarning(field, '')
    this.props.formApi.setSuccess(field, '')
  }

  private showValidationResult (validationResult: ValidationResult) {

    let field = this.props.field
    this.clearValidationResult()

    let result = this.validationResult(validationResult)
    if (result) {
      if (result.error) {
        this.props.formApi.setError(field, result.error)
      } else if (result.success) {
        this.props.formApi.setSuccess(field, result.success)
      }
    }
  }

  private help () {
    if (this.state.result &&
        this.state.result.type === ValidationResultType.success &&
        this.state.result.result!.code === CourseCodeApi.ValidateCourseCodeCommandResultCode.ok) {
      return <div>
        <p>Kurzusnév: {this.state.result.result!.courseName}</p>
      </div>
    } else {
      return 'Adja meg a 19 karakter hosszú kódot, például: ABCD-1234-5678-9012'
    }
  }

  private handleChange (value: string) {
    if (!value || value.trim().length < 19) {
      this.setState({
        isValidating: false,
        result: emptyValidationResult()
      })
      this.clearValidationResult()
      return
    }
    this.codeSubject.next(value)
  }

  private validate (value: string) {
    if (!value) {
      // empty
      return { error: null, success: null }
    }

    return this.validationResult(this.state.result)
  }

  private rightIcon () {
    if (this.state.isValidating) {
      return fontAwesome('spinner', 'pulse')
    }

    return undefined
  }

  private createObservables () {

    this.validationStartObservable = this.codeSubject.debounceTime(500).distinctUntilChanged()
    this.validatedCodeObservable = this
      .validationStartObservable
      .switchMap(code => {
        return Rx.Observable.create(async (observer: Rx.Observer<ValidationResult>) => {

          try {
            let result = await this.props.mutate({
              variables: { code: code }
            })

            observer.next({ type: ValidationResultType.success, result: result.data.validateCourseCode })

          } catch (err) {
            ErrorHandler.handleMutationError(err)
            observer.next({ type: ValidationResultType.error, result: undefined })
          }

          observer.complete()
        })
      })
  }
}

const withValidateCourseCode = ReactApollo.graphql<InputProps, ValidateCourseCodeResponse, ValidateCourseCodeVariables>(VALIDATE_COURSE_CODE_MUTATION)

export const CourseCodeFields = withValidateCourseCode(CourseCodeFieldsBase)
