import { InputResolver, AsyncInputResolver } from './input-resolver'

export class OptionalInputResolver<TInput, TOutput, TValidationResult> implements InputResolver<TInput | null | undefined, TOutput, TValidationResult> {

  constructor (
    private inputResolver: InputResolver<TInput, TOutput, TValidationResult>,
    private defValue: TOutput) {}

  resolve (input: TInput | null | undefined) {
    if (!input) {
      return this.defValue
    }

    return this.inputResolver.resolve(input)
  }
}

export class AsyncOptionalInputResolver<TInput, TOutput, TValidationResult> implements AsyncInputResolver<TInput | null | undefined, TOutput, TValidationResult> {

  constructor (
    private inputResolver: AsyncInputResolver<TInput, TOutput, TValidationResult>,
    private defValue: TOutput) {}

  async resolve (input: TInput | null | undefined) {
    if (!input) {
      return this.defValue
    }

    return this.inputResolver.resolve(input)
  }
}

export function optional<TInput, TOutput, TValidationResult> (
  inputResolver: InputResolver<TInput, TOutput, TValidationResult>,
  defaultValue: TOutput): InputResolver<TInput | null | undefined, TOutput, TValidationResult> {

  return new OptionalInputResolver(inputResolver, defaultValue)
}

export function asyncOptional<TInput, TOutput, TValidationResult> (
  inputResolver: AsyncInputResolver<TInput, TOutput, TValidationResult>,
  defaultValue: TOutput): AsyncInputResolver<TInput | null | undefined, TOutput, TValidationResult> {

  return new AsyncOptionalInputResolver(inputResolver, defaultValue)
}
