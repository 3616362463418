import * as Raven from 'raven-js'
import { resolveParams } from './resolve-params'
import { sentryUrl } from './sentry-url'
import { isInProductionMode } from 'devmode'

export function configureClientErrorReporter (context: string) {

  let params = { ...resolveParams(),
    captureUnhandledRejections: true
  }

  console.log('context info', params)

  if (!isInProductionMode()) {
    return
  }

  let url = sentryUrl()
  if (url) {
    Raven.config(url, params).install()
  }
}

export function reportClientError (err: Error) {
  if (!isInProductionMode()) {
    return
  }

  Raven.captureException(err)
}
