import * as React from 'react'
import * as PersonName from 'person/ui/name-fields'
import * as PersonBirth from 'person/ui/birth-fields'
import * as PersonMother from 'person/ui/mother-fields'
import * as Form from 'ui/form'
import * as Institution from './institution'
import * as Qualification from './qualification'
import * as Education from './education'
import * as NotificationAddress from './notification-address-fields'
import * as RegistrationNumber from './registration-number-field'

interface Props {
}

export type Model = Qualification.QualificationListModel & {
  name: PersonName.NameModel
  motherName: PersonMother.MotherModel
  birth: PersonBirth.BirthModel
  registrationNumber: string
  institution: Institution.InstitutionModel
  notificationAddress: NotificationAddress.NotificationAddressModel
}

// Health person registration fields
export class HealthPersonFields_ extends React.Component<Form.NestedComponentProps<Model> & Props, {}> {

  render () {

    return <>
      <div className="label">Személyes adatok</div>
      <div className="box">
        <PersonName.NameFields field="name" birthNameVisible={true} />
        <PersonBirth.BirthFields field="birth" isRequired={true} />
        <PersonMother.MotherFields field="motherName" isRequired={true} />
      </div>
      <div className="label">Nyilvántartási szám</div>
      <div className="box">
        <RegistrationNumber.RegistrationNumberField
          field="registrationNumber"
        />
      </div>
      <div className="label">Tanulmányok</div>
      <div className="box">
        <Education.EducationFields field="education" />
      </div>
      <div className="label">Szakképesítés</div>
      <div className="box">
        <Qualification.QualificationListField />
      </div>
      <div className="label">Értesítési cím</div>
      <div className="box">
        <NotificationAddress.NotificationAddressFields field="notificationAddress" />
      </div>
      <div className="label">Foglalkoztató intézmény</div>
      <div className="box">
        <Institution.InstitutionFields
          field="institution"
          isAddressOptional={!this.isNotificationAddressSameAsInstitution()}
        />
      </div>
    </>
  }

  private isNotificationAddressSameAsInstitution() {
    const sameAsInstitution = this.props.fieldApi.getValue(
      this.props.fieldApi.getFullField(['notificationAddress', 'sameAsInstitution']))

    return sameAsInstitution
  }
}

export const HealthPersonFields =
  Form.withNestedField<Props, Model>(HealthPersonFields_)
