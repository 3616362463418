import * as React from 'react'
import * as Form from 'ui/form'
import * as Address from 'address/ui'

export interface NotificationAddressModel {
  sameAsInstitution: boolean
  address?: Address.AddressModel
}

function defaultModel (): NotificationAddressModel {
  return {
    sameAsInstitution: false,
    address: undefined
  }
}

interface PublicProps {}

type Props = Form.NestedComponentProps<NotificationAddressModel> & PublicProps

export class NotificationAddressFields_ extends React.Component<Props> {
  render () {

    return <>
    <Form.CheckboxField
      field="sameAsInstitution"
      label="Értesítési cím:"
      horizontal={true}
    >
      Az értesítési cím megegyezik a foglalkoztató címével
    </Form.CheckboxField>
    {this.address()}
    </>
  }

  componentDidUpdate (prevProps: Props) {

    let api = this.props.fieldApi
    let isSame = this.sameAsInstitution()
    let addressField = api.getFullField('address')
    let address = api.getValue(addressField)

    if (isSame && address !== undefined) {
      api.setError(addressField, undefined)
      api.reset(addressField)
    }
  }

  private address () {
    let sameAsInstitution = this.sameAsInstitution()
    if (sameAsInstitution) {
      return null
    }

    return (
      <Address.AddressFields
        field="address"
        label="Cím:"
      />
    )
  }

  private sameAsInstitution () {
    let api = this.props.fieldApi
    return !!api.getValue(api.getFullField('sameAsInstitution'))
  }
}

export const NotificationAddressFields =
  Form.withNestedField<PublicProps, NotificationAddressModel>(NotificationAddressFields_)
