import gql from 'graphql-tag'
import * as Api from '../api'

export const SAVE_CONFIG_MUTATION =
  gql`mutation saveConfig($input: SaveConfigInput!) {
    saveConfig(input: $input) {
      code, validation
    }
  }
  `

export type SaveConfigVariables = {
  input: Api.SaveConfigCommand
}

export interface SaveConfigResponse {
  saveConfig: Api.SaveConfigCommandResult
}
