// import { EnrollmentCanCloseState } from './enrollment-view'

export interface ReopenEnrollmentCommand {
  enrollmentId: string
}

export enum ReopenEnrollmentCommandResultCode {
  success,
  alreadyOpened
}

export interface ReopenEnrollmentCommandResult {
  code: ReopenEnrollmentCommandResultCode
}
