import gql from 'graphql-tag'

export const addressFragment = gql`
  fragment Address on Address {
    countryId
    cityId
    cityName
    postalCode
    street
    streetTypeId
    houseNumber
    others
  }
`
