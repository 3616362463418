import * as emailAddresses from 'email-addresses'
import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import * as Api from './api'

export class EmailInputResolver implements InputResolver<string, string, Api.EmailValidationResult> {

  resolve (input: string) {

    let result = emailAddresses.parseOneAddress(input)
    if (result && (result.type === 'mailbox' || result.type === 'address')) {
      let parsed = (result as emailAddresses.ParsedMailbox).address
      return parsed
    }

    return new ValidationError<Api.EmailValidationResult>('invalid-email')
  }
}

export function email (): InputResolver<string, string, Api.EmailValidationResult> {

  return new EmailInputResolver()
}
