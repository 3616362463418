import * as React from 'react'
import * as ReactForm from 'react-form'
import { SubmitButton } from './submit-button'
import * as Cancel from 'ui/cancel'

interface Props {
  formApi: ReactForm.FormApi
  cancelDefaultPath?: string
}

export class SubmitCancelButtons extends React.PureComponent<Props> {

  render () {
    return (
      <div className="field is-horizontal">
        <div className="field-label is-normal"></div>
        <div className="field-body">
          <div className="field is-grouped">
            <div className="control">
              <SubmitButton formApi={this.props.formApi}>{this.props.children}</SubmitButton>
            </div>
            <div className="control">
              <Cancel.CancelButton defaultTo={this.props.cancelDefaultPath} additionalClassName="is-link" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
