import * as React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { LocationDescriptor } from 'history'

/**
 * This component can be used to redirect page to the specific url with
 * the specified status
 */
export const RedirectWithStatus = ({ from,
                                     to,
                                     status }: {
                                       from: string,
                                       to: LocationDescriptor,
                                       status: number}) => (

  <Route render={({ staticContext }) => {
      // there is no `staticContext` on the client, so
      // we need to guard against that here
    if (staticContext) {
      staticContext.status = status
    }

    return <Redirect from={from} to={to} />
  }}/>
)
