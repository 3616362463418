import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as CountriesQuery from './countries.query'

import * as Form from 'ui/form'

type InputProps = Form.ComponentProps<string>

type Props = ReactApollo.ChildDataProps<InputProps, CountriesQuery.CountriesResponse, CountriesQuery.CountriesVariables>

class CountryField_ extends React.Component<Props> {

  render () {

    return (
      <Form.SelectFieldControl
        fieldApi={this.props.fieldApi}
        options={this.options()}
      />
    )
  }

  private options () {
    if (this.props.data === undefined || !this.props.data.countries) {
      return []
    }

    return this.props.data.countries
               .map(x => ({ value: x.id, label: x.label }))
  }
}

const withCountries = ReactApollo.graphql<InputProps, CountriesQuery.CountriesResponse, CountriesQuery.CountriesVariables>(CountriesQuery.COUNTRIES_QUERY, {
  options: (props) => ({ variables: { filter: {} } })
})

const CountryFieldWithData = withCountries(CountryField_)

const withDecorator = Form.withFieldDecorator(CountryFieldWithData)
export const CountryField = Form.withField<Form.FieldLayout, string>(withDecorator)
