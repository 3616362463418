import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as Validation from 'validation/ui'

import { CountryField } from './country-field'
import { COUNTRIES_QUERY, CountriesResponse, CountriesVariables } from './countries.query'
import { StreetTypeField } from './street-type-field'
import { ResolveCityField } from './resolve-city-field'

enum AddressType {
  hungary,
  other
}

interface PublicProps {
  label: string
  isOptional?: boolean
}

export interface AddressModel {
  countryId: string
  postalCode: string
  cityId: string
  cityName: string
  street: string
  streetTypeId: string
  houseNumber: string
  others: string
}

type InputProps = Form.NestedComponentProps<AddressModel> & PublicProps
type Props = InputProps & {
  hungaryData: ReactApollo.DataValue<CountriesResponse, CountriesVariables>
}

class AddressFields_ extends React.Component<Props> {

  componentWillReceiveProps (nextProps: Props) {

    let api = this.props.fieldApi
    let countryIdField = api.getFullField('countryId')
    let currentValue = api.getValue(countryIdField)

    if (!currentValue && this.hungaryId(nextProps)) {
      // set hungary
      this.props.fieldApi.setValue(countryIdField, this.hungaryId(nextProps))
    }
  }

  render () {

    let isHungary = this.currentAddressType() === AddressType.hungary

    let requiredValidator = this.props.isOptional ?
                            Validation.onlyServerValidator : Validation.requiredValidator

    return <>
      {
        this.addressSimpleLayout(this.props.label,
                                 <CountryField
                                   field="countryId"
                                   label="Ország"
                                   validate={requiredValidator}
                                   validationErrorDisplay={Validation.requiredErrorDisplay}
                                 />
        )
      }

      <div className="field is-horizontal">
        <div className="field-label">
          <div className="label">
          </div>
        </div>
        <div className="field-body">
          <div className="field">

            <div className="field is-grouped is-grouped-multiline">

              <div className="control" style={{ 'maxWidth': isHungary ? '58px' : '95px' }}>
                <Form.InputField
                  field="postalCode"
                  label="Irsz."
                  maxLength={isHungary ? 4 : 8}
                  placeholder="Irsz."
                  validate={requiredValidator}
                  validationErrorDisplay={Validation.requiredErrorDisplay}
                />
              </div>

              <div className="control is-expanded">
                {this.city()}
              </div>

            </div>

          </div>
        </div>
      </div>

      <div className="field is-horizontal">
        <div className="field-label">
          <div className="label">
          </div>
        </div>
        <div className="field-body">
          <div className="field">

            <div className="field is-grouped is-grouped-multiline">

              <div className="control is-expanded">
                <Form.InputField
                  field="street"
                  label="Közterület megnevezése"
                  maxLength={30}
                  placeholder="Közterület megnevezése"
                  validate={requiredValidator}
                  validationErrorDisplay={Validation.compose(Validation.stringErrorDisplay,
                                                             Validation.requiredErrorDisplay)}
                />
              </div>

              <div className="control is-expanded">
                <StreetTypeField
                  field="streetTypeId"
                  label="Közterület jellege"
                // horizontal={true}
                  validate={requiredValidator}
                  validationErrorDisplay={Validation.requiredErrorDisplay}
                />
              </div>

              <div className="control" style={{ 'maxWidth': '105px' }}>
                <Form.InputField
                  field="houseNumber"
                  label="Házszám"
                  maxLength={10}
                // horizontal={true}
                  placeholder="pl. 1."
                  validate={requiredValidator}
                  validationErrorDisplay={Validation.requiredErrorDisplay}
                />
              </div>

              <div className="control" style={{ 'maxWidth': '105px' }}>
                <Form.InputField
                  field="others"
                  label="Emelet / ajtó"
                  maxLength={10}
                // horizontal={true}
                  placeholder="pl. 1/3."
                />
              </div>

            </div>
          </div>
        </div>
      </div>

    </>
  }

  private hungaryId (props: Props): null | string {
    if (!props.hungaryData.countries) {
      return null
    }

    return props.hungaryData.countries[0].id
  }

  private city () {
    switch (this.currentAddressType()) {
      case AddressType.hungary:
        return this.hungarianCity()
      case AddressType.other:
        return this.foreignCity()
    }
  }

  private hungarianCity () {

    let postalCode = this.getFieldValue('postalCode')
    let countryId = this.getFieldValue('countryId')

    return <ResolveCityField
             field="cityId"
             countryId={countryId}
             postalCode={postalCode}
             label="Város"
    // horizontal={true}
             validate={this.props.isOptional ? Validation.onlyServerValidator : Validation.requiredValidator}
             validationErrorDisplay={Validation.requiredErrorDisplay}
    />
  }

  private getFieldValue (field: keyof AddressModel) {
    return this.props.fieldApi.getValue(this.props.fieldApi.getFullField(field))
  }

  private foreignCity () {

    return <Form.InputField
             field="cityName"
             label="Város"
             maxLength={30}
    // horizontal={true}
             validate={this.props.isOptional ? Validation.onlyServerValidator : Validation.requiredValidator}
             validationErrorDisplay={Validation.compose(Validation.stringErrorDisplay,
                                                        Validation.requiredErrorDisplay)}
    />
  }

  private currentAddressType () {
    let hungaryId = this.hungaryId(this.props)
    let currentCountryId = this.getFieldValue('countryId')
    if (currentCountryId && hungaryId) {
      return currentCountryId === hungaryId ? AddressType.hungary : AddressType.other
    }

    // the default is hungary
    return AddressType.hungary
  }

  private addressSimpleLayout (label: string, component: any) {
    return <>
      <div className="field is-horizontal">
        <div className="field-label">
          <div className="label">
            { label }
          </div>
        </div>
        <div className="field-body">
          <div className="field">
            { component }
          </div>
        </div>
      </div>
    </>
  }
}

const WithData =
  ReactApollo.graphql<InputProps, CountriesResponse, CountriesVariables>(
    COUNTRIES_QUERY, {
      name: 'hungaryData',
      options: (props) => ({ variables: { filter: { machineName: 'Hungary' } } })
    })(AddressFields_)

export const AddressFields = Form.withNestedField<PublicProps, AddressModel>(WithData)
