import * as React from 'react'
import * as Router from 'react-router'
import * as RouterDom from 'react-router-dom'
import * as classNames from 'classnames'

type Props = {
  defaultTo?: string
  additionalClassName?: string
  isFullWidth?: boolean
} & Router.RouteComponentProps<any>

class CancelButton_ extends React.Component<Props, {}> {

  render () {

    let target = (this.props.location.state &&
                  this.props.location.state.cancelReferrer)

    let classes = classNames(
      'button',
      this.props.additionalClassName,
      {
        'is-fullwidth': this.props.isFullWidth
      }
    )

    if (this.props.history.length <= 2) {
      // empty history, use default
      target = this.props.defaultTo
    }

    if (target) {
      // we have link
      return <RouterDom.Link
               className={classes} to={target}>
        Mégsem
      </RouterDom.Link>
    } else {
      return <button className={classes} type="button" onClick={this.goBack.bind(this)}>Mégsem</button>
    }
  }

  private goBack () {
    this.props.history.goBack()
  }
}

export const CancelButton = Router.withRouter<Props>(CancelButton_)
