import gql from 'graphql-tag'
import * as Api from '../../api'

export const SURGERIES_QUERY =
      gql`
query {
  surgeries {
    id, label
  }
}`

export type SurgeriesResponse = {
  surgeries: Api.SurgeriesQueryResult
}
