import { lazyInject } from 'ui/di'
import { ApolloClient } from 'apollo-client'
import { UIConfig } from 'ui-config/ui'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import * as Api from '../api'
import * as Router from 'react-router'
import { NavLink } from 'react-router-dom'
import * as ErrorHandler from 'error-handler/ui'

enum Phase {
  none,
  confirming,
  error,
  confirmed
}

interface State {
  phase: Phase
  reason: Api.ConfirmResultCode
}

interface Props {
  client: ApolloClient<any>
}

export class Confirm_ extends React.Component<Props & Router.RouteComponentProps<any>, State> {

  @lazyInject('UIConfig')
  private config: UIConfig

  constructor (props: any) {
    super(props)

    this.state = {
      phase: Phase.none,
      reason: Api.ConfirmResultCode.success
    }
  }

  async componentDidMount () {
    this.setState({
      phase: Phase.confirming,
      reason: Api.ConfirmResultCode.success
    })

    try {
      let response = await fetch(this.config.publicUrl + '/confirm/' + this.props.match.params.token, {
        credentials: 'same-origin',
        method: 'POST'
      })

      if (response.ok) {

        let json = await response.json()

        if (json.success) {
          this.setState({
            phase: Phase.confirmed,
            reason: Api.ConfirmResultCode.success
          })

          await this.props.client.resetStore()

          return
        }

        let reason = json.reason as Api.ConfirmResultCode
        this.setState({
          phase: Phase.error,
          reason: reason
        })

        return
      }

      ErrorHandler.handlePostError(response)

    } catch (err) {
      ErrorHandler.handlePostError(err)
    }
  }

  render () {

    return <>
      <Breadcrumb end="Fiók aktiválás" />
      <PageTitle title="Fiók aktiválás" />

      <section className="section">
        {this.content()}
      </section>
    </>
  }

  private content () {

    switch (this.state.phase) {

      case Phase.confirming:
        return this.confirming()
      case Phase.confirmed:
        return this.confirmed()
      case Phase.error:
        return this.error()
      default:
        return null
    }
  }

  private confirming () {
    return <div className="ui active centered inline loader"></div>
  }

  private confirmed () {
    return (
      <article className="message is-success">
        <div className="message-header">
          <p>Fiók aktiválás</p>
        </div>
        <div className="message-body">
          <p>Sikeresen aktiválta fiókját, be is jelentkeztettük.</p>
          <p>A <NavLink to="/kurzusaim">Kurzusaim</NavLink> oldalon érheti el a kurzusait.</p>
        </div>
      </article>
    )
  }

  private error () {

    let message = null
    switch (this.state.reason) {
      case Api.ConfirmResultCode.invalidToken:
        return (
          <div className="message is-warning">
            <div className="message-header">
              Nem megfelelő megerősítő link
            </div>
            <div className="message-body">
              Kérem, ellenőrizze, hogy a teljes linket másolta-e ki az email-ből.
            </div>
          </div>)
      case Api.ConfirmResultCode.alreadyConfirmed:
        return (
          <div className="message is-info">
            <div className="message-header">
              Már megerősítette ezt az email címet.
            </div>
            <div className="message-body">
              <NavLink to="/belepes">Bejelentkezem</NavLink>
            </div>
          </div>
        )
      default:
        throw new Error('Invalid result: ' + this.state.reason)
    }
  }
}

export const Confirm = ReactApollo.withApollo(Confirm_)
