import gql from 'graphql-tag'
import * as Api from '../api'

export const COUNTRIES_QUERY =
      gql`
query countries($filter: CountryFilter!) {
  countries(filter: $filter) {
    id, label
  }
}`

export interface CountriesVariables {
  filter: {
    id?: string
    machineName?: string
  }
}

export type CountriesResponse = {
  countries: Api.CountriesQueryResult
}
