import gql from 'graphql-tag'
import * as AddressUI from 'address/ui'

export const institutionFragment = gql`
  fragment Institution on Institution {
    name
    operationArea {
      operationAreaId
      otherOperationArea
      surgeryId
    }
    address {
      ...Address
    }
  }
  ${AddressUI.addressFragment}
`
