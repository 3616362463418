import { CourseCodeInput } from './course-code-input'
import { CourseCodeValidationResult } from './course-code-validation-result'

export interface ValidateCourseCodeCommand {
  code: CourseCodeInput
}

export enum ValidateCourseCodeCommandResultCode {
  ok,
  validationError
}

export interface ValidateCourseCodeCommandResult {
  code: ValidateCourseCodeCommandResultCode
  validation?: CourseCodeValidationResult
  courseName?: string
  courseType?: string
}
