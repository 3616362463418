import * as React from 'react'
import { Route, NavLink, Switch } from 'react-router-dom'
import * as moment from 'moment'
import { UIConfig } from 'ui-config/ui'
import { lazyInject } from 'ui/di'

import { Login } from 'auth/ui/login'
import { ForgotPassword, Confirm, ResetPassword } from 'account/ui'
import { FrontendErrorBoundary } from './frontend-error-boundary'
import { Registration } from 'registration/ui/register'
import { NotFound } from './not-found'
import { NavBar, NavMenu, PrivacyPolicy, ServiceAgreement, NewsLetter, Partners, AboutUs, Articles, Home, ContactPolicy } from 'frontend'
import { Contact } from 'contact/ui/frontend'
import { Imprint } from 'imprint/ui/frontend'
import * as Toast from 'ui/toast'
import { MyCourses } from 'course-enrollment/ui'
import { CoursePage } from 'course/ui/frontend'
import { MyProfile } from 'user-profile/ui/frontend'
import { EnrollUsingCode } from 'course-code-enrollment/ui/frontend'
import { Header } from './header'
import { Helmet } from 'react-helmet'
import { SiteMeta } from 'ui/site-meta'

enum DisplayMode {
  mobile,
  tablet
}

// configure moment
moment.locale('hu')

const logo = require('./images/site-share-image.png')

export class FrontendApp extends React.Component<{}, {}> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {
    return (
      <FrontendErrorBoundary>
        <SiteMeta
          siteTitle="képzés-központ"
          title="Kezdőlap"
          type="website"
          image={`${this.config.publicUrl}/${logo}`}
        />
        <Helmet titleTemplate={`%s | képzés-központ`} />
        <Helmet>
          <title>Kezdőlap</title>
        </Helmet>
        <Helmet>
          <script async src="https://www.googletagmanager.com/gtag/js?id=G-PLJZEG7SJS"></script>
          <script>
          {`
           window.dataLayer = window.dataLayer || [];
           function gtag(){dataLayer.push(arguments);}
           gtag('js', new Date());

           gtag('config', 'G-PLJZEG7SJS');
          `}
          </script>
        </Helmet>
        {this.topMenu()}
        <section className="container">
          <div>
            <Toast.ToastContainer
              position={Toast.toast.POSITION.TOP_CENTER}
              hideProgressBar
            />
            {this.upperMenu()}
            {this.header()}
            <section className="container">
              <Switch>
                <Route path="/belepes" component={Login} />
                <Route path="/elfelejtett-jelszo" component={ForgotPassword} />
                <Route path="/jelszo-visszaallitas/:token" component={ResetPassword} />
                <Route path="/aktivalas/:token" component={Confirm} />
                <Route path="/regisztracio" component={Registration} />
                <Route path="/kurzus-regisztracio" component={EnrollUsingCode} />
                <Route path="/kapcsolat" component={Contact} />
                <Route path="/impresszum" component={Imprint} />
                <Route path="/adatkezeles" component={PrivacyPolicy} />
                <Route path="/szolgaltatasi-szerzodes" component={ServiceAgreement} />
                <Route path="/hirlevel-tajekoztato" component={NewsLetter} />
                <Route path="/adatkezeles-kapcsolat" component={ContactPolicy} />
                <Route path="/partnereink" component={Partners} />
                <Route path="/rolunk" component={AboutUs} />
                <Route path="/cikkek" component={Articles} />
                <Route path="/kurzus/:idOrAlias" component={CoursePage} />
                <Route path="/kurzusaim" component={MyCourses} />
                <Route path="/adataim" component={MyProfile} />
                {/* <Route path="/help" component={Help} /> */}
                <Route exact path="/" component={Home} />
                <Route component={NotFound} />
              </Switch>
            </section>
          </div>
        </section>
        {this.footer(DisplayMode.mobile)}
        {this.footer(DisplayMode.tablet)}
      </FrontendErrorBoundary>
    )
  }

  private topMenu () {
    return (
      <div className="page-top-bar">
        <div className="container">
          <NavBar />
        </div>
      </div>
    )
  }

  private upperMenu () {
    return (
      <NavMenu />
    )
  }

  private header () {

    return <Header />
  }

  private footer (displayMode: DisplayMode) {
    if (displayMode === DisplayMode.mobile) {
      return this.footerMobile()
    }

    return this.footerNotMobile()
  }

  private footerMobile () {
    return (
      <div className="page-footer-bar is-hidden-tablet">
        <div className="container">
          <footer className="columns">
            <div className="column">
              <div className="columns is-mobile is-pulled-right">
                <div className="column is-narrow">
                  <NavLink to="/impresszum" className="footer-menu-item">Impresszum</NavLink>
                </div>
                <div className="column is-narrow">
                  <NavLink to="/adatkezeles" className="footer-menu-item">Adatkezelés</NavLink>
                </div>
                <div className="column is-narrow">
                  <NavLink to="/kapcsolat" className="footer-menu-item">Kapcsolat</NavLink>
                </div>
              </div>
            </div>
            <div className="column is-narrow is-pulled-right has-text-right">
              Eventus Coaching Centrum Kft. © Copyright 2018. <span className="no-wrap-rule">Minden jog fenntartva.</span>
            </div>
          </footer>
        </div>
      </div>
    )
  }

  private footerNotMobile () {
    return (
      <div className="page-footer-bar is-hidden-mobile">
        <div className="container">
          <footer className="columns">
            <div className="column">
              <div className="columns is-mobile">
                <div className="column is-narrow">
                  <NavLink to="/impresszum" className="footer-menu-item">Impresszum</NavLink>
                </div>
                <div className="column is-narrow footer-menu">
                  <NavLink to="/adatkezeles" className="footer-menu-item">Adatkezelés</NavLink>
                </div>
                <div className="column is-narrow footer-menu">
                  <NavLink to="/kapcsolat" className="footer-menu-item">Kapcsolat</NavLink>
                </div>
               </div>
            </div>
            <div className="column is-pulled-right has-text-right">
              Eventus Coaching Centrum Kft. © Copyright 2019. <span className="no-wrap-rule">Minden jog fenntartva.</span>
            </div>
          </footer>
        </div>
      </div>
    )
  }
}
