import * as PaginationApi from 'pagination/api'
import { CodesWithEnrollmentsFilter } from './codes-with-enrollments.query'

export interface ExportCodesCommand {
  filter?: CodesWithEnrollmentsFilter
  sortParams?: PaginationApi.SortParams
}

export enum ExportCodesCommandResultCode {
  success
}

export type ExportCodesCommandResult = {
  code: ExportCodesCommandResultCode
  url?: string
}
