import { CourseEnrollmentsFilter } from './course-enrollments.query'

export interface PrintCertificatesCommand {
  courseId: string

  filter?: CourseEnrollmentsFilter
}

export enum PrintCertificatesCommandResultCode {
  success,
  noClosedEnrollment
}

export interface PrintCertificatesCommandResult {
  code: PrintCertificatesCommandResultCode
  url?: string
}
