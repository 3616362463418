import * as React from 'react'
import { ErrorPanel } from './error-panel'

interface Props {
  loading?: boolean
  onRefresh?: () => void
  refreshButton?: React.ReactNode
}

export const ServerErrorDisplay = (props: Props) => {
  return <ErrorPanel
           type="warning"
           title="Hibánkból a kívánt oldal jelenleg nem jelenik meg, elnézését kérjük a kellemetlenségért."
           icon="exclamation-circle"
           loading={props.loading}
           onRefresh={props.onRefresh}
           refreshButton={props.refreshButton}>
        <p>A problémát jeleztük az üzemeltetőink felé.<br/>
        A lenti gombra kattintva frissítheti az oldalt. Ha a hiba csak pillanatnyi, ez megoldja a problémát.</p>
  </ErrorPanel>
}
