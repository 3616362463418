import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'
import { passwordValidationErrorDisplay } from './password-validation-error-display'
import * as Api from '../api'
import { passwordMismatchValidationErrorDisplay } from '.'

export interface ChangePasswordModel {
  password: string
  passwordAgain: string
}

interface Props {
  required: boolean
}

export class ChangePasswordFields_ extends React.Component<Form.NestedComponentProps<ChangePasswordModel> & Props> {

  render () {
    return <>
      <Form.InputField
        label="Jelszó:"
        type="password"
        field="password"
        autoComplete="off"
        autoCorrect="off"
        leftIcon={Icon.fontAwesome('lock')}
        id="password"
        placeholder="Új jelszó"
        horizontal={true}
        validate={this.secondPasswordRequired.bind(this)}
        validationErrorDisplay={
          Validation.compose(
            Validation.requiredErrorDisplay,
            passwordValidationErrorDisplay)}
      />
      <Form.InputField
        label="Jelszó még egyszer:"
        type="password"
        field="passwordAgain"
        autoComplete="off"
        autoCorrect="off"
        leftIcon={Icon.fontAwesome('lock')}
        id="password"
        placeholder="Új jelszó még egyszer"
        horizontal={true}
        validate={this.secondPasswordRequired.bind(this)}
        validationErrorDisplay={
          Validation.compose(
            Validation.requiredErrorDisplay,
            passwordValidationErrorDisplay,
            passwordMismatchValidationErrorDisplay)}
      />
    </>
  }

  private isPasswordSet () {
    let api = this.props.fieldApi
    let password = api.getValue(api.getFullField('password'))
    return password && password.length > 0
  }

  private secondPasswordRequired (input: string) {
    let isSecondRequired = this.props.required || this.isPasswordSet()

    if (isSecondRequired) {
      return Validation.requiredValidator(input)
    }

    return null
  }

}

export function passwordMismatchErrorDisplay (error: string) {
  let asString = error as Api.PasswordMismatchValidationResult
  if (asString === 'passwords-mismatch') {
    return 'A jelszavak nem egyeznek.'
  }

  return null
}

export const ChangePasswordFields =
  Form.withNestedField<Props, ChangePasswordModel>(ChangePasswordFields_)
