import * as React from 'react'
import { setTimeout, clearTimeout } from 'timers'
const FacebookProvider = require('react-facebook').default
const { Like, ShareButton, Page } = require('react-facebook')

interface State {
  visible: boolean
  width: number
}

export class FacebookWidget extends React.Component<{}, State> {

  private timeoutId?: NodeJS.Timer

  constructor (props: any) {
    super(props)
    this.state = {
      visible: true,
      width: 0
    }
  }

  render () {
    if (!this.state.visible) {
      return null
    }

    return <FacebookProvider appId="247009472790347">
      <Page href="https://www.facebook.com/kepzeskozpont" facepile={false} width="500px" />
    </FacebookProvider>
  }

  componentDidMount () {
    window.addEventListener('resize', this.handleResize.bind(this))
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.handleResize.bind(this))
  }

  private handleResize () {

    let window: any = (global as any).window
    let newWidth = this.state.width
    if (window) {
      newWidth = window.innerWidth
    }

    if (this.state.width === 0) {
      this.setState({
        ...this.state,
        width: newWidth
      })

      return
    }

    if (this.state.width === newWidth) {
      return
    }

    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = undefined
    }

    let self = this
    this.timeoutId = setTimeout(() => {

      self.setState({
        width: newWidth,
        visible: false
      })

      self.setState({
        ...this.state,
        visible: true
      })
    }, 300)
  }

}
