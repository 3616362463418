export interface PrintCertificateCommand {
  enrollmentId: string
}

export enum PrintCertificateCommandResultCode {
  success,
  noClosedEnrollment
}

export interface PrintCertificateCommandResult {
  code: PrintCertificateCommandResultCode
  url?: string
}
