import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'

export type ProfessionModel = string

interface Props {
  field: string
}

export class ProfessionFields extends React.Component<Props> {

  render () {
    return <Form.InputField
             field={this.props.field}
             label="Foglalkozás:"
             type="text"
             placeholder="Foglalkozás (opcionális)"
             maxLength={50}
             horizontal
             leftIcon={Icon.fontAwesome('briefcase ')}
             validate={Validation.onlyServerValidator}
             validationErrorDisplay={Validation.compose(Validation.stringErrorDisplay)}
    />
  }
}
