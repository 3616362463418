import { createValidator } from './create-validator'
import { OptionalInputResolver } from '../optional-input-resolver'
import { InputResolver } from '..';
import { Validator } from './compose-validator';

export function optionalValidator<T = string>(wrappedValidator: Validator, defaultValue: T) {

  return (input?: string) => {

    if (!input) {
      return ''
    }

    return wrappedValidator(input)
  }
}
