import * as AuthAclApi from 'auth-acl/api'
import * as AuthApi from 'auth/api'

export enum ErrorType {
  unknown,
  notAuthorized,
  security,
  graphql,
  network
}

export function resolveErrorType (err: any) {

  if (err.networkError) {
    return ErrorType.network
  }

  if (err.graphQLErrors) {
    for (let current of err.graphQLErrors) {
      if (current.message === AuthApi.NOT_AUTHORIZED_ERROR_MESSAGE) {
        return ErrorType.notAuthorized
      }

      if (current.message === AuthAclApi.SECURITY_ERROR_MESSAGE) {
        return ErrorType.security
      }
    }

    // other graphql error
    return ErrorType.graphql
  }

  return ErrorType.unknown
}
