import * as React from 'react'
import * as Api from 'account/api'
import { Link } from 'react-router-dom'

export function emailAlreadyUserErrorDisplay (error: string) {
  let asString = error as Api.EmailAlreadyUsedValidationResult
  if (asString === 'email-already-used') {
    return <div>
      Ez az email cím már használatban van. Kérem, <Link to="/belepes">jelentkezzen be</Link>.
    </div>
  }

  return null
}
