import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import { MenuLinkBottom } from 'frontend/nav-bar/menu-link-bottom'
import * as Api from '../../api'

const FRONTEND_COURSES_QUERY = gql`
  query frontendCourses {
    frontendCourses {
      urlName
      name
    }
  }`

type FrontendCoursesResponse = {
  frontendCourses: Api.FrontendCoursesQueryResult
}

type InputProps = {}
type Props = ReactApollo.ChildDataProps<InputProps, FrontendCoursesResponse>

class CourseMenu_ extends React.PureComponent<Props> {
  render () {
    if (!this.props.data.frontendCourses || this.props.data.loading) {
      return null
    }

    return this.props.data.frontendCourses.map((x, index) => this.item(x, index))
  }

  private item (course: Api.FrontendCourse, key: number) {

    return (
      <MenuLinkBottom to={`/kurzus/${course.urlName}`} key={key}>
        {course.name}
      </MenuLinkBottom>
    )
  }
}

export const CourseMenu = ReactApollo.graphql<InputProps, FrontendCoursesResponse>(FRONTEND_COURSES_QUERY)(CourseMenu_)
