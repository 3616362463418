import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'
import * as Api from '../api'

type GetConfigVariables = Api.GetConfigQuery

interface GetConfigResponse {
  getConfig: Api.GetConfigQueryResult
}

export const GET_CONFIG_QUERY = gql`
  query getConfig($domains: [GetConfigDomainQuery!]!) {
    getConfig(domains: $domains) {
      domain
      entries {
        key
        value
      }
    }
  }
`

export class GetConfigQuery extends ReactApollo.Query<GetConfigResponse, GetConfigVariables> {}
