import * as React from 'react'
import * as Router from 'react-router'
import { LoginPanel, LoginPanelApi } from 'auth/ui/login-panel'
import { UIConfig } from 'ui-config/ui'
import { lazyInject } from 'ui/di'
import { MenuLinkBottom } from './menu-link-bottom'
import { NavBarWithBurger } from 'ui/nav-bar'
import { CourseMenu } from 'course/ui/frontend'
import { NavLink } from 'react-router-dom'
import { LoggedInMenuItems } from './logged-in-menu-items'
import { HelpNavbarItem } from './help-navbar-item'

const imagesPath = require.context('../ui/images')

type InputProps = Router.RouteComponentProps<any>

type Props = InputProps
interface State {
  isHamburgerActive: boolean
}

class NavMenu_ extends React.Component<Props, State> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {
    return (<>
      <NavBarWithBurger className="bottom-menu bottom-menu-gap" brand={this.brand()}>
        <div className="navbar-end is-uppercase">
          <MenuLinkBottom to="/" exact={true}>
            Kezdőlap
          </MenuLinkBottom>
          {this.loggedInMenu()}
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              Kurzusok
            </a>
            <div className="navbar-dropdown">
              <CourseMenu />
            </div>
          </div>
          <div className="navbar-item has-dropdown is-hoverable">
            <a className="navbar-link">
              Referenciák
            </a>
            <div className="navbar-dropdown">
              <MenuLinkBottom to="/rolunk">
                Rólunk
              </MenuLinkBottom>
              <MenuLinkBottom to="/partnereink">
                Partnereink
              </MenuLinkBottom>
              <MenuLinkBottom to="/cikkek">
                Cikkek
              </MenuLinkBottom>
            </div>
          </div>
          <MenuLinkBottom to="/kapcsolat">
            Kapcsolat
          </MenuLinkBottom>
          <HelpNavbarItem className="navbar-item is-hidden-desktop" />
          {/* <MenuLinkBottom to="/utmutato" className="is-hidden-desktop">
              <Icon.Icon name={Icon.fontAwesome('question')} />
              </MenuLinkBottom> */}
        </div>
      </NavBarWithBurger></>
    )
  }

  private loggedInMenu () {
    return <LoginPanel
      loggedInPanel={(api: LoginPanelApi) => {
        return <div className="navbar-item has-dropdown is-hoverable is-hidden-desktop">
          <a className="navbar-link">
            {api.details().fullName}
          </a>
          <div className="navbar-dropdown">
            <LoggedInMenuItems loginPanelApi={api} />
          </div>
        </div>
      }}

    notLoggedInPanel={() => null}
    />
  }

  private brand () {
    return (
      <NavLink to="/" className="logo image">
        <img src={imagesPath('./logo.png')} />
      </NavLink>
    )
  }

}

export const NavMenu = Router.withRouter<any>(NavMenu_)
