import * as React from 'react'
import * as Form from 'ui/form'

import * as CourseCode from 'course-code-enrollment/ui/frontend'
import { GeneralPersonFields } from './general-person-fields'
import { HealthPersonFields } from 'health-person/ui'
import { AccountFields } from './account-fields'
import * as CourseApi from 'course/api'
import * as Validation from 'validation/ui'
import { NavLink } from 'react-router-dom'

enum Phase {
  none,
  validCode,
  invalidCode
}

interface State {
  phase: Phase
  courseType: string | null
}

interface Props {
  formApi: Form.FormApi<any>
}

export class RegistrationForm extends React.Component<Props, State> {

  constructor (props: Props) {
    super(props)

    this.state = {
      phase: Phase.none,
      courseType: null
    }
  }

  render () {

    let formApi = this.props.formApi

    return <form onSubmit={formApi.submitForm}>
      <div className="box">
        <CourseCode.CourseCodeFields
          field="courseCode"
          formApi={formApi}
          onChange={this.handleCourseCodeChange.bind(this)}
        />
      </div>
      <div className="box">
        <AccountFields field="account" />
      </div>
      {this.personFields(formApi.getFormState())}
      <Form.SubmitButton formApi={formApi}>Regisztráció</Form.SubmitButton>
    </form>

  }

  private handleCourseCodeChange (params: CourseCode.ChangeParams) {

    switch (params.type) {
      case CourseCode.ChangeType.validCode:
        // valid code
        this.stepToValidPhase(params.courseType!)
        break
      case CourseCode.ChangeType.invalidCode:
        if (this.state.phase !== Phase.none) {
          // go to became invalid phase
          this.stepToBecameInvalidCodePhase()
        }
        break
    }
    /*
     * switch (this.state.phase) {
     *   case Phase.none:
     *     // we are in the initial phase
     *     switch (params.type) {
     *       case CourseCode.ChangeType.validCode:
     *         // valid code
     *         this.stepToValidPhase(params.courseType!)
     *         break
     *       default:
     *         // NOP
     *     }
     *     break
     *   case Phase.validCode:
     *     // we are in valid code phase
     *     switch (params.type) {
     *       case CourseCode.ChangeType.validCode:
     *         // remain valid, but possibly with other code
     *         this.stepToValidPhase(params.courseType!)
     *         break
     *       case CourseCode.ChangeType.invalidCode:
     *         // go to became invalid phase
     *         this.stepToBecameInvalidCodePhase()
     *         break
     *       default:
     *         // NOP
     *     }
     *     break
     *   case Phase.invalidCode:
     *     // we are in the became invalid code phase
     *     switch (params.type) {
     *       case CourseCode.ChangeType.validCode:
     *         // again it is valid
     *         this.stepToValidPhase(params.courseType!)
     *         break
     *       default:
     *         // NOP
     *     }
     *     break
     * }*/
  }

  private stepToValidPhase (courseType: string) {
    this.setState({ courseType: courseType, phase: Phase.validCode })
    this.clearPersonFieldErrors()
  }

  private stepToBecameInvalidCodePhase () {
    this.setState({ ...this.state, phase: Phase.invalidCode })
  }

  private personFields (model: any) {
    switch (this.state.phase) {
      case Phase.none:
        return null
      case Phase.validCode:
      case Phase.invalidCode:
        return this.personFieldsForCourseType(this.state.courseType!, model)
      default:
        return null
    }
  }

  private clearPersonFieldErrors () {
    this.props.formApi.setError('person', undefined)
    this.props.formApi.setError('healthPerson', undefined)
    this.resetCheckbox('approveContract')
    this.resetCheckbox('approvePrivacyPolicy')
  }

  private resetCheckbox(path: Form.FieldPath) {
    this.props.formApi.setValue(path, false)
    this.props.formApi.reset(path)
  }

  private personFieldsForCourseType (courseType: string, model: any) {

    let personFields: React.ReactNode
    if (CourseApi.isHealthCourseType(courseType)) {
      personFields = <>
        {this.healthInfoBox()}
        <HealthPersonFields field="healthPerson" />
      </>
    } else {
      personFields = <GeneralPersonFields field="person" />
    }

    return (
      <>
        <div className="box">
          {personFields}
        </div>
        {this.policyBoxes(courseType)}
      </>
    )
  }

  private policyBoxes(courseType: string) {
    if (CourseApi.isEducationCourseType(courseType)) {
      return this.educationPolicyBoxes()
    } else {
      return this.defaultPolicyBoxes()
    }
  }

  private defaultPolicyBoxes() {
    let readTheContract = this.readTheContract()

    let approvePrivacyPolicy = this.approvePrivacyPolicy()

    let acceptStoringData = this.acceptStoringDataText()

    return <>
      <Form.ColumnCheckboxField
        field="approveContract"
        label=""
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      >
        {readTheContract}
      </Form.ColumnCheckboxField>
      <Form.ColumnCheckboxField
        field="approvePrivacyPolicy"
        label=""
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      >
        {approvePrivacyPolicy}
      </Form.ColumnCheckboxField>
      <Form.ColumnCheckboxField
        field="acceptStoringData"
        label=""
      >
        {acceptStoringData}
      </Form.ColumnCheckboxField>
    </>
  }

  private educationPolicyBoxes() {
    return <>
      <Form.ColumnCheckboxField
        field="approveContract"
        label=""
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      >
        {this.readTheContract()}
      </Form.ColumnCheckboxField>
      <Form.ColumnCheckboxField
        field="approvePrivacyPolicy"
        label=""
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      >
        {this.educationPrivacyPolicyText()}
      </Form.ColumnCheckboxField>
      <Form.ColumnCheckboxField
        field="acceptStoringData"
        label=""
      >
        {this.acceptStoringDataText()}
      </Form.ColumnCheckboxField>
    </>
  }

  private educationPrivacyPolicyText() {
    return <>
      <p style={{marginBottom: '1em'}}>
        Kijelentem, hogy a képzés szervezője a képzésen való részvételem miatt felmerülő adatkezelésről előzetesen, tömör és érthető adatkezelési tájékoztató átadásával tájékoztatott, ezen adatkezelési tájékoztató rendelkezéseit megismertem, a felmerülő kérdéseimre választ kaptam, továbbá a képzés szervezője a képzésen való részvételem miatt szükséges adatkezeléshez jogalappal rendelkezik,
      </p>
      <p>
        Tudomásul veszem, hogy minden képzési anyag, teszt, kérdőív, képzést segítő, szemléltető eszköz egésze (együttesen: Képzési Anyag), vagy annak bármely része a képzést szervező vagy a webfelületet szolgáltató cég kizárólagos tulajdonát képezi és ezen csak a képzés elvégzéséig terjedő ideig szerzek felhasználási jogot, amely kizárólag saját célú felhasználásra, olvasásra, felkészülésre jogosít, és nem tartalmazza a nyomtatás, terjesztés, átdolgozás, üzleti célú vagy magáncélú, ellenérték fejében, vagy ingyenesen harmadik személy részére történő átadást, hozzáférhetővé tételt, azaz a Képzés saját néven és saját magam javára történő elvégzését meghaladó felhasználásának jogát. Köteles vagyok a képzés minden részletét üzleti titokként kezelni, annak tartalmáról, a Képzési Anyagokról harmadik személy részére felvilágosítást, információt nem vagyok jogosult adni. A jelen pontban írt szerzői jogi szabályok megsértése esetén teljes kártérítési kötelezettséggel tartozom.
      </p>
    </>
  }

  private acceptStoringDataText() {
    return <>
      Hozzárárulok, hogy a személyes adataimat a <NavLink to="/hirlevel-tajekoztato" target="_blank">hírlevél tájékoztatóban</NavLink> írtak szerint az Eventus Coaching Centrum Kft. az általa nyújtott szolgáltatás befejezését követően is tárolja, valamint a jövőben tájékoztatást nyújtson programjairól, tréningjeiről.
    </>
  }

  private readTheContract () {

    return (
      <>
        A Programban történő részvételre irányuló szolgáltatási <NavLink to="/szolgaltatasi-szerzodes" target="_blank">szerződést</NavLink> elolvastam, annak rendelkezéseit megértettem, a szerződési feltételeket elfogadom és magamra nézve kötelezőnek ismerem el.
      </>
    )
  }

  private approvePrivacyPolicy () {

    return (
      <>
        Az <NavLink to="/adatkezeles" target="_blank">adatvédelmi nyilatkozatot</NavLink> megértettem és a személyes adataimnak az adatvédelmi nyilatkozatban írtak szerinti kezeléséhez hozzájárulok.
      </>
    )
  }

  private healthInfoBox () {
    return <article className="message is-primary">
      <div className="message-body">
        A kiválasztott kurzus típusa <strong>szabadon választott kreditpontos képzés egészségügyi szakdolgozók részére</strong>. A <strong>kreditigazolás</strong> kiállításához szükségesek az alábbi adatok, kérjük, adja meg ezeket.
      </div>
    </article>
  }
}
