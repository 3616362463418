import * as React from 'react'
import { ErrorPanel } from './error-panel'
// import { WithAuthStateChildProps, withAuthState } from 'auth/ui/with-auth-state'

type SecurityErrorProps = {
  loading: boolean
  onRefresh: () => void
}

export class SecurityErrorDisplay extends React.Component<SecurityErrorProps> {

  render () {
    return <ErrorPanel
             type="warning"
             title="Nincs joga megtekinteni ezt az oldalt."
             icon="lock"
             loading={this.props.loading}
             onRefresh={this.props.onRefresh}>
      <p>Sajnos nincs joga megtekinteni ezt az oldalt, kérem, nyisson egy másik böngészőfület és jelentkezzen be a megfelelő joggal rendelkező felhasználóval.</p>
      <p>Ezután a lenti gombra kattintva frissítheti az oldalt.</p>
    </ErrorPanel>
  }
}
