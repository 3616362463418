import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'

export const HasRightQuery = gql`query can($action: String!, $subject: String) {
  can(action: $action, subject: $subject)
}`

interface HasRightVariables {
  action: string
  subject?: string
}

export type HasRightResponse = {
  can: boolean
}

export type HasRightChildDataProps = {
  hasRight: ReactApollo.DataValue<HasRightResponse>
}

export function createHasRightQuery<TInput> (action: string, subject?: string) {
  const hasRightQuery = ReactApollo.graphql<TInput, {}, HasRightVariables, HasRightChildDataProps>(HasRightQuery, {
    name: 'hasRight',
    options: (props) => ({
      // fetchPolicy: 'network-only',
      variables: { action: action, subject: subject }
    })
  })
  return hasRightQuery
}
