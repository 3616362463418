import * as React from 'react'
import * as ReactForm from 'react-form'
import * as classNames from 'classnames'

interface Props {
  formApi: ReactForm.FormApi<any>
  additionalClassName?: string
  isFullWidth?: boolean
}

export class SubmitButton extends React.Component<Props, {}> {

  render () {
    let state = this.props.formApi.getFormState()

    let disabled = state.errors !== undefined

    let classes = classNames('button is-primary',
                             this.props.additionalClassName,
      {
        disabled: disabled,
        'is-loading': state.submitting,
        'is-fullwidth': this.props.isFullWidth
      }
    )

    return <button
             disabled={disabled}
             type="submit"
             className={classes}>{this.props.children}</button>
  }
}
