import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'
import { emailAlreadyUserErrorDisplay } from './email-already-used-error-display'
import { passwordValidationErrorDisplay } from 'account/ui'

interface Props {}

export interface Model {
  email: string
  password: string
}

export class AccountFields_ extends React.Component<Form.NestedComponentProps<Model> & Props> {

  render () {
    return <>
    <Form.InputField
      id="email"
      field="email"
      label="E-mail-cím:"
      type="email"
      leftIcon={Icon.fontAwesome('envelope')}
      horizontal={true}
      placeholder="sajat.email@cim.hu"
      validationErrorDisplay={Validation.compose(
          Validation.requiredErrorDisplay,
          Validation.emailErrorDisplay,
          emailAlreadyUserErrorDisplay)}
      validate={Validation.composeValidator(Validation.requiredValidator)}
    />
    <Form.InputField
      label="Jelszó:"
      type="password"
      field="password"
      leftIcon={Icon.fontAwesome('lock')}
      id="password"
      horizontal={true}
      placeholder="jelszó"
      validate={Validation.requiredValidator}
      validationErrorDisplay={
        Validation.compose(
          Validation.requiredErrorDisplay,
          passwordValidationErrorDisplay)}
    />
    </>
  }
}

export const AccountFields =
  Form.withNestedField<Props, Model>(AccountFields_)
