import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import * as Api from './api'

export class NumberInputResolver implements InputResolver<string, number, Api.NumberValidationResult> {

  constructor (private min?: number,
              private max?: number) {
  }

  resolve (input: string) {

    let parsed = parseInt(input, 10)
    if (isNaN(parsed)) {
      return new ValidationError<Api.NumberValidationResult>('cannot-parse')
    }

    if (this.min !== undefined) {
      if (parsed < this.min) {
        return new ValidationError<Api.NumberValidationResult>('smaller-than-min')
      }
    }

    if (this.max !== undefined) {
      if (parsed > this.max) {
        return new ValidationError<Api.NumberValidationResult>('larger-than-max')
      }
    }

    return parsed
  }
}

export function number (
  min?: number,
  max?: number): InputResolver<string, number, Api.NumberValidationResult> {

  return new NumberInputResolver(min, max)
}
