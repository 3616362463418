import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'

export class Articles extends React.Component {

  render () {

    return <div>

      <Breadcrumb parent="Referenciák" end="Cikkek" />

      <div className="articles-page">

        <PageTitle title="Cikkek" />

        <section className="section">

          <div className="content">

            <label className="label">Érthetően Az Egészségért Magazin</label>
            <ul>
              <li><a target="_blank" href={require('./2010_Stressz_es_erzelmi_intelligencia_kapcsolata.pdf')} >2010 /   A stressz és EQ kapcsolata (Dr. Pásztor Dóra, Eventus Kft.)</a></li>
            </ul>
            <label className="label">HR Portál (online)</label>
            <ul>
              <li><a target="_blank" href="https://www.hrportal.hu/hr/hogyan-fejlesztheto-az-erzelmi-intelligencia-20090723.html">2009/07 Hogyan fejleszthető az érzelmi intelligencia (Szilágyi Katalin Nagy Erzsébettel történt riportja alapján)</a></li>
              <li><a target="_blank" href="https://www.hrportal.hu/hr/a-stressz-jelei-a-szervezeten-belul-20101018.html">2010/10 Stressz jelei a szervezetben (Nagy Erzsébet, Eventus Kft.)</a></li>
            </ul>
            <label className="label">Marketing Pirula Magazin</label>
            <ul>
              <li><a target="_blank" href={require('./201007_Interju_az_Eventus_Kft._ugyvezetojevel.pdf')}>2010/07 Interjú az Eventus Kft. ügyvezetőjével</a></li>
              <li><a target="_blank" href={require('./201009_Produktiv_feszultseg.pdf')}>2010/09 Produktív feszültség (Nagy Erzsébet, Eventus Kft.)</a></li>
            </ul>
            <label className="label">Pirula Trend Magazin</label>
            <ul>
              <li><a target="_blank" href={require('./201107_EQ_tudatossag_a_betegellatasban.pdf')}>2011/07 EQ tudatosság a betegellátásban (Nagy Erzsébet, Eventus Kft.)</a></li>
            </ul>
            <label className="label">Patika Tükör Magazin</label>
            <ul>
              <li><a target="_blank" href={require('./201007_Stressz_az_elet_sava-borsa.pdf')}>2010/07 Stressz az élet sava-borsa (Dr. Pásztor Dóra, Eventus Kft.)</a></li>
              <li><a target="_blank" href={require('./201008_A_stressz_es_az_erzelmek.pdf')}>2010/08 A stressz és az érzelmek (Dr. Pásztor Dóra, Eventus Kft.)</a></li>
              <li><a target="_blank" href={require('./201009_A_stressz_es_a_ferfiak.pdf')}>2010/09 A stressz és a férfiak (Dr. Pásztor Dóra, Eventus Kft.)</a></li>
              <li><a target="_blank" href={require('./201010_Az_erzelmi_intelligencia_fejlesztheto.pdf')}>2010/10 Az érzelmi intelligencia fejleszthető (Dr. Pásztor Dóra, Eventus Kft.)</a></li>
            </ul>
          </div>

        </section>

      </div>
    </div>
  }

}
