import * as React from 'react'
import * as ErrorTypeResolver from './resolve-error-type'
import { ServerErrorDisplay } from './server-error-display'
import { NetworkErrorDisplay } from './network-error-display'
import { SecurityErrorDisplay } from './security-error-display'
import { NotAuthorizedHandler } from 'auth/ui'

interface Props {
  error: Error
  loading: boolean
  onRefresh: () => void
}

export class ErrorDisplay extends React.Component<Props> {

  render () {
    let type = ErrorTypeResolver.resolveErrorType(this.props.error)
    switch (type) {
      case ErrorTypeResolver.ErrorType.graphql:
        return this.serverError()
      case ErrorTypeResolver.ErrorType.network:
        return this.networkError()
      case ErrorTypeResolver.ErrorType.security:
        return this.securityError()
      case ErrorTypeResolver.ErrorType.notAuthorized:
        return <NotAuthorizedHandler />
      case ErrorTypeResolver.ErrorType.unknown:
        return this.serverError()
      default:
        return this.serverError()
    }
  }

  private securityError () {
    return <SecurityErrorDisplay
             loading={this.props.loading}
             onRefresh={this.props.onRefresh} />
  }

  private serverError () {
    return <ServerErrorDisplay
             loading={this.props.loading}
             onRefresh={this.props.onRefresh} />
  }

  private networkError () {
    return <NetworkErrorDisplay
             loading={this.props.loading}
             onRefresh={this.props.onRefresh} />
  }
}
