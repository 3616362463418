import * as React from 'react'
import * as ConfigUI from 'config/ui'
import * as ConfigApi from 'config/api'
import * as ImprintApi from 'imprint/api'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
let Mailto: any = require('react-mailto.js').default
import { ScrollToTopOnMount } from 'ui/scroll'

const defaultData = () => ({
  editor: '',
  headquarters: '',
  site: '',
  registrationNumber: '',
  taxNumber: '',
  educationalRegistrationNumber: '',
  manager: '',
  phone: '',
  email: '',
  operator: ''
})

type Props = {}

export class Imprint extends React.Component<Props> {

  render () {
    let keys: ImprintApi.ConfigKeys[] = [
      'editor', 'headquarters', 'site', 'registrationNumber', 'taxNumber',
      'educationalRegistrationNumber', 'manager', 'phone', 'email', 'operator'
    ]
    let variables: ConfigApi.GetConfigQuery = {
      domains: [{
        domain: 'imprint',
        keys: keys
      }]
    }

    return <div>
      <ScrollToTopOnMount />
      <ConfigUI.GetConfigQuery query={ConfigUI.GET_CONFIG_QUERY} variables={variables}>
        {
          ({ loading, error, data }) => {

            let imprint = defaultData()

            if (!loading && data) {
              imprint = ConfigUI.mapConfigResult(data.getConfig, 'imprint', keys)
            }

            if (error) {
              throw error
            }

            return this.imprint(imprint)
          }
        }
      </ConfigUI.GetConfigQuery>
    </div>
  }

  private imprint (config: ImprintApi.PublicConfigModel) {

    let titles = [
      { label: 'A kepzeskozpont.com oldal szerkesztője:', value: config.editor },
      { label: 'Székhely:', value: config.headquarters },
      { label: 'Telephely:', value: config.site },
      { label: 'Cégjegyzékszám:', value: config.registrationNumber },
      { label: 'Adószám:', value: config.taxNumber },
      { label: 'Felnőttképzési nyilvántartási szám:', value: config.educationalRegistrationNumber },
      { label: 'Ügyvezető:', value: config.manager },
      { label: 'Mobilszám:', value: config.phone },
      { label: 'e-mail:', value: <Mailto to = {config.email} secure = {true} >{config.email}</Mailto> }
    ]

    let netingTitles = [{
      label: 'Technikai üzemeltetés:', value: config.operator
    }]

    let items = titles.map((current, index) => {
      let key = `${index}`
      return this.element(current, key)
    })

    let netingItems = netingTitles.map((current, index) => {
      return this.element(current, `${index + titles.length}`)
    })

    return <div>

      <Breadcrumb end="Impresszum" />

      <PageTitle title="Impresszum" />

      <section className="section">
        <div className="imprint-page">
          { this.structure(items, 0) }
          <div className="column" />
          { this.structure(netingItems, 1) }
        </div>
      </section>

    </div>
  }

  private structure (item: any, key: number) {
    return (
      <div className="columns is-centered is-multiline">
        <div className="column">
          {item}
        </div>
      </div>
    )
  }

  private element (item: any, key: string) {

    return (
      <div className="columns is-gapless" key={key}>
        <div className="column">
          <div className="field-label">
            <label className="label">{item.label}</label>
          </div>
        </div>
        <div className="column">
          <div className="field">
            <div className="control">
              <label className="">{item.value}</label>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
