import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'
import * as React from 'react'
import * as Router from 'react-router'
import * as Icon from 'ui/icon'
import { Link } from 'react-router-dom'
import { toast } from 'ui/toast'
import * as Form from 'ui/form'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { passwordValidationErrorDisplay } from './password-validation-error-display'
import * as ErrorHandler from 'error-handler/ui'
import * as Validation from 'validation/ui'
import * as Api from '../api'

interface Model {
  password: string
  passwordAgain: string
}

interface State {
  hasPasswordResetted: boolean
}

interface ResetPasswordVariables {
  input: {
    token: string
    password: string
  }
}

interface ResetPasswordResponse {
  resetPassword: Api.ResetPasswordResult
}

interface ValidatePasswordResetTokenQueryResponse {
  validatePasswordResetToken: Api.ValidatePasswordResetTokenResult
}

interface ValidatePasswordResetTokenVariables {
  token: string
}

type InputProps = Router.RouteComponentProps<{token: string}>
type Props = ReactApollo.ChildDataProps<InputProps, ValidatePasswordResetTokenQueryResponse, ValidatePasswordResetTokenVariables> & ReactApollo.ChildMutateProps<InputProps, ResetPasswordResponse, ResetPasswordVariables>

export class ResetPassword_ extends React.Component<Props, State> {

  constructor (props: any) {
    super(props)

    this.state = {
      hasPasswordResetted: false
    }
  }

  render () {

    return <>
      <Breadcrumb end="Jelszó visszaállítás" />
      <PageTitle title="Jelszó visszaállítás" />

      <section className="section">
        { this.content() }
      </section>
    </>
  }

  private content () {

    if (this.state.hasPasswordResetted) {
      return (
        <div className="message is-success">
          <div className="message-body">
            Sikeres jelszómódosítás, <Link to="/belepes">be tud jelentkezni</Link> az új jelszóval.
          </div>
        </div>
      )
    }

    let content = null

    let isTokenValid = this.props.data.validatePasswordResetToken === Api.ValidatePasswordResetTokenResultCode.success
    if (isTokenValid) {
      // the reset password form
      return this.form()
    }
    return (
      <div className="message is-warning">
        <div className="message-body">
          Ez a jelszó visszaállító link sajnos helytelen vagy lejárt, <Link to="/elfelejtett-jelszo">kérjen újat</Link>.
        </div>
      </div>
    )
  }

  private form () {

    return <>
      <div className="box">
        <Form.Form onSubmit={this.handleSubmit.bind(this)}>
          {formApi => (
            <form onSubmit={formApi.submitForm}>
              <Form.InputField
                label="Jelszó"
                type="password"
                field="password"
                leftIcon={Icon.fontAwesome('lock')}
                id="password"
                horizontal={true}
                validate={Validation.requiredValidator}
                validationErrorDisplay={
                  Validation.compose(
                    Validation.requiredErrorDisplay,
                    passwordValidationErrorDisplay)}
              />
              <Form.InputField
                label="Jelszó még egyszer"
                type="password"
                field="passwordAgain"
                leftIcon={Icon.fontAwesome('lock')}
                id="password"
                horizontal={true}
                validate={Validation.requiredValidator}
                validationErrorDisplay={
                  Validation.compose(
                    Validation.requiredErrorDisplay,
                    passwordValidationErrorDisplay)}
              />

              <div className="field is-horizontal">
                <div className="field-label is-normal">
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control is-expanded">
                      <Form.SubmitButton formApi={formApi}>Jelszómódosítás</Form.SubmitButton>
                    </div>
                  </div>
                </div>
              </div>

            </form>
          )}
        </Form.Form>
      </div>
    </>
  }

  private async handleSubmit (model: Model, e: any, formApi: Form.FormApi<Model>) {

    if (model.password !== model.passwordAgain) {
      formApi.setError('passwordAgain', 'A jelszavak nem egyeznek')
      return
    }

    try {
      let result = await this.props.mutate({
        variables: {
          input: {
            token: this.props.match.params.token,
            password: model.password }
        }
      })

      let code = result.data.resetPassword
      switch (code) {
        case Api.ResetPasswordResultCode.success:
          this.setState({ hasPasswordResetted: true })
          break
        case Api.ResetPasswordResultCode.invalidToken:
          toast.warn('Érvénytelen a jelszó módosító link, kérjen egy újat.')
          break
        case Api.ResetPasswordResultCode.expiredToken:
          toast.warn('Lejárt a jelszó módosító link, kérjen újat.')
          break
        case Api.ResetPasswordResultCode.invalidPassword:
          formApi.setError('password', Api.InvalidPassword)
          break
        default:
          throw new Error('Invalid result: ' + code)
      }
    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }
}

const RESET_PASSWORD_MUTATION =
  gql`mutation resetPassword($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
  `

const VALIDATE_PASSWORD_RESET_TOKEN_QUERY =
  gql`query validatePasswordResetToken($token: String!) {
    validatePasswordResetToken(token: $token)
  }`

const mutation = ReactApollo.graphql<InputProps, ResetPasswordResponse, ResetPasswordVariables>(RESET_PASSWORD_MUTATION)

const query = ReactApollo.graphql<InputProps, ValidatePasswordResetTokenQueryResponse, ValidatePasswordResetTokenVariables>(VALIDATE_PASSWORD_RESET_TOKEN_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only',
    variables: { token: props.match.params.token }
  })
})

export const ResetPassword = Router.withRouter(ReactApollo.compose(query, mutation)(ResetPassword_))
