import * as IoC from 'inversify'

import { UIConfig } from 'ui-config/ui'
import { ApolloClient } from 'apollo-client'
import { onError } from 'apollo-link-error'
import { HttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { concat } from 'apollo-link'

function createHttpLink (publicUrl: string) {
  return new HttpLink(
    { credentials: 'same-origin',
      uri: publicUrl + '/graphql' })
}

function createErrorLink () {

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
                        console.log(
                          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
                        )
                       )
    }

    if (networkError) console.log(`[Network error]: ${networkError}`)
  })

  return errorLink
}

const apolloModule = new IoC.ContainerModule((bind) => {
  bind('ApolloClient').toDynamicValue((context) => {
    let config = context.container.get<UIConfig>('UIConfig')
    const httpLink = createHttpLink(config.publicUrl)
    const errorLink = createErrorLink()

    const cache = new InMemoryCache().restore((window as any).__APOLLO_STATE__)

    const client = new ApolloClient({
      cache: cache,
      link: concat(errorLink, httpLink)
    })

    return client
  })
})

export default apolloModule
