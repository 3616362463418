import * as Api from '../api'

export function stringErrorDisplay (error: string) {
  let asString: Api.StringValidationResult = error as Api.StringValidationResult
  if (asString === 'tooShort') {
    return 'A megadott szöveg rövidebb a megengedettnél'
  } else if (asString === 'tooLong') {
    return 'A megadott szöveg hosszabb a megengedettnél'
  }

  return null
}
