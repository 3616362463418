import gql from 'graphql-tag'
import * as AddressUI from 'address/ui'

export const notificationAddressFragment = gql`
  fragment NotificationAddress on NotificationAddress {
    sameAsInstitution
    address {
      ...Address
    }
  }
  ${AddressUI.addressFragment}
`
