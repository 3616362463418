import * as React from 'react'
import * as Form from 'ui/form'
import * as ItemFields from './profession-group-qualification-fields'

type InputProps = {
}

type Props = Form.WithFormApiComponentProps<QualificationListModel> & InputProps

export interface QualificationListModel {
  qualifications: ItemFields.QualificationModel[]
}

const MAX_QUALIFICATIONS = 3

class QualificationListField_ extends React.Component<Props> {

  componentWillMount () {
    // set the default
    let value = this.props.formApi.getValue(this.fullField())
    if (value === undefined || (value !== undefined && value.length === 0)) {
      this.props.formApi.setValue(this.fullField(), [{}])
    }
  }

  render () {

    return <>
      {this.items()}
      {this.addMoreButton()}
    </>
  }

  private items () {
    let values = this.currentValues()

    return values.map((x, index) => {

      let key = `ql_${index * 2}`
      let field = `qualifications[${index}]`
      let moreInfo = null

      if (index === 1) {
        moreInfo = (
          <div className="message is-info">
            <div className="message-body">
              <div className="">
                Kérjük, adja meg, ha az előbb kiválasztott szakmacsoporton belül van további szakképesítése is! Először válassza ki újra a korábban megadott szakmacsoportot, majd válassza ki az újabb szakképesítést.
              </div>
            </div>
          </div>
        )
      }

      return <React.Fragment key={key}>
        <ItemFields.ProfessionGroupQualificationFields field={field} key={key} moreInfo={moreInfo}>
          {this.removeButton(index)}
        </ItemFields.ProfessionGroupQualificationFields>
      </React.Fragment>
    })
  }

  private removeButton (index: number) {
    if (index === 0) {
      return null
    }

    return (
      <div>
        <button type="button"
          className="button"
          onClick={this.removeClickHandler.bind(this, index)}>Eltávolítás
        </button>
      </div>
    )
  }

  private addMoreButton () {

    let disabled = this.currentValues().length >= MAX_QUALIFICATIONS

    return (
      <button
        type="button"
        className="button is-info"
        disabled={disabled}
        onClick={this.addMoreClickHandler.bind(this)}>
        További szakképesítés
      </button>
    )
  }

  private currentValues (): ItemFields.QualificationModel[] {
    let value: undefined | ItemFields.QualificationModel[] = this.props.formApi.getValue(this.fullField())
    if (!value) {
      return []
    }

    return value
  }

  private addMoreClickHandler () {
    this.props.formApi.addValue(this.fullField(), {})
  }

  private removeClickHandler (index: number) {
    this.props.formApi.setError(this.fullField(index), undefined)
    this.props.formApi.removeValue(this.fullField(), index)
  }

  private fullField (index?: number) {
    let path: (string | number)[] = ['qualifications']
    if (index) { path.push(index) }
    return this.props.formApi.getFullField(path)
  }
}

export const QualificationListField =
  Form.withFormApi<InputProps, QualificationListModel>(QualificationListField_)
