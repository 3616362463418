import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'

export interface NameModel {
  lastName: string
  firstName: string
  birthName: string
}

interface Props {
  birthNameVisible: boolean
}

class NameFields_ extends React.Component<Props & Form.NestedComponentProps<NameModel>> {

  render () {

    let birthNameField = null
    if (this.props.birthNameVisible) {
      birthNameField = <Form.InputField
                         id="birthName"
                         field="birthName"
                         label="Születési név:"
                         type="text"
                         maxLength={100}
                         leftIcon={Icon.fontAwesome('user')}
                         horizontal={true}
                         placeholder="Születési név (opcionális)"
                         validate={Validation.onlyServerValidator}
                         validationErrorDisplay={Validation.stringErrorDisplay}
      />
    }

    return <>
    <Form.InputField
    id="lastName"
    field="lastName"
    label="Vezetéknév:"
    type="text"
    maxLength={50}
    leftIcon={Icon.fontAwesome('user')}
    horizontal={true}
    placeholder="Vezetéknév"
    validate={Validation.requiredValidator}
    validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay,
                                               Validation.stringErrorDisplay)}
    />
    <Form.InputField
    id="firstName"
    field="firstName"
    label="Keresztnév:"
    type="text"
    maxLength={50}
    leftIcon={Icon.fontAwesome('user')}
    horizontal={true}
    placeholder="Keresztnév"
    validate={Validation.requiredValidator}
    validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay,
                                               Validation.stringErrorDisplay)}
    />

    {birthNameField}
    </>
  }
}

export const NameFields =
  Form.withNestedField<Props, NameModel>(NameFields_)
