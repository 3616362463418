import * as React from 'react'
import * as Form from 'ui/form'
import * as PersonName from 'person/ui/name-fields'
import * as PersonBirth from 'person/ui/birth-fields'
import * as PersonProfession from 'person/ui/profession-fields'

export interface Model {
  name: PersonName.NameModel
  birth: PersonBirth.BirthModel
  profession: PersonProfession.ProfessionModel
}

interface Props {}

export class GeneralPersonFields_ extends React.Component<Form.NestedComponentProps<Model> & Props> {

  render () {
    return <>
    <PersonName.NameFields field="name" birthNameVisible={false} />
    {/* <PersonBirth.BirthFields field="birth" isRequired={false} /> */}
    <PersonProfession.ProfessionFields field="profession" />
    </>
  }
}

export const GeneralPersonFields =
  Form.withNestedField<Props, Model>(GeneralPersonFields_)
