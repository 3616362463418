import * as React from 'react'
import * as Router from 'react-router'
import * as classNames from 'classnames'

interface State {
  isHamburgerActive: boolean
}

type Props = Router.RouteComponentProps<any> & {
  brand?: React.ReactNode
  className?: string
}

class NavBarWithBurger_ extends React.Component<Props, State> {

  constructor (props: any) {
    super(props)

    this.state = { isHamburgerActive: false }
  }

  componentWillReceiveProps (nextProps: Props) {
    let path = this.props.location.pathname
    let newPath = nextProps.location.pathname

    if (path !== newPath && this.state.isHamburgerActive) {
      this.setState({ isHamburgerActive: false })
    }
  }

  componentDidUpdate (prevProps: Props, prevState: State) {

    if (this.state.isHamburgerActive !== prevState.isHamburgerActive) {

      let navBarMenu: Element = (this.refs['navbar-menu'] as any)
      let burger: Element = (this.refs['burger'] as any)

      if (this.state.isHamburgerActive) {
        burger.classList.add('is-active')
        navBarMenu.classList.add('is-active')
      } else {
        burger.classList.remove('is-active')
        navBarMenu.classList.remove('is-active')
      }
    }
  }

  render () {

    let classes = classNames('navbar', this.props.className)

    return (
      <nav className={classes} role="navigation" aria-label="main navigation">
        <div className="touch-view navbar-brand">
          {this.props.brand}
          <div className="navbar-burger" ref="burger" onClick={this.hamburgerMenuClickHandler.bind(this)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div className="navbar-menu" ref="navbar-menu">
          {this.props.children}
        </div>
      </nav>
    )
  }

  private hamburgerMenuClickHandler (e: React.MouseEvent<HTMLElement>) {
    this.setState({ isHamburgerActive: !this.state.isHamburgerActive })
  }
}

export const NavBarWithBurger = Router.withRouter<Props>(NavBarWithBurger_)
