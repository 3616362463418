import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Api from '../../api'
import * as Router from 'react-router'
import * as RouterDOM from 'react-router-dom'
import * as moment from 'moment'
import * as AuthUI from 'auth/ui'
import { NavLink } from 'react-router-dom'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { WithLoading } from 'ui/with-loading'
import { NotFound } from 'ui/frontend'
let Markdown = require('react-remarkable')
import { ScrollToTopOnMount } from 'ui/scroll'

const FRONTEND_COURSES_QUERY = gql`
  query frontendCourses($idOrAlias: String) {
    frontendCourses(idOrAlias: $idOrAlias) {
      id
      urlName
      typeName
      name
      subtitle
      description
      curriculum
      targetGroup
      price
      startDate
      endDate
      health {
        credit
        registrationNumber
      }
      organizer {
        name
        address
      }
      imageUrl
    }
  }`

interface ListResponse {
  frontendCourses: Api.FrontendCoursesQueryResult
}

type InputProps = Router.RouteComponentProps<{idOrAlias: string}>
type DataProps = {
  course: ReactApollo.DataValue<ListResponse>
}
type Props = InputProps & AuthUI.WithAuthStateChildProps & DataProps

class CourseWithLoading extends WithLoading<ListResponse> {
}

class CoursePage_ extends React.PureComponent<Props> {

  render () {
    return <>
    <ScrollToTopOnMount />
    <CourseWithLoading data={this.props.course}>
    {(data) => {
      if (data.frontendCourses!.length === 0) {
        return <NotFound />
      }
      return this.renderCourse(data.frontendCourses![0])
    }}
      </CourseWithLoading>
    </>
  }

  renderCourse (course: Api.FrontendCourse) {

    if (course.urlName && this.props.match.params.idOrAlias != course.urlName) {
      // redirect to the alias
      return <RouterDOM.Redirect
      to={{
          pathname: `/kurzus/${course.urlName}`
        }} />
    }

    return <div>

      <Breadcrumb parent="Kurzusok" end={course.name} />

      <PageTitle
        title={course.name}
        description={course.description}
        subtitle={course.subtitle}
        imageUrl={course.imageUrl} />

      <section className="section">
        <div className="columns">

          <div className="column is-two-thirds">
            <div className="content course-description">
              <h4>Leírás</h4>
              <div>
                <Markdown source={course.description} />
              </div>
              <br />
              <h4>Tematika</h4>
              <div>
                <Markdown source={course.curriculum} />
              </div>
            </div>
          </div>

          <div className="column">
            <div className="content has-text-centered">
              <div className="course-data-background">
                {this.getPic(course.imageUrl)}
                <div className="course-data">
                  <h4>A képzés adatai</h4>
                  {this.dataPair('Típus', course.typeName)}
                  {this.period(course)}
                  {this.healthParameters(course)}
                  {this.organizerParameters(course)}
                  {this.dataPair('Tanfolyam helye', 'kepzeskozpont.com')}
                  {this.dataPair('Célcsoport', course.targetGroup)}
                  {this.price(course)}
                </div>
                <div className="course-buttons">
                  <div>
                    {this.enrollButton()}
                    <NavLink to="/kapcsolat"><div className="field button is-fullwidth is-info">Érdeklődöm</div></NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    </div>
  }

  private enrollButton () {
    let path = this.isLoggedIn() ? '/kurzus-regisztracio' : '/regisztracio'

    return <NavLink to={path} className="field button is-fullwidth is-primary">Beiratkozom a kurzusra</NavLink>
  }

  private isLoggedIn () {
    return this.props.authState.isLoggedIn
  }

  private period (course: Api.FrontendCourse) {
    let start = moment(course.startDate).format('ll')
    let end = moment(course.endDate).format('ll')

    return this.dataPair('Képzés ideje', `${start} - ${end}`)
  }

  private price (course: Api.FrontendCourse) {
    if (course.price) {
      return this.dataPair('Ár', `${course.price}`)
    }

    return null
  }

  private healthParameters (course: Api.FrontendCourse) {
    if (!course.health) {
      return null
    }

    return <>
    {this.dataPair('Megszerezhető pontérték', course.health.credit)}
    {this.dataPair('Nyilvántartási szám', course.health.registrationNumber)}
    </>
  }

  private organizerParameters (course: Api.FrontendCourse) {
    if (!course.organizer) {
      return null
    }

    return <>
    {this.dataPair('Továbbképzés szervezőjének neve', course.organizer.name)}
    {this.dataPair('Továbbképzés szervezőjének címe', course.organizer.address)}
    </>
  }

  private dataPair (title: string, value: any) {
    return <>
      <h5>{title}:</h5>
      <p>
        {value}
      </p>
    </>
  }

  private getPic (imageUrl: string) {

    if (!imageUrl) {
      return null
    }

    return <>
      <div className="image">
        <img src={imageUrl} />
      </div>
    </>
  }
}

const withFrontendCourses = ReactApollo.graphql<InputProps, {}, {}, DataProps>(
  FRONTEND_COURSES_QUERY, {
    name: 'course',
    options: (props) => ({
      variables: {
        idOrAlias: props.match.params.idOrAlias
      }
    })
  })

const withAuthState = AuthUI.withAuthState(CoursePage_)
export const CoursePage = withFrontendCourses(withAuthState)
