
export interface RequestPasswordResetCommand {
  email: string
}

export enum RequestPasswordResetResultCode {
  success,
  invalidEmail
}

export type RequestPasswordResetResult = RequestPasswordResetResultCode
