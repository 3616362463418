import * as V from 'validation'

export interface GenerateCodesCommand {
  count: string
  courseId: string
  codeGroup: string
  codeExpiryDate: string
  availableDays: string
}

export enum GenerateCodesResultCode {
  success,
  validationError
}

export interface GenerateCodesCommandResult {
  code: GenerateCodesResultCode,
  validation?: string
}

export const INVALID_CODE_GROUP_FORMAT = 'invalid-code-group-format'
export type CourseIdValidationResult = 'invalid-course-id'
export type CodeGroupValidationResult = 'invalid-code-group-format'

export interface GenerateCodesValidationResult {
  count?: V.NumberValidationResult | V.RequiredValidationResult
  courseId?: V.RequiredValidationResult | CourseIdValidationResult
  codeGroup?: V.RequiredValidationResult | V.StringValidationResult | CodeGroupValidationResult
  codeExpiryDate?: V.RequiredValidationResult | V.DateValidationResult
  availableDays?: V.RequiredValidationResult | V.NumberValidationResult
}
