import * as React from 'react'
import * as Router from 'react-router'
import * as H from 'history'
import * as RouterDom from 'react-router-dom'

type Props = {
  to: string
  className?: string
} & Router.RouteComponentProps<any>

class LinkWithCancelSource_ extends React.PureComponent<Props> {
  render () {

    let target: H.LocationDescriptor = {
      pathname: this.props.to,
      state: {
        cancelReferrer: this.props.location.pathname + this.props.location.search
      }
    }

    return <RouterDom.Link
      className={this.props.className} to={target}>
      {this.props.children}
    </RouterDom.Link>
  }
}

export const LinkWithCancelSource = Router.withRouter<Props>(LinkWithCancelSource_)
