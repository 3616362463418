import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Api from '../../api'
import * as AuthUI from 'auth/ui'
import { FrontpageCourseBox } from './frontpage-course-box'

const FRONTEND_COURSES_QUERY = gql`
  query frontendCourses($idOrAlias: String) {
    frontendCourses(idOrAlias: $idOrAlias) {
      id
      urlName
      name
      subtitle
      shortDescription
      imageUrl
    }
  }`

interface ListResponse {
  frontendCourses: Api.FrontendCoursesQueryResult
}

type InputProps = {}
type DataProps = {
  courses: ReactApollo.DataValue<ListResponse>
}
type Props = InputProps & DataProps & AuthUI.WithAuthStateChildProps

class FrontpageCourseList_ extends React.PureComponent<Props> {

  render () {
    if (this.props.courses.loading || !this.props.courses.frontendCourses) {
      return null
    }

    let isLoggedIn = this.isLoggedIn()
    let courses = this.props.courses.frontendCourses.map((x, index) => this.item(x, index, isLoggedIn))
    return courses
  }

  private item (course: Api.FrontendCourse, key: number, isLoggedIn: boolean) {
    return <FrontpageCourseBox
      isLoggedIn={isLoggedIn}
      name={course.name}
      subtitle={course.subtitle}
      key={key}
      shortDescription={course.shortDescription}
      urlName={course.urlName}
      imageUrl={course.imageUrl}
    />
  }

  private isLoggedIn () {
    return this.props.authState.isLoggedIn
  }
}

const withCourseList = ReactApollo.graphql<InputProps, {}, {}, DataProps>(FRONTEND_COURSES_QUERY, {
  name: 'courses'
})

const withAuthState = AuthUI.withAuthState(FrontpageCourseList_)
export const FrontpageCourseList = withCourseList(withAuthState)
