import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'
import * as React from 'react'
import { toast } from 'ui/toast'
import * as Form from 'ui/form'
import { fontAwesome } from 'ui/icon'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import * as Validation from 'validation/ui'
import * as Api from '../api'

interface Model {
  email: string
}

type Props = {
  mutate: ReactApollo.MutationFunc<{ requestPasswordReset: Api.RequestPasswordResetResult },
  { email: string }>
}

export class ForgotPassword_ extends React.Component<Props> {

  render () {

    return <>
      <Breadcrumb end="Elfelejtett jelszó" />
      <PageTitle title="Elfelejtett jelszó" />

      <section className="section profile-data">
        <div className="columns is-centered">
          <div className="column is-half">
            <p className="label">Kérjük, adja meg e-mail-címét, és küldünk egy linket, amivel meg tudja változtatni a jelszavát.</p>
            <div className="box">
              {this.form()}
            </div>
            </div>
          </div>
      </section>
    </>
  }

  private form () {

    return <>
      <Form.Form onSubmit={this.handleSubmit.bind(this)}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <Form.InputField
              label="E-mail-cím:"
              type="email"
              field="email"
              placeholder="sajat.email@cim.hu"
              leftIcon={fontAwesome('envelope')}
              validate={Validation.requiredValidator}
              validationErrorDisplay={Validation.compose(
                  Validation.requiredErrorDisplay)}
              horizontal={true}
            />

            <div className="field is-horizontal">
              <div className="field-label is-normal">
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control is-expanded">
                    <Form.SubmitButton formApi={formApi}>Kérem a linket</Form.SubmitButton>
                  </div>
                </div>
              </div>
            </div>

          </form>
        )}
      </Form.Form>
    </>
  }

  private async handleSubmit (model: Model, e: any, formApi: Form.FormApi<Model>) {

    let result = await this.props.mutate({
      variables: { email: model.email }
    })

    let code = result.data.requestPasswordReset
    switch (code) {
      case Api.RequestPasswordResetResultCode.success:
        toast.success('Email címére elküldtük a jelszó módosításhoz szükséges linket.')
        break
      case Api.RequestPasswordResetResultCode.invalidEmail:
        formApi.setError('email', 'A megadott email cím nem létezik rendszerünkben.')
        break
      default:
        throw new Error('Invalid result: ' + code)
    }
  }
}

const requestPasswordResetMutation =
  gql`mutation requestPasswordReset($email: String!) {
    requestPasswordReset(email: $email)
  }
  `

const mutation = ReactApollo.graphql<{}, {}>(requestPasswordResetMutation)
export const ForgotPassword = mutation(ForgotPassword_)
