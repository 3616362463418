import { ValidationError } from './validation-error'
import { InputResolver, AsyncInputResolver } from './input-resolver'
import * as Api from './api'

export class RequiredInputResolver<TInput, TOutput, TValidationResult> implements InputResolver<TInput | null | undefined, TOutput, TValidationResult | Api.RequiredValidationResult> {

  constructor (
    private inputResolver: InputResolver<TInput, TOutput, TValidationResult>) {}

  resolve (input: TInput | null | undefined) {
    if (!input) {
      return new ValidationError<Api.RequiredValidationResult>('required')
    }

    return this.inputResolver.resolve(input)
  }
}

export class AsyncRequiredInputResolver<TInput, TOutput, TValidationResult> implements AsyncInputResolver<TInput | null | undefined, TOutput, TValidationResult | Api.RequiredValidationResult> {

  constructor (
    private inputResolver: AsyncInputResolver<TInput, TOutput, TValidationResult>) {}

  async resolve (input: TInput | null | undefined) {
    if (!input) {
      return new ValidationError<Api.RequiredValidationResult>('required')
    }

    return this.inputResolver.resolve(input)
  }
}

export class RequiredBooleanInputResolver implements InputResolver<boolean | null | undefined, boolean, Api.RequiredValidationResult> {

  resolve (input: boolean | null | undefined) {
    if (input === null || input === undefined) {
      return new ValidationError<Api.RequiredValidationResult>('required')
    }

    return input
  }
}

export function requiredBoolean () {
  return new RequiredBooleanInputResolver()
}

export class SimpleRequiredInputResolver<TValue> implements InputResolver<TValue | null | undefined, TValue, Api.RequiredValidationResult> {

  resolve (input: TValue | null | undefined) {
    if (!input) {
      return new ValidationError<Api.RequiredValidationResult>('required')
    }

    return input
  }
}

export function simpleRequire<TValue> () {
  return new SimpleRequiredInputResolver<TValue>()
}

export function required<TInput, TOutput, TValidationResult> (
  inputResolver: InputResolver<TInput, TOutput, TValidationResult>): InputResolver<TInput | null | undefined, TOutput, TValidationResult | Api.RequiredValidationResult> {

  return new RequiredInputResolver(inputResolver)
}

export function asyncRequired<TInput, TOutput, TValidationResult> (
  inputResolver: AsyncInputResolver<TInput, TOutput, TValidationResult>): AsyncInputResolver<TInput | null | undefined, TOutput, TValidationResult | Api.RequiredValidationResult> {

  return new AsyncRequiredInputResolver(inputResolver)
}
