export interface AuthState {
  isLoggedIn: boolean
  details: AuthStateDetails
}

export interface AuthStateDetails {
  userId: string
  roles: string[]
  fullName: string
}

export function notLoggedInUser (): AuthState {
  return {
    isLoggedIn: false,
    details: {
      userId: '',
      roles: [],
      fullName: ''
    }
  }
}

export function loggedInUser (userId: string, roles: string[], fullName: string): AuthState {
  return {
    isLoggedIn: true,
    details: {
      userId: userId,
      roles: roles,
      fullName: fullName
    }
  }
}
