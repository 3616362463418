import { injectable } from 'inversify'
import { FrontendApp } from 'ui/frontend/frontend-app'
import { ReactBootstrap } from 'client/react'

@injectable()
export class FrontendClientBootstrap {

  constructor (
    private reactBootstrap: ReactBootstrap
  ) {}

  start () {
    this.reactBootstrap.start(FrontendApp)
  }
}
