import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
const imagesPath = require.context('./')

export class AboutUs extends React.Component {

  render () {

    let paragraphs = [
      'A képzések tartalmi megvalósítója és a Képzésközpont létrehozója az Eventus Coaching Centrum Kft., melynek profilja az érzelmi intelligencia-fejlesztés valamint kommunikációs és egészségszakmai képzések.',

      '2009 óta végzünk oktatási tevékenységet. Számos tréninget bonyolítottunk le cégeknek, csoportoknak, egyéneknek személyes és online formában. Szervezésünkben több mint 2000 szakdolgozó szerzett értékes tudást és kreditpontot.'
    ]

    let prologHtml = paragraphs.map((item, index) => {
      return this.paragraph(item, index)
    })

    let feedbacks = [
      {
        title: 'Online “EQ tudatos betegellátás“ kreditpontos továbbképzésünk partner elégedettségi felmérésének visszajelzései:',
        pic_title: 'A résztvevők 99 %-a másnak is ajánlaná képzésünket!',
        pic: './feedback_eq.png',
        texts: [
          {
            text: '„Nagyon örültem ennek a képzésformának, érdekes volt, jól felépített, tanultam belőle. A teszt kitöltéséhez is volt elég idő, sikertelenség esetén ismétlési lehetőség. Bármilyen témában szívesen részt vennék hasonló vagy u.ilyen képzésben, még akkor is ha nem jár érte kreditpont, csak úgy a magam szórakoztatására is. Nagyon köszönöm.”'
          },
          {
            text: '„Nekem ez nagyon kényelmes és hasznos volt, itthon munkaidő után,nyugodtan ki tudtam tölteni. Szóval köszönöm,és várom a következőt.”'
          },
          {
            text: '„Nagyon meg vagyok elégedve a munkájukkal. Nagyon hasznos és eddig ismeretlen ismerős dolgok ébresztettek fel. Köszi.”'
          },
          {
            text: '„Nagyon jó az elképzelés, a mai világban ezt tartom mindenki számára elérhetőnek. Külön köszönöm, hogy megkérdeztek. Tisztelettel: ... László”'
          },
          {
            text: '„Bármilyen témában szívesen látnék hasonló képzési anyagot. Egyszerű, gyorsan és könnyen elsajátítható. Számomra nagyon fontos, hogy nem kell elutazni a képzés helyére, bármikor elvégezhető a szabadidőmben .”'
          }
        ]
      },
      {
        title: 'Face to face érzelmi intelligencia fejlesztő képzéseink visszajelzései:',
        pic_title: '',
        pic: '',
        texts: [
          {
            text: '”Rengeteget tanultam. Lenyűgözött a felkészültség, a csodálatosan felépített tematika, a remek és nagyszerű ritmusú információ áramlás és áramoltatás! Nagyszerű volt!”',
            sign: 'Issekutz Erzsébet',
            firm: 'Ringier Kiadó',
            prof: 'TOP kiadványok felelős szerkesztő'
          },
          {
            text: '”A munkatársak hatékony motiválása, irányítása csak úgy lehetséges, ha a vezető képes az őt körülvevő környezet és munkatársak elvárásait, igényeit feltárni, megérteni, átélni, amelyhez elengedhetetlen feltétel, hogy tisztában legyen a saját érzelmeivel, gondolataival és cselekvési késztetéseivel az adott környezetben. Mindehhez az önreflexiós folyamathoz elengedhetetlen készségkészlet a vezető érzelmi intelligenciája. Ezen készségkészlet elmélyítésében, fejlesztésében segített nekem az Eventus “EQ wellness ®”képzése.”',
            sign: 'Dr. Szalai Gyula',
            firm: 'Novartis Pharma',
            prof: 'üzletágigazgató'
          },
          {
            text: '“Egy igazán egyedi képzés részesei lehettünk, mely egyszerre fejlesztette az önismeretünket és építette a csapat kohéziót. Külön élmény volt hogy a képzők valós szervezeti, vezetői tapasztalatukon keresztül hozták közelebb közelebb az EQ munkahelyi vonatkozásait és szerepét egy szervezeti, vállalati kultúrában.”',
            sign: 'Tóth Orsolya',
            firm: 'PGM Hungary Kft. ZenithOptimedia',
            prof: 'ügyvezető igazgató'
          },
          {
            text: '„A cég munkájával elégedettek voltunk, a tréningek kiválóan fejlesztették a munkatársaink érzelmekkel való bánásmódját, az éntudatosság, az önmenedzselés, a társas tudatosság és a kapcsolatmenedzsment képességeit.”',
            sign: 'Sziklai Mariann',
            firm: 'MVM KONTÓ Zrt.',
            prof: 'vezérigazgató'
          }
        ]
      }
    ]

    let feedbackHtml = feedbacks.map((item, index) => {
      return this.feedbackBox(item, index)
    })

    let image = 'customer-rating.png'

    return <div>

      <Breadcrumb parent="Referenciák" end="Rólunk" />

      <PageTitle title="Rólunk" />

      <section className="section">
        <div className="about-us-page">

          <div className="columns is-centered is-variable is-7">
            <div className="column">
              {prologHtml}
            </div>
            <div className="column">
              <div className="card kk-box-shadow">
                <img className="image" src={imagesPath('./' + image)} />
              </div>
            </div>
          </div>
          <div className="columns is-centered is-variable is-7">
            {feedbackHtml}
          </div>

        </div>
      </section>

    </div>
  }

  private paragraph (paragraph: any, key: number) {

    if (!paragraph) {
      return ''
    }

    return (
      <div className="content" key={key}>
        <div className="prolog">
          {paragraph}
        </div>
      </div>
    )
  }

  private feedbackBox (feedback: any, key: number) {

    if (!feedback) {
      return ''
    }

    let messagesHtml = feedback.texts.map((item: any, index: number) => {
      return (
        this.feedbackMessages(item, index)
      )
    })

    let picHtml = null
    if (feedback.pic_title) {
      picHtml = (
        <div className="gap">
          <p className="label">{feedback.pic_title}</p>
          <div className="card kk-box-shadow">
            <img className="image" src={imagesPath(feedback.pic)} />
          </div>
        </div>
      )
    }

    return (
      <div className="column" key={key}>
        <div>
          <h3 className="title is-5">{feedback.title}</h3>
          {picHtml}
          {messagesHtml}
        </div>
      </div>
    )
  }

  private feedbackMessages (message: any, key: number) {

    if (!message || !message.text) {
      return ''
    }

    let firmAndProfHtml = null
    if (message.firm) {
      firmAndProfHtml = <div className="subtitle is-6">{message.firm}, {message.prof}</div>
    }

    let signHtml = null
    if (message.sign) {
      signHtml = (
        <div className="has-text-right subtitle is-6">
          <p className="title is-6">{message.sign}</p>
          {firmAndProfHtml}
        </div>
      )
    }

    let trimmedMessage = this.trimMessage(message.text)

    let result = null
    if (message.sign) {
      result = this.layoutF2F(trimmedMessage, signHtml)
    } else {
      result = this.layoutEQ(trimmedMessage)
    }

    return (
      <div className="gap" key={key}>
        {result}
      </div>
    )
  }

  private layoutEQ (message: string) {

    return (
      <div className="card kk-box-shadow">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-24x24">
                <img src={imagesPath('./double-apostrof.png')} />
              </figure>
            </div>
            <div className="media-content">
              {message}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private layoutF2F (message: string, sign: any) {

    return (
      <div className="card kk-box-shadow">
        <div className="card-content">
          <div className="media">
            <div className="media-left">
              <figure className="image is-24x24">
                <img src={imagesPath('./double-apostrof.png')} />
              </figure>
            </div>
            <div className="media-content">
              {message}
            </div>
          </div>
          {sign}
        </div>
      </div>
    )
  }

  private trimMessage (message: string) {

    // Remove space and apostrophe characters
    return message.replace(/^\s+|\s+$/gm, '').replace(/^[„”“]|[„”“]$/gm, '').trim()
  }

}
