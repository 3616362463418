import * as React from 'react'
import * as Router from 'react-router'
import * as AuthAcl from 'auth-acl/ui'
import { LoginPanel, LoginPanelApi } from 'auth/ui/login-panel'
import { UIConfig } from 'ui-config/ui'
import { lazyInject } from 'ui/di'
import { MenuLinkTop } from './menu-link-top'
import { NavLink } from 'react-router-dom'
import { LoggedInMenuItems } from './logged-in-menu-items'
import { HelpNavbarItem } from './help-navbar-item'

type InputProps = Router.RouteComponentProps<any>

type Props = AuthAcl.HasRightChildDataProps & InputProps
interface State {
  isHamburgerActive: boolean
}

class NavBar_ extends React.Component<Props, State> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {
    return (
      <nav className="navbar page-top-bar" role="navigation" aria-label="main navigation">
        <LoginPanel
          loggedInPanel={(api: LoginPanelApi) => {
            return <div className="navbar-end is-hidden-touch">
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  {api.details().fullName}
                </a>
                <div className="navbar-dropdown">
                  <LoggedInMenuItems loginPanelApi={api} />
                </div>
              </div>
              {/* <MenuLinkTop to="/utmutato">
                  <span className="fas fa-question" />
                  </MenuLinkTop> */}
              <HelpNavbarItem className="navbar-item" />
            </div>
          }}

          notLoggedInPanel={() => {
            return (<div className="navbar-end">
              <div className="level is-mobile">
                <div className="level-left">
                </div>
                <div className="top-menu level-right">
                  <NavLink to="/belepes" className="top-menu-item level-item">
                    Bejelentkezés
                  </NavLink>
                  <NavLink to="/regisztracio" className="top-menu-item level-item">
                    Regisztráció
                  </NavLink>
                  {/* <NavLink to="/help" className="top-menu-item-help level-item">
                      <span className="fas fa-question" />
                      </NavLink> */}
                  <HelpNavbarItem className="top-menu-item-help level-item" />
                </div>
              </div>
            </div>)
          }}
        />
      </nav>
    )
  }

  private loggedInMenuItems (api: LoginPanelApi) {
    let items = [
      <MenuLinkTop key="my-profile" to="/adataim">
        Adataim
      </MenuLinkTop>,
      <MenuLinkTop key="my-courses" to="/kurzusaim">
        Kurzusaim
      </MenuLinkTop>,
      <MenuLinkTop key="course-registration" to="/kurzus-regisztracio">
        Új kurzusregisztráció
      </MenuLinkTop>
    ]

    let adminItem = this.adminMenuItem()
    if (adminItem) {
      items.push(adminItem)
    }

    items.push(<a key="logout" onClick={api.logout} className="navbar-item"> Kijelentkezés</a>)

    return items
  }

  private adminMenuItem () {
    let accessAdmin = this.props.hasRight.loading ? false : this.props.hasRight.can
    if (!accessAdmin) {
      return null
    }

    return (<a key="admin" href={this.config.publicUrl + '/admin'} className="navbar-item"> Adminisztráció</a>)
  }

}

const withHasRight = AuthAcl.createHasRightQuery('access', 'admin')

export const NavBar = Router.withRouter<any>(withHasRight(NavBar_))
