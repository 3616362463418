import { CourseInput } from './course-input'

export enum UpdateCourseResultCode {
  success,
  validationError
}

export interface UpdateCourseCommand {
  id: string
  course: CourseInput
}

export interface UpdateCourseCommandResult {
  code: UpdateCourseResultCode,
  validation?: string
}
