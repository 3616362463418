import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import { DateValidationResult } from './api'
import * as moment from 'moment'

export enum TimeOffset {
  startOfDay,
  endOfDay
}

export class DateInputResolver implements InputResolver<string, moment.Moment, DateValidationResult> {
  constructor (private timeOffset: TimeOffset = TimeOffset.startOfDay) {
  }

  resolve (input: string): ValidationError<DateValidationResult> | moment.Moment {

    let date = moment(input)
    if (!date.isValid()) {
      return new ValidationError<DateValidationResult>('invalidDateFormat')
    }

    switch (this.timeOffset) {
      case TimeOffset.startOfDay:
        date.startOf('day')
        break
      case TimeOffset.endOfDay:
        date.endOf('day')
        break
    }

    return date
  }
}

export function date (timeOffset: TimeOffset = TimeOffset.startOfDay): InputResolver<string, moment.Moment, DateValidationResult> {

  return new DateInputResolver(timeOffset)
}
