import * as React from 'react'
import * as FieldDecorator from 'ui/form/field-decorator'
import * as ReactForm from 'react-form'
import Recaptcha from 'react-grecaptcha'

type CaptchaFieldProps = {
  id?: string
  locale?: string
  siteKey: string
  onChange?: (value: string) => void
}

class CaptchaFieldControl extends React.Component<ReactForm.ComponentProps<string> & CaptchaFieldProps> {

  render () {

    return (
      <>
        <Recaptcha
          sitekey={this.props.siteKey}
          locale={this.props.locale}
          callback={this.captchaVerifyCallback.bind(this)}
          expiredCallback={this.captchaExpiredCallback.bind(this)}
        />
      </>
    )
  }

  private captchaVerifyCallback (response: string) {
    this.props.fieldApi.setError(undefined)
    this.props.fieldApi.setValue(response)
    if (this.props.onChange) {
      this.props.onChange(response)
    }
  }

  private captchaExpiredCallback () {
    this.props.fieldApi.reset()
  }
}

export const CaptchaField =
  ReactForm.withField<CaptchaFieldProps & FieldDecorator.FieldLayout, string>(
    FieldDecorator.withFieldDecorator(
      CaptchaFieldControl))

export const BareCaptchaField =
  ReactForm.withField<CaptchaFieldProps, string>(CaptchaFieldControl)
