import * as React from 'react'

interface Props {
  height?: string
  width?: string
  mapUrl?: string
}

export function ShowSimpleGoogleMap (props: Props) {

  let height = props.height ? props.height : '100%'
  let width = props.width ? props.width : '100%'

  if (!props.mapUrl) {
    return null
  }

  return (
    <iframe src={props.mapUrl} width={width} height={height} style={{ 'border': 0 }}></iframe>
  )
}
