import { lazyInject } from 'ui/di'
import { UIConfig } from 'ui-config/ui'
import * as qs from 'query-string'
import * as React from 'react'
import * as emailAddresses from 'email-addresses'
import * as Router from 'react-router'
import { toast } from 'ui/toast'
import { Link } from 'react-router-dom'

import { fontAwesome } from 'ui/icon'
import * as Form from 'ui/form'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'

import * as Api from 'auth/api'
import { LoginPanel, LoginPanelApi } from './login-panel'
import * as Validation from 'validation/ui'

interface Model {
  email: string
  password: string
}

const emailValidate = (value: any) => {
  return emailAddresses.parseOneAddress(value) === null ? 'Kérem, ellenőrizze az e-mail címet' : ''
}

export class Login extends React.Component<Router.RouteComponentProps<any>> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {

    return <>

      <Breadcrumb end="Bejelentkezés" />

      <PageTitle title="Bejelentkezés" />

      <section className="section">
        <div className="login-page">
          <div className="columns is-centered">
            <div className="column is-three-fifths">
              <LoginPanel
                notLoggedInPanel={this.loginForm.bind(this)}
                loggedInPanel={this.loggedInForm.bind(this)}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  }

  private loggedInForm (loginApi: LoginPanelApi) {
    return (<>
      <div className="column has-text-centered">
        Be vagy jelentkezve mint {loginApi.details().fullName}
      </div>
      <div className="column has-text-centered">
        <button className="button is-info" onClick={loginApi.logout}>Kilépés</button>
      </div>
    </>)
  }

  private loginForm (loginApi: LoginPanelApi) {

    return <>
      <Form.Form onSubmit={this.handleSubmit.bind(this, loginApi)}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <Form.InputField
              label="E-mail-cím:"
              type="email"
              field="email"
              placeholder="sajat.email@cim.hu"
              leftIcon={fontAwesome('envelope')}
              validate={Validation.requiredValidator}
              validationErrorDisplay={Validation.compose(
                  Validation.requiredErrorDisplay)}
              horizontal={true}
            />
            <Form.InputField
              label="Jelszó:"
              type="password"
              field="password"
              placeholder="jelszó"
              leftIcon={fontAwesome('lock')}
              horizontal={true}
              validate={Validation.requiredValidator}
              validationErrorDisplay={Validation.compose(
                  Validation.requiredErrorDisplay,
                  this.invalidPasswordErrorDisplay)}
            />

            <div className="field">
              <p className="control has-text-centered login-button">
                <Form.SubmitButton formApi={formApi}>Bejelentkezés</Form.SubmitButton>
              </p>
            </div>
          </form>
        )}
      </Form.Form>
      <div className="not-registered has-text-centered">
        <div className="not-registered-title">Még nem regisztrált nálunk?</div>
        <Link to="/regisztracio" className="button is-primary">Regisztráljon!</Link>
      </div>
      <div className="has-text-centered">
        <Link to="/elfelejtett-jelszo">Elfelejtette jelszavát?</Link>
      </div>
    </>
  }

  private invalidPasswordErrorDisplay (error: string) {
    if (error === 'invalid') {
      return <div>Hibás jelszó. <Link to="/elfelejtett-jelszo">Elfelejtette?</Link></div>
    }

    return null
  }

  private async handleSubmit (loginApi: LoginPanelApi,
                              model: Model,
                              e: React.SyntheticEvent<any>,
                              formApi: Form.FormApi<Model>) {

    let resultCode = await loginApi.loginByPassword(model.email, model.password)

    switch (resultCode) {
      case Api.LoginByPasswordResultCode.ok: {
        toast.success('Sikeres bejelentkezés')

        this.redirectAfterLogin(loginApi)
        break
      }
      case Api.LoginByPasswordResultCode.invalidPassword: {
        formApi.setError('password', 'invalid')
        break
      }
      case Api.LoginByPasswordResultCode.missingUser: {
        formApi.setError('email', 'Nem találok ilyen felhasználót az adatbázisban :(')
        break
      }
      case Api.LoginByPasswordResultCode.notConfirmed: {
        formApi.setError('email', 'Kérem, ellenőrizze az email címére küldött aktivációs email-t és a segítségével aktiválja fiókját.')
        break
      }
      case Api.LoginByPasswordResultCode.unknownError: {
        break
      }
      default:
        throw new Error('Invalid result: ' + resultCode)
    }
  }

  private redirectAfterLogin (loginApi: LoginPanelApi) {

    let success = this.redirectAfterLoginUsingState()
    if (success) {
      return
    }

    let redirectUrl = this.parseRedirectUrl()
    if (!redirectUrl) {
      redirectUrl = this.config.publicUrl + '/kurzusaim'
    }

    let url = redirectUrl
    setTimeout(() => { location.assign(url) }, 500)
  }

  private redirectAfterLoginUsingState () {
    if (!this.props.location.state) {
      return false
    }

    let location = this.props.location.state.redirectLocation
    if (!location) {
      return false
    }

    this.props.history.push(location)
    return true
  }

  private parseRedirectUrl (): string | undefined {
    const queryParams = qs.parse(this.props.location.search)
    return queryParams.redirect_url
  }
}
