import * as V from 'validation'

export interface UpdateCodeCommand {
  id: string
  expiryDateTime: string
  availableDays: string
}

export enum UpdateCodeResultCode {
  success,
  validationError
}

export interface UpdateCodeCommandResult {
  code: UpdateCodeResultCode,
  validation?: string
}

export interface UpdateCodeValidationResult {
  expiryDateTime?: V.RequiredValidationResult | V.DateValidationResult
  availableDays?: V.RequiredValidationResult | V.NumberValidationResult
}
