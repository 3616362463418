import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import * as Api from './api'

export class AliasFieldInputResolver implements InputResolver<string, string, Api.AliasFieldValidationResult> {

  resolve (input: string) {
    input = input ? input.trim() : ''

    if (input.length > 0) {

      let match = /^[a-z0-9-]*$/.test(input)

      if (!match) {
        return new ValidationError<Api.AliasFieldValidationResult>('invalidAliasField')
      }
    }

    return input
  }
}

export function alias (): InputResolver<string, string, Api.AliasFieldValidationResult> {

  return new AliasFieldInputResolver()
}
