import gql from 'graphql-tag'
import * as Api from '../../api'

export const OPERATION_AREAS_QUERY =
      gql`
query {
  operationAreas {
    id, key, label
  }
}`

export type OperationAreasResponse = {
  operationAreas: Api.OperationAreasQueryResult
}
