import * as React from 'react'
import * as ReactApollo from 'react-apollo'

import * as Form from 'ui/form'
import * as QualificationQueries from './qualifications.query'

type PublicProps = {
  professionGroupId?: string
}
type InputProps = PublicProps & Form.ComponentProps<string>
type Props = ReactApollo.ChildDataProps<InputProps, QualificationQueries.QualificationsResponse, QualificationQueries.QualificationsVariables>

class QualificationField_ extends React.Component<Props> {

  render () {
    return (
      <Form.SelectFieldControl
        fieldApi={this.props.fieldApi}
        options={this.qualificationOptions()}
      />
    )
  }

  componentDidUpdate (prevProps: Props) {
    if (this.props.professionGroupId === prevProps.professionGroupId) {
      return
    }

    let currentValue = this.props.fieldApi.value
    if (this.qualificationOptions().findIndex(x => x.value === currentValue) < 0) {
      // profession group id changed, clear the value
      this.props.fieldApi.setValue(undefined)
    }
  }

  private qualificationOptions () {
    if (!this.props.data.qualifications) {
      return []
    }

    return this.props.data.qualifications
               .map(x => ({ value: x.id, label: x.label }))
  }
}

const QualificationFieldWithData =
  ReactApollo.graphql<InputProps, QualificationQueries.QualificationsResponse, QualificationQueries.QualificationsVariables>(
    QualificationQueries.QUALIFICATIONS_QUERY, {
      options: (props) => {
        return {
          variables: {
            professionGroupId: props.professionGroupId || ''
          }
        }
      }
    })(QualificationField_)

export const QualificationField = Form.withField<PublicProps & Form.FieldLayout , string>(
  Form.withFieldDecorator(QualificationFieldWithData))
