import * as React from 'react'
import { Route } from 'react-router-dom'

interface Props {
  status: number
  children: React.ReactNode
}

/**
 * The component can be used to render the its children
 * and send specified status code when it is rendered on
 * the server. It can be used for example for 404 component.
 */
export const Status = (props: Props) => (
  <Route render={({ staticContext }) => {
    if (staticContext) {
      staticContext.status = props.status
    }
    return props.children
  }} />
)
