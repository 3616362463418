import * as React from 'react'
import { NavLink } from 'react-router-dom'

interface Props {
  begin?: string
  parent?: string,
  end: string
}

export const Breadcrumb = (props: Props) => {

  let rootMenu = 'Kezdőlap'
  if (props.begin) {
    rootMenu = props.begin
  }

  let parentMenu
  if (props.parent) {
    parentMenu = <li className="is-active"><NavLink to="">{props.parent}</NavLink></li>
  } else {
    parentMenu = ''
  }

  return (
    <nav className="touch-view breadcrumb" aria-label="breadcrumbs">
      <ul>
        <li><NavLink to="/">{rootMenu}</NavLink></li>
        {parentMenu}
        <li className="is-active"><NavLink to="" aria-current="page">{props.end}</NavLink></li>
      </ul>
    </nav>
  )
}
