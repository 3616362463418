import * as React from 'react'
import * as Form from 'ui/form'
import { NavLink } from 'react-router-dom'

import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'
import * as Captcha from 'captcha/ui'

interface Props {
  formApi: Form.FormApi<any>
  captchaSiteKey: string
}

export class SendEmailForm extends React.Component<Props, {}> {

  constructor (props: Props) {
    super(props)
  }

  render () {

    let formApi = this.props.formApi

    return <form onSubmit={formApi.submitForm}>
      <section className="section">

        <Form.InputField
          field="fullName"
          label="Név:"
          type="text"
          maxLength={50}
          leftIcon={Icon.fontAwesome('user')}
          horizontal={true}
          placeholder="Név"
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.compose(
            Validation.requiredErrorDisplay,
            Validation.stringErrorDisplay
          )}
        />
        <Form.InputField
          field="email"
          label="E-mail-cím:"
          type="text"
          maxLength={50}
          leftIcon={Icon.fontAwesome('envelope')}
          horizontal={true}
          placeholder="sajat.email@cim.hu"
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.compose(
            Validation.requiredErrorDisplay,
            Validation.emailErrorDisplay
          )}
        />
        <Form.InputField
          field="phone"
          label="Telefon:"
          type="text"
          maxLength={20}
          leftIcon={Icon.fontAwesome('phone')}
          horizontal={true}
          placeholder="+36 20 1234567"
          validate={Validation.onlyServerValidator}
          validationErrorDisplay={Validation.compose(
            Validation.stringErrorDisplay
          )}
        />
        <Form.InputField
          field="subject"
          label="Tárgy:"
          type="text"
          maxLength={50}
        // leftIcon={Icon.fontAwesome('phone')}
          horizontal={true}
          placeholder="Az üzenet tárgya"
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.compose(
            Validation.requiredErrorDisplay,
            Validation.stringErrorDisplay
          )}
        />
        <Form.TextareaField
          label="Üzenet:"
          field="body"
          horizontal
          maxLength={500}
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.compose(
            Validation.requiredErrorDisplay,
            Validation.stringErrorDisplay)}
        >
        </Form.TextareaField>
        <Form.ColumnCheckboxField
          field="approvePrivacyPolicy"
          horizontal
          label=""
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.requiredErrorDisplay}
        >Hozzájárulok a személyes adataim - az <NavLink to="/adatkezeles-kapcsolat" target="_blank">adatkezelési tájékoztatóban</NavLink> megadottak szerinti - kezeléséhez
        </Form.ColumnCheckboxField>
        <Captcha.CaptchaField
          label="Robot ellenőrzés:"
          locale="hu-HU"
          field="captcha"
          horizontal
          siteKey={this.props.captchaSiteKey}
          validate={Validation.requiredValidator}
          validationErrorDisplay={Validation.compose(
            Validation.requiredErrorDisplay,
            Validation.stringErrorDisplay)}
        >
        </Captcha.CaptchaField>
      </section>
      <div className="has-text-centered">
        <div className="control has-text-centered">
          <Form.SubmitButton formApi={formApi}>Üzenet küldése</Form.SubmitButton>
        </div>
      </div>

    </form>

  }
}
