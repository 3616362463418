import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as ProfessionGroupQueries from './profession-groups.query'
import { QualificationField } from './qualification-field'
import * as Validation from 'validation/ui'

export interface QualificationModel {
  professionGroupId: string
  qualificationId: string
}

type PublicProps = {
  moreInfo?: string | React.ReactNode
}
type InputProps = PublicProps & Form.NestedComponentProps<QualificationModel>
type Props = ReactApollo.ChildDataProps<InputProps, ProfessionGroupQueries.ProfessionGroupsResponse>

class ProfessionGroupQualificationFields_ extends React.Component<Props> {

  render () {
    return <>
      {this.props.moreInfo}
      <Form.SelectField
        field="professionGroupId"
        label="Szakmacsoport:"
        help="Azt a szakmacsoportot válassza ki, amelyikre a pontigazolást kéri."
        options={this.professionGroupOptions()}
        horizontal={true}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
      <QualificationField
        field="qualificationId"
        label="Szakképesítés:"
        professionGroupId={this.professionGroupId()}
        horizontal={true}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
      <div className="field is-horizontal">
        <div className="field-label is-normal">
        </div>
        <div className="field-body">
            {this.props.children}
        </div>
      </div>
    </>
  }

  private professionGroupId (): string | undefined {

    let api = this.props.fieldApi
    let value = api.getValue(api.getFullField('professionGroupId'))
    return value
  }

  private professionGroupOptions () {
    if (!this.props.data.professionGroups) {
      return []
    }

    return this.props.data.professionGroups
               .map(x => ({ value: x.id, label: x.label }))
  }
}

const withData = ReactApollo.graphql<InputProps, ProfessionGroupQueries.ProfessionGroupsResponse>(ProfessionGroupQueries.PROFESSION_GROUPS_QUERY)

export const ProfessionGroupQualificationFields =
  Form.withNestedField<PublicProps, QualificationModel>(withData(ProfessionGroupQualificationFields_))
