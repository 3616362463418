import { createValidator } from './create-validator'
import { EmailInputResolver } from '../email-input-resolver'
import * as Api from '../api'

export function emailValidator (input: string) {

  let validator = createValidator(new EmailInputResolver())
  return validator(input)
}

export function emailErrorDisplay (error: string) {
  let casted = error as Api.EmailValidationResult
  if (casted === 'invalid-email') {
    return 'Az email-cím formátuma nem megfelelő.'
  }

  return null
}
