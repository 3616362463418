import * as Api from '../api'

export function mapConfigResult<TModel> (result: Api.DomainResult[],
                                        domain: string,
                                        keys: string[]): TModel {
  let model: any = {}
  let domainResult = result.find(x => x.domain === domain)!
  for (let entry of domainResult.entries) {
    model[entry.key] = entry.value
  }

  return model
}
