import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as EducationLevelField from './education-level-field'
import * as EducationIdField from './education-id-field'
import * as Validation from 'validation/ui'

interface Props {
}

export interface EducationModel {
  educationLevelId: string
  educationId: string
}

export class EducationFields_ extends React.Component<Form.NestedComponentProps<EducationModel> & Props> {
  render () {
    return <>
      <EducationLevelField.EducationLevelField field="educationLevelId" />
      <EducationIdField.EducationIdField field="educationId" />
    </>
  }
}

export const EducationFields =
  Form.withNestedField<Props, EducationModel>(EducationFields_)
