import * as IoC from 'inversify'
import { UIConfig, defaultUIConfig } from '../ui/ui-config'

const uiConfigModule = new IoC.ContainerModule((bind) => {
  bind<UIConfig>('UIConfig').toDynamicValue((context) => {

    // we are on client side
    let content = document.getElementById('preloadedData')
    if (!content || !content.textContent) {
      return defaultUIConfig()
    }
    return JSON.parse(content.textContent)
  })
})

export default uiConfigModule
