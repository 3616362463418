import * as React from 'react'
import * as classNames from 'classnames'

interface IconProps {
  name: string
  large?: boolean
  medium?: boolean
  color?: string
}

export const Icon = (props: IconProps) => {

  let classes = classNames('icon', {
    'is-large': props.large,
    'is-mediun': props.medium
  }, props.color)
  return <span className={classes}>
    <i className={props.name}></i>
  </span>
}
