import * as React from 'react'
const Slider = require('react-animated-slider').default

const imagesPath = require.context('./images')

export class Header extends React.Component {

  render () {
    return <>
      <section className="hero">
        {/* <div className="header-background-printing"> */}
          <div className="image">
            {this.slider()}
          </div>
        {/* </div> */}
      </section>
    </>
  }

  private slider () {

    let content = this.sliderContent()

    return <Slider autoplay={5000} className="slider-wrapper">
    {
      content.map((item, index) => (
        <div
          key={index}
          className="slider-content"
          // style={{ background: `url('${item.image}') no-repeat left bottom` }}
        >
        {this.layout(item)}
        </div>
      ))
    }
    </Slider>
  }

  private sliderContent () {
    const content = [
      {
        imageTitle: imagesPath('./header-online-barmikor-barhonnan.png'),
        imageRight: imagesPath('./header-woman-fingers.png')
      },
      {
        imageTitle: imagesPath('./header-kepzeskozpontunkban.png'),
        imageRight: imagesPath('./header-woman.png')
      },
      {
        imageTitle: imagesPath('./header-online-barmikor-barhonnan.png'),
        imageRight: imagesPath('./header-woman-fingers.png')
      },
      {
        imageTitle: imagesPath('./header-kepzeskozpontunkban.png'),
        imageRight: imagesPath('./header-woman.png')
      }
    ]

    return content
  }

  private layout (item: any) {
    return (<>
      <div className="inner">
        <div className="imageRight">
          <img src={item.imageRight} />
          <div>
            <div className="imageTitle">
              <img src={item.imageTitle} />
              <div className="is-hidden-desktop" style={{ background: 'white', opacity: 0.8, padding: '4px' }}>
                {this.subText()}
              </div>
              <div className="is-hidden-touch">
                {this.subText()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>)
  }

  private subText () {
    return <>
      <div>rugalmas időbeosztással, időt,</div>
      <div>fáradtságot és költséget kímélő formában</div>
      <div>veheti igénybe tudástárunkat!</div>
    </>
  }
}
