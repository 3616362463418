export enum DeleteUserResultCode {
  success,
  adminCouldNotDeleted
}

export type DeleteUserCommand = {
  id: string
}

export interface DeleteUserCommandResult {
  code: DeleteUserResultCode,
}
