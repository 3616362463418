import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
const imagesPath = require.context('./images')

export class Partners extends React.Component {

  render () {

    let images = [

      // Kötött sorrend kezdete - elől a nagyobb cégek
      { filename: 'richter-gedeon-logo.png',          firm: 'Richter Gedeon Nyrt.',                  url: 'https://www.richter.hu/' },
      { filename: 'teva-logo.png',                    firm: 'Teva Zrt.',                             url: 'https://www.teva.hu/' },
      { filename: 'sanofi-aventis-logo.png',          firm: 'Sanofi-Aventis Zrt.',                   url: 'http://www.sanofi.hu/' },
      { filename: 'valeant-logo.png',                 firm: 'VALEANT PHARMA Magyarország Kft.',      url: 'http://www.valeantotc.hu/' },
      { filename: 'mvm-konto-logo.png',               firm: 'Nemzeti Üzleti Szolgáltató Zrt.',       url: 'http://mvm.hu/bemutatkozas/mvm-csoport/nemzeti-uzleti-szolgaltato-zrt/' },
      { filename: 'muszaki-kiado-logo.png',           firm: 'Műszaki Könyvkiadó Kft.',               url: 'http://www.mkkonyvkiado.hu/' },
      { filename: 'zenith-optimedia-logo.png',        firm: 'Zenith Optimedia',                      url: 'https://www.zenithmedia.com/' },
      { filename: 'zed-digital-logo.png',             firm: 'ZED Digital',                           url: 'https://zed.digital/' },
      { filename: 'tns-media-intelligence-logo.png',  firm: 'TNS Media Intelligence',                url: 'http://www.tnsmi.hu/' },
      { filename: 'kantar-media-logo.png',            firm: 'Kantar Media Kft.',                     url: 'https://www.kantarmedia.com/' },
      { filename: 'ucb-logo.png',                     firm: 'UCB Hungary Kft.',                      url: 'https://www.ucb.com/worldwide/hungary' },
      // Kötött sorrend vége - elől a nagyobb cégek

      { filename: 'creasys-logo.png',                 firm: 'Creasys Kft.',                          url: 'http://creasys.hu/' },
      { filename: 'demmler-es-toth-logo.png',         firm: 'Demmler és Társa Kft.',                 url: 'http://www.demmler.hu/' },
      { filename: 'doktorinfo-logo.png',              firm: 'DoktorInfo Kft.',                       url: 'http://www.doktorinfo.com/' },
      { filename: 'in-vitro-logo.png',                firm: 'In Vitro Kft.',                         url: 'http://www.invitro.hu/' },
      { filename: 'krka-logo.png',                    firm: 'Krka Magyarország Kft.',                url: 'https://www.krka.biz/hu/' },
      { filename: 'netambulancia-logo.png',           firm: 'NetAmbulancia Kft.',                    url: 'http://www.netambulancia.hu/' },
      { filename: 'novartis-logo.png',                firm: 'Novartis Hungária Egészségügyi Kft.',   url: 'https://www.novartis.hu/' },
      { filename: 'print-and-go-logo.png',            firm: 'Print & Go',                            url: '' },
      { filename: 'thesys-labs-logo.png',             firm: 'Thesys Labs Kft.',                      url: '' }
    ]

    let partners = images.map((current, index) => {

      let linkHtml
      if (current.url) {
        linkHtml = <a target="_blank" href={current.url}><img src={imagesPath('./' + current.filename)} title={current.firm} /></a>
      } else {
        linkHtml = <img src={imagesPath('./' + current.filename)} title={current.firm} />
      }

      return <div className="partners column is-narrow" key={index}>
        <div className="card kk-box-shadow">
          <div className="image-container">
            <div className="frame">
              {linkHtml}
            </div>
          </div>
          <div className="card-content">
            <div className="content">
              <div className="has-text-centered">
                <p className="subtitle is-6 has-text-gray">
                  {current.firm}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    })

    return <div>

      <Breadcrumb parent="Referenciák" end="Partnereink" />

      <PageTitle title="Partnereink" />

      <section className="section">
        <div className="columns is-mobile is-multiline is-centered">
          {partners}
        </div>
      </section>

    </div>
  }

}
