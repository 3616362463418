import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as AuthStateQuery from './auth-state.query'
import * as Api from '../api'

enum AuthPhase {
  unknown,
  loggedIn,
  noLoggedIn
}

class AuthState {
  constructor (public readonly phase: AuthPhase,
              public readonly details?: Api.AuthStateDetails) {
  }

  get isLoggedIn () {
    return this.phase === AuthPhase.loggedIn
  }

  get isUnknown () {
    return this.phase === AuthPhase.unknown
  }
}

export interface WithAuthStateChildProps {
  authState: AuthState
}

interface WithAuthStateProps {
  authStateSystemQuery: ReactApollo.DataValue<AuthStateQuery.AuthStateResponse>
}

function withAuthState_<P> (WrappedComponent: React.ComponentType<P & WithAuthStateChildProps>): React.StatelessComponent<P> {

  return (props: P & WithAuthStateProps) => {
    let authState = new AuthState(AuthPhase.unknown)
    let query = props.authStateSystemQuery
    if (!query.loading && query.authState) {
      if (query.authState.isLoggedIn) {
        authState = new AuthState(AuthPhase.loggedIn, query.authState.details)
      } else {
        authState = new AuthState(AuthPhase.noLoggedIn)
      }
    }

    return <WrappedComponent authState={authState} {...props } />
  }
}

export function withAuthState<TInputProps> (WrappedComponent: React.ComponentType<TInputProps & WithAuthStateChildProps>) {

  let withAuthState = withAuthState_(WrappedComponent)
  return ReactApollo.graphql<TInputProps, AuthStateQuery.AuthStateResponse>(AuthStateQuery.AUTH_STATE_QUERY, {
    name: 'authStateSystemQuery'
  })(withAuthState)
}
