export enum CourseCodePhase {
  none = 0,
  notUsed = 1,
  used = 2
}

export function codePhaseToString (phase: CourseCodePhase) {
  switch (phase) {
    case CourseCodePhase.used:
      return 'felhasznált'
    case CourseCodePhase.notUsed:
      return 'nincs felhasználva'
    default:
      return ''
  }
}
