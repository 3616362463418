import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Address from 'address/ui'
import * as OperationArea from './operation-area-fields'
import * as Validation from 'validation/ui'

interface Props {
  isAddressOptional?: boolean
}

export interface InstitutionModel {
  name: string
  operationArea: OperationArea.OperationAreaModel
  address: Address.AddressModel
}

export class InstitutionFields_ extends React.Component<Form.NestedComponentProps<InstitutionModel> & Props> {
  render () {
    return <>
    <Form.InputField
      field="name"
      label="Foglalkoztató intézmény neve:"
      type="text"
      maxLength={200}
      leftIcon={Icon.fontAwesome('building')}
      horizontal={true}
      placeholder="Foglalkoztató intézmény neve"
      validate={Validation.requiredValidator}
      validationErrorDisplay={Validation.requiredErrorDisplay}
    />
    <OperationArea.OperationAreaFields field="operationArea" />
    <Address.AddressFields
      field="address"
      label={this.addressLabel()}
      isOptional={this.props.isAddressOptional}
    />
    </>
  }

  private addressLabel() {

    let optionalText = ''
    if (this.props.isAddressOptional) {
      optionalText = ' (opcionális)'
    }

    return `Foglalkoztató intézmény címe${optionalText}:`
  }
}

export const InstitutionFields =
  Form.withNestedField<Props, InstitutionModel>(InstitutionFields_)
