import * as React from 'react'
import { UIConfig } from 'ui-config/ui'
import { lazyInject } from 'ui/di'
import { LoginPanelApi } from 'auth/ui/login-panel'
import { MenuLink } from 'ui/nav-bar'
import * as AuthAcl from 'auth-acl/ui'

interface PublicProps {
  loginPanelApi: LoginPanelApi
}

type InputProps = PublicProps
type Props = AuthAcl.HasRightChildDataProps & InputProps

class LoggedInMenuItems_ extends React.Component<Props> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {
    return this.loggedInMenuItems()
  }

  private loggedInMenuItems () {
    let items = [
      <MenuLink key="my-profile" to="/adataim">
        Adataim
      </MenuLink>,
      <MenuLink key="my-courses" to="/kurzusaim">
        Kurzusaim
      </MenuLink>,
      <MenuLink key="course-registration" to="/kurzus-regisztracio">
        Új kurzusregisztráció
      </MenuLink>
    ]

    let adminItem = this.adminMenuItem()
    if (adminItem) {
      items.push(adminItem)
    }

    items.push(<a key="logout" onClick={this.props.loginPanelApi.logout} className="navbar-item"> Kijelentkezés</a>)

    return items
  }

  private adminMenuItem () {
    let accessAdmin = this.props.hasRight.loading ? false : this.props.hasRight.can
    if (!accessAdmin) {
      return null
    }

    return (<a key="admin" href={this.config.publicUrl + '/admin'} className="navbar-item"> Adminisztráció</a>)
  }
}

const withHasRight = AuthAcl.createHasRightQuery<PublicProps>('access', 'admin')

export const LoggedInMenuItems = withHasRight(LoggedInMenuItems_)
