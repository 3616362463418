import * as React from 'react'
import * as ReactForm from 'react-form'
import * as classNames from 'classnames'
import * as FieldDecorator from '../field-decorator'
import * as DateTime from 'react-datetime'
import * as moment from 'moment'

interface Props {
  placeholder?: string
}

interface State {
  focused: boolean
  date: any
}

class DatePickerFieldControl extends React.Component<ReactForm.ComponentProps<any> & Props, State> {

  constructor (props: any) {
    super(props)

    this.state = {
      focused: false,
      date: null
    }
  }

  render () {

    let api = this.props.fieldApi

    let classes = classNames('input', {
      'is-success': !!api.success,
      'is-warning': !!api.warning,
      'is-danger': !!api.error
    })

    return (
      <DateTime
        defaultValue={api.value ? moment(api.value) : undefined}
        inputProps={{ className: classes, placeholder: this.props.placeholder }}
        closeOnSelect={true}
        timeFormat={false}
        onChange={this.handleChange.bind(this)}
        locale="hu" />
    )
  }

  private handleChange (date: moment.Moment | string | undefined | null) {
    if (!date) {
      this.props.fieldApi.reset()
    } else if (moment.isMoment(date)) {
      this.props.fieldApi.setValue(date.toISOString())
    } else {
      // try to parse
      let parsed = moment(date, 'YYYY.MM.DD')
      if (parsed.isValid()) {
        this.props.fieldApi.setValue(parsed.toISOString())
      } else {
        this.props.fieldApi.reset()
      }
    }
  }
}

export const DatePickerField = ReactForm
  .withField<Props & FieldDecorator.FieldLayout, string>(
    FieldDecorator.withFieldDecorator(DatePickerFieldControl))

export const BareDatePickerField = ReactForm
  .withField<Props, string>(DatePickerFieldControl)
