import { toast } from 'ui/toast'
import * as ErrorTypeResolver from './resolve-error-type'
import { reportClientError } from 'error-report/client-error-report'

export function handleMutationError (err: any) {
  let errorType = ErrorTypeResolver.resolveErrorType(err)
  switch (errorType) {
    case ErrorTypeResolver.ErrorType.network:
      toast.error('Pillanatnyilag nem érhető el a szerver. Kérjük, ellenőrizze, hogy az internet kapcsolata nem szakadt-e meg.')
      break
    case ErrorTypeResolver.ErrorType.security:
    case ErrorTypeResolver.ErrorType.notAuthorized:
      toast.error('Nincs joga a kért művelet elvégzéséhez. Kérem, ellenőrizze, hogy a megfelelő jogosultságú felhasználóval jelentkezett-e be.')
      break
    default:
      reportClientError(err)
      toast.error('Nem várt hiba történt a művelet végrehajtása közben, kérem, próbálja később.')
      break
  }

  return errorType
}
