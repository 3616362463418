import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as EducationLevelsQuery from './education-levels.query'
import * as Validation from 'validation/ui'

type InputProps = { field: string }
type Props = ReactApollo.ChildDataProps<InputProps, EducationLevelsQuery.EducationLevelsResponse>

class EducationLevelField_ extends React.Component<Props> {

  render () {
    return (
      <Form.SelectField
        field={this.props.field}
        label="Legmagasabb iskolai végzettség"
        horizontal
      /* leftIcon={Icon.fontAwesome('user-graduate')}*/
        options={this.educationLevelOptions()}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
    )
  }

  private educationLevelOptions () {
    if (!this.props.data.educationLevels) {
      return []
    }

    return this.props.data.educationLevels
               .map(x => ({ value: x.id, label: x.label }))
  }
}

export const EducationLevelField = ReactApollo.graphql<InputProps, EducationLevelsQuery.EducationLevelsResponse>(EducationLevelsQuery.EDUCATION_LEVELS_QUERY)(EducationLevelField_)
