import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'
import * as Api from '../api'

type LoadConfigVariables = Api.LoadConfigQuery

interface LoadConfigResponse {
  loadConfig: Api.LoadConfigQueryResult
}

export const LOAD_CONFIG_QUERY = gql`
  query loadConfig {
    loadConfig {
      config
    }
  }
`

interface Props {
}

export class LoadConfigQuery extends ReactApollo.Query<LoadConfigResponse, LoadConfigVariables> {}
