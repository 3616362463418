import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'

export type MotherModel = string

interface Props {
  field: string
  isRequired: boolean
}

export class MotherFields extends React.Component<Props> {

  render () {
    let validator = this.props.isRequired ?
                    Validation.requiredValidator : Validation.onlyServerValidator
    let validatorDisplay = Validation.requiredErrorDisplay

    const label = 'Anyja születési neve'
    
    return <Form.InputField
             field={this.props.field}
             label={`${label}:`}
             type="text"
             maxLength={100}
             leftIcon={Icon.fontAwesome('female')}
             horizontal={true}
             placeholder={this.props.isRequired ? label : `${label} (opcionális)`}
             validate={validator}
             validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay,
                                                        Validation.stringErrorDisplay)}
    />
  }
}
