import { createValidator } from '../../../validation/ui'
import * as Api from '../../api'

export function educationIdValidator (input: string) {

  let validator = createValidator(new Api.EducationIdInputResolver())
  return validator(input)
}

export function educationIdErrorDisplay (error: string) {
  let casted = error as Api.EducationIdValidationResult
  if (casted === 'invalid-education-id') {
    return 'Nem megfelelő oktatási azonosító. Az oktatási azonosító 7-essel kezdődik, 11 számjegyből áll és ellenőrzőösszeggel ellenőrzött.'
  }

  return null
}
