import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
let Mailto: any = require('react-mailto.js').default
import { ScrollToTopOnMount } from 'ui/scroll'
import { NavLink } from 'react-router-dom'

export class ServiceAgreement extends React.Component {

  section = 0
  subSection = 0

  render () {

    return <div>

      <ScrollToTopOnMount />
      <Breadcrumb end="Szolgáltatási szerződés" />

      <PageTitle title="Szolgáltatási szerződés" />

      <section className="section">

        <div className="service-agreement-page">

          {/* <h2 className="title">Szolgáltatási szerződés</h2> */}

          <div className="content">

              <p>egyéni résztvevők részére</p>

              <p><strong>Eventus Coaching Centrum Kft.</strong> (1112 Budapest, Felsőhatár út 2/C, C épület, Fsz.2., Cg. 13 09 194829,
              adószáma: 24890221-2-43.), képviseli: Nagy Erzsébet ügyvezető) mint <strong>Szolgáltató</strong></p>

              <p>valamint</p>

              <p>a Hozzáférési Kóddal rendelkező természetes személy mint <strong>Résztvevő</strong></p>

              <p>között az alulírott helyen és időben az alábbiak szerint.</p>


              <p><strong>Fogalmak</strong></p>

              <p><strong>Hozzáférési Kód</strong> jelenti a Résztvevő részére a Programban történő részvétel érdekében kiadásra
              kerülő, az Online Felület eléréséhez szükséges egyedi felhasználói nevet és jelszót.</p>

              <p><strong>Online Felület</strong> jelenti a Szolgáltató által üzemeltetett, a <NavLink to="/" target="_blank">www.kepzeskozpont.com </NavLink> útján elérhető
              internetes weboldalt, amelyhez szükséges tárhely szolgáltatást a Neting Informatika Kft. (1039
              Budapest Juhász Gyula utca 10., adószáma: 12941258-2-41, Cg. 01-09-711138) biztosítja.</p>

              <p><strong>Program</strong> jelenti a jelen szerződés alapján a Szolgáltató által kifejlesztett és az Online Felületen
              nyújtott képzést.</p>

              <p><strong>Részvételi Díj</strong> jelenti a Programban történő részvétel feltételét képező díjakat.</p>

              <p><strong>Előzmények</strong></p>

              <p>A Résztvevő a jelen szerződés feltételeinek elfogadásával kijelenti és szavatolja, hogy</p>

                <ul>
                  <li>a Részvételi Díjat megfizette, illetve a Programban történő részvétele érdekében más
                  helyette megfizette és</li>

                  <li>jogszerűen rendelkezik a Szolgáltató által szabályszerűen kiadott Hozzáférési Kóddal,</li>

                  <li>a Program részleteit, tematikáját megismerte és annak megismerését követően kíván benne
                  részt venni,</li>

                  <li>a személyes adatok kezeléséről szóló adatkezelési tájékoztatót elolvasta, amely alapján a
                  személyes adatai kezeléséről megfelelő, előzetes, érthető és világos tájékoztatást kapott a
                  személyes adatai kezeléséről,</li>

                  <li>egészségszakmai továbbképzés esetén a személyes adatok kezeléséhez hozzájárulását adja,</li>

                  <li>tudomásul veszi, hogy a Program egésze, valamint annak bármely része a Szolgáltató és/vagy
                  a Program szervezőjének kizárólagos szellemi tulajdonát képezi, annak felhasználására
                  kizárólag a Programban történő részvétel érdekében, az ahhoz szükséges terjedelemben
                  biztosít számára jogot a Szolgáltató.</li>
              </ul>

              {this.sectionHtml(++this.section, 'Megállapodás továbbképzés nyújtására')}

                    <p>1.1. A Szolgáltató kijelenti és szavatolja, hogy a Program nyújtásához szükséges szakmai
                    tapasztalattal, infrastrukturális feltételekkel rendelkezik.</p>

                    <p>1.2. A Résztvevő kijelenti, hogy a Szolgáltató szolgáltatásait igénybe kívánja venni. A Résztvevő a
                    jelen szerződés feltételeinek elfogadásával kijelenti, hogy a Program részleteit megismerte
                    és ezen teljeskörű tájékozódására tekintettel kívánja a Szolgáltató szolgáltatását igénybe
                    venni, valamint a Programban részt venni.</p>

                    <p>1.3. A Résztvevő tudomásul veszi, hogy a Programban történő részvételhez az Online Felület
                    használatához, illetve eléréséhez szükséges adatkapcsolatot, internet elérés, egyéb hardver
                    és szoftver elemek biztosítását a Szolgáltató nem vállalja, az kizárólag a Résztvevő
                    felelőssége és kötelezettsége.</p>

                    <p>1.4. A Szolgáltató nem felel a Résztvevő által biztosított adatkapcsolat, internetelérés, szoftver és
                    hardver bármely hibájából, üzemszünetéből, kompatibilitási problémáiból adódó hibákért,
                    károkért, vagy bármely hátrányos következményért.</p>

                    <p>1.5. A Megrendelő tudomásul veszi, hogy a Program kizárólag az Online Felületen keresztül kerül
                    nyújtásra és vehető igénybe.</p>

                    <p>1.6. A Program teljesítése esetén minden a programot sikeresen elvégzett Résztvevő tanúsítványt
                    kap.</p>

              {this.sectionHtml(++this.section, 'A Programban történő részvétel biztosítása')}

                    <p>2.1. A Programban történő részvétel érdekében a Résztvevő részére a Részvételi Díj megfizetését
                    követően kiadásra került a Hozzáférési Kód, amely a Programba az Online Felületen
                    keresztül történő belépéshez szükséges, egyedi azonosító.</p>

                    <p>2.2. A Résztvevő tudomásul veszi, hogy a részére kiadott Hozzáférési Kód az első belépéstől
                    számított a Hozzáférési Kód a kiadásakor megjelölt határidőig érvényes, amely idő alatt a
                    Szolgáltató lehetőséget biztosít a Résztvevő részére a Program elvégzésére. A Programot az
                    első belépést követően a Hozzáférési Kód érvényességi idején belül bármikor
                    megszakíthatja, újrakezdheti, azonban összességében ezen időszak áll rendelkezésére a
                    Program befejezésére.</p>

                    <p>2.3. A Szolgáltató nem felel a kiadott Hozzáférési Kód felhasználásáért, azaz azok
                    felhasználásának elmulasztása, valamint a Programban történő nem megfelelő részvétel,
                    illetve a Program el nem végzése a Szolgáltatónak nem felróható, azzal összefüggésben őt
                    felelősség nem terheli. A Résztvevő tudomásul veszi, hogy a kiadott Hozzáférési Kódot egy
                    személy használhatja, a Hozzáférési Kód regisztrálója részére kerül kiadásra a Program
                    sikeres elvégzését igazoló tanúsítvány amely regisztráció módosítására nincs lehetőség. A
                    Résztvevő köteles a fenti rendelkezést betartani illetve annak betartásáról gondoskodni. A
                    fentiekkel ellentétes, azt sértő felhasználás esetén a Szolgáltató a Programból az adott
                    Hozzáférési Kódot további értesítés nélkül kizárhatja, valamint az ilyen felhasználásból eredő
                    károkért, hátrányokért a Szolgáltató nem felel.</p>

                    <p>2.4. A Szolgáltató szavatol azért, hogy az Online Felület munkanapokon 0-24 óra között elérhető
                    azzal, hogy az esetleges technikai jellegű üzemszünetek az egy naptári hónapon belül a 48
                    órát nem haladhatják meg. Amennyiben az üzemszünet a Szolgáltatónak felróható okból a
                    fenti időtartamot meghaladja, a felek a Hozzáférési Kód rendelkezésre állási idejének
                    meghosszabbítása érdekében tárgyalásokat folytatnak.</p>

                    <p>2.5. A Szolgáltató nem felel</p>

                        <p>2.5.1. a Program útján megszerzett tudás Részvevő általi hasznosításából, használatából
                        eredő esetleges károkért, költségekért, valamint</p>

                        <p>2.5.2. azért, mert a Résztvevő a Programot bármely okból nem tudja sikeresen elvégezni,</p>

                        <p>2.5.3. és nem szavatolja, hogy az elvégzett Program alkalmas valamely, a Résztvevő által
                        elérni kívánt célra.</p>

              {this.sectionHtml(++this.section, 'Adatvédelem')}

                    <p>3.1. Szolgáltató kijelenti és szavatol, hogy a Program során a Résztvevő vonatkozásában
                    kizárólag a Program nyújtása érdekében és az ahhoz szükséges mértékben kerülnek
                    személyes adatok kezelésre. A személyes adatok kezelésére a www.kepzeskozpont.com
                    webhelyen elérhető és a jelen szerződés elválaszthatatlan mellékletét képező Adatvédelmi
                    tájékoztató tartalmazza.</p>

                    <p>3.2. A Résztvevőnek lehetősége van külön, kifejezett hozzájárulással feliratkozni a Szolgáltató
                    hírlevelére. A feliratkozásra a www.kepzeskozpont.com webhelyen van lehetőség az ezen a
                    webhelyen lévő adatkezelési szabályzat elolvasása és annak rendelkezései elfogadása
                    esetén.</p>

                    <p>3.3. A Szolgáltató az adatkezelés során az Európai Parlament és a Tanács (EU) 2016/679 rendelete
                    a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről
                    és az ilyen adatok szabad áramlásáról, valamint a 95/46/EK irányelv hatályon kívül
                    helyezéséről (továbbiakban: Általános adatvédelmi rendelet), az információs önrendelkezési
                    jogról és az információszabadságról szóló 2011. évi CXII. törvény rendelkezéseinek
                    betartásával, valamint a Nemzeti Adatvédelmi és Információszabadság Hatóság vonatkozó
                    állásfoglalásának figyelembevételével végzi tevékenységét.</p>

                    <p>3.4. A Megrendelő tudomásul veszi, hogy a Személyes Adatok szükségesek a Program
                    nyújtásához, amelyre tekintettel a Személyes Adatok megadása, illetve az ezek kezeléséhez
                    történő hozzájárulás hiányában, vagy megvonása esetén a Program nyújtása
                    ellehetetlenülhet, amely lehetetlenülés a Résztvevő érdekkörében felmerült
                    lehetetlenülésnek minősül.</p>

              {this.sectionHtml(++this.section, 'Szerzői jogok')}

                    <p>4.1. Felek rögzítik, hogy a minősített továbbképzés, valamint a képzésben felhasználásra kerülő
                    minden képzési anyag, teszt, kérdőív, képzést segítő, szemléltető eszköz egésze (együttesen:
                    Képzési Anyag), vagy annak bármely része a Szolgáltató kizárólagos tulajdonát képezi, azon a
                    Résztvevő sem a jelen szerződés alapján, sem a jelen szerződés teljesítése során nem szerez
                    tulajdon vagy a jelen szerződésben kifejezetten nem rögzített felhasználási jogot.</p>

                    <p>4.2. A Résztvevő nem jogosult a Program vagy annak alapján a részére átadásra kerülő bármely
                    Képzési Anyag egésze vagy bármely része vonatkozásában harmadik személynek
                    felhasználási vagy egyéb jogot engedni, azt a Programtól eltérő célra felhasználni, azzal
                    bármely módon rendelkezni.</p>

                    <p>4.3. A Résztvevő a Program, annak valamely része, a Képzési Anyag, illetve annak bármely része
                    vonatkozásában a Részvételi Díj megfizetésével nem kizárólagos, Magyarország területére
                    kiterjedő, és a Program egyszeri elvégzéséig terjedő időre (azaz a Hozzáférési Kód
                    érvényességi idejére) szerez felhasználási jogot, amely kizárólag saját célú felhasználásra,
                    olvasásra, felkészülésre jogosít, és nem tartalmazza a nyomtatás, terjesztés, átdolgozás,
                    üzleti célú vagy magáncélú, ellenérték fejében, vagy ingyenesen harmadik személy részére
                    történő átadást, hozzáférhetővé tételt, azaz a Program saját néven és saját maga javára
                    történő elvégzését meghaladó felhasználásának jogát.</p>

                    <p>4.4. A Résztvevő köteles a Program minden részletét üzleti titokként kezelni, annak tartalmáról, a
                    Képzési Anyagokról harmadik személy részére felvilágosítást, információt nem jogosult a
                    Szolgáltató előzetes írásbeli hozzájárulása hiányában adni.</p>

                    <p>4.5. A Résztvevő teljes kártérítési kötelezettséggel tartozik a jelen pontban írt szerzői jogi
                    szabályok megsértése esetén a Szolgáltató felé.</p>

              {this.sectionHtml(++this.section, 'Hatály')}

                    <p>5.1. A jelen szerződés az aláírása, illetve mindkét fél általi elfogadása napján lép hatályba és a
                    Megrendelő által megrendelt Program befejezéséig hatályos.</p>

              {this.sectionHtml(++this.section, 'Vegyes rendelkezések')}

                    <p>6.1. Felek kijelentik és szavatolják, hogy a jelen szerződés megkötéséhez szükséges minden
                    felhatalmazással rendelkeznek.</p>

                    <p>6.2. A jelen szerződésben nem szabályozott kérdések tekintetében a Ptk. rendelkezései az
                    irányadók.</p>

                    <p>6.3. A jelen szerződéssel, valamint annak teljesítésével összefüggésben felmerülő vitás kérdések
                    eldöntésére a felek a Szolgáltató székhelye szerinti bíróság kizárólagos illetékességét kötik
                    ki.</p>

          </div>

        </div>

      </section>

    </div>
  }

  private sectionHtml (section: number, text: string) {
    this.subSection = 0

    return <h3>{section + '. ' + text}</h3>
  }

  private subSectionHtml (section: number, subSection: number, text: string) {
    return <p>{section + '.' + subSection + '. ' + text}</p>
  }
}
