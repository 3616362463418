import { CourseEnrollmentsFilter } from './course-enrollments.query'

export interface ExportCreditEnrollmentsCommand {
  courseId: string

  filter?: CourseEnrollmentsFilter
}

export enum ExportCreditEnrollmentsCommandResultCode {
  success,
  noClosedEnrollment
}

export interface ExportCreditEnrollmentsCommandResult {
  code: ExportCreditEnrollmentsCommandResultCode
  url?: string
}
