import * as CourseCodeApi from 'course-code/api'
import * as Validation from 'validation'

export interface EnrollUsingCodeCommand {
  courseCode: string
}

export enum EnrollUsingCodeCommandResultCode {
  succeeded,
  validationError
}

export interface EnrollUsingCodeCommandResult {
  code: EnrollUsingCodeCommandResultCode,
  validation?: string
}

export interface EnrollUsingCodeCommandValidationResult {
  courseCode?: CourseCodeApi.CourseCodeValidationResult | Validation.RequiredValidationResult
}
