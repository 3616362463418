
export function roundEnrollmentScore (score: number) {
  return Math.floor(score)
}

export function formatEnrollmentScore (score: string | number | undefined) {

  if (score === undefined) { return '' }

  let num: number
  if (typeof score === 'string') {
    num = parseFloat(score)
    if (isNaN(num)) {
      throw new Error(`Invalid score number provided: ${score}`)
    }
  } else {
    num = score
  }

  let rounded = roundEnrollmentScore(num)
  return `${rounded}%`
}
