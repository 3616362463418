import * as React from 'react'
import * as ReactForm from 'react-form'
import { FieldLayout, FieldDecorator } from './field-decorator'

type Props<T, TValue> = FieldLayout & T & ReactForm.ComponentProps<TValue>
type ChildProps<T, TValue> = T & ReactForm.ComponentProps<TValue>

export function withFieldDecorator<T, TValue> (
  component: React.ComponentClass<ChildProps<T, TValue>>): React.StatelessComponent<Props<T, TValue>> {

  let Comp = component
  return (props: Props<T, TValue>) => (
    <FieldDecorator feedback={props.fieldApi} {...props}>
      {<Comp {...props as ChildProps<T, TValue>} />}
    </FieldDecorator>)
}
