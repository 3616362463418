import * as React from 'react'
import { Route, Link } from 'react-router-dom'

interface Props {
  to: string
  activeClassName: string
  children: React.ReactNode
}

export const ListItemLink = (props: Props) => (
  <Route path={props.to} children={({ match }) => (
    <li className={match ? props.activeClassName : ''}>
      <Link to={props.to}>
        {props.children}
      </Link>
    </li>
  )}/>
)
