import * as Api from '../api'

export function passwordValidationErrorDisplay (error: string) {
  let asString = error as Api.PasswordValidationResult
  if (asString === 'invalid-password') {
    return 'A jelszónak minimum 6 karaktert kell tartalmaznia.'
  }

  return null
}
