
export interface PrintMyCertificateCommand {
  enrollmentId: string
}

export enum PrintMyCertificateCommandResultCode {
  success,
  courseNotClosed
}

export interface PrintMyCertificateCommandResult {
  code: PrintMyCertificateCommandResultCode
  url?: string
}
