import * as React from 'react'
import * as ReactForm from 'react-form'
let SimpleMDE = require('react-simplemde-editor')
import * as FieldDecorator from '../field-decorator'

type MarkdownFieldProps = {
  id: string
}

class MarkdownFieldControl extends React.Component<ReactForm.ComponentProps<string> & MarkdownFieldProps> {

  render () {
    return (
      <SimpleMDE
        id={this.props.id}
        onChange={this.changeHandler.bind(this)}
        value={this.props.fieldApi.value || ''}
        options={{
          spellChecker: false,
          hideIcons: ['image']
        }}
      />
    )
  }

  private changeHandler (value: string) {
    this.props.fieldApi.setValue(value)
  }
}

export const MarkdownField =
  ReactForm.withField<MarkdownFieldProps & FieldDecorator.FieldLayout, string>(
    FieldDecorator.withFieldDecorator(
      MarkdownFieldControl))
