import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as SurgeryQuery from './surgeries.query'
import * as Validation from 'validation/ui'

type InputProps = {}
type Props = ReactApollo.ChildDataProps<InputProps, SurgeryQuery.SurgeriesResponse>

class SurgeryField_ extends React.Component<Props> {

  render () {
    return (
      <Form.SelectField
        field="surgeryId"
        label="Szakrendelés típusa"
        horizontal
      /* leftIcon={Icon.fontAwesome('building')}*/
        options={this.surgeryOptions()}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
    )
  }

  private surgeryOptions () {
    if (!this.props.data.surgeries) {
      return []
    }

    return this.props.data.surgeries
               .map(x => ({ value: x.id, label: x.label }))
  }
}

export const SurgeryField = ReactApollo.graphql<InputProps, SurgeryQuery.SurgeriesResponse>(SurgeryQuery.SURGERIES_QUERY)(SurgeryField_)
