import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import gql from 'graphql-tag'

import * as Form from 'ui/form'
import * as Api from '../api'

export const STREET_TYPES_QUERY =
  gql`{ streetTypes { id, label } }`

type InputProps = Form.ComponentProps<string>

export type StreetTypesResponse = {
  streetTypes: Api.StreetTypesQueryResult
}

type Props = ReactApollo.ChildDataProps<{}, StreetTypesResponse> & Form.ComponentProps<string>

class StreetTypeField_ extends React.Component<Props> {

  render () {

    return (
      <Form.SelectFieldControl
        fieldApi={this.props.fieldApi}
        options={this.options()}
      />
    )
  }

  private options () {
    if (!this.props.data.streetTypes) {
      return []
    }

    return this.props.data.streetTypes
               .map(x => ({ value: x.id, label: x.label }))
  }
}

const StreetTypeFieldWithData = ReactApollo
  .graphql<InputProps, StreetTypesResponse>(STREET_TYPES_QUERY)(StreetTypeField_)

export const StreetTypeField = Form.withField<Form.FieldLayout, string>(
  Form.withFieldDecorator(StreetTypeFieldWithData))
