import gql from 'graphql-tag'
import * as moment from 'moment'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Api from '../api'
import * as CourseApi from 'course/api'
import * as ErrorHandler from 'error-handler/ui'
import { toast } from 'ui/toast'
import { NavLink } from 'react-router-dom'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { fontAwesome } from 'ui/icon'
import { WithLoading } from 'ui/with-loading'

const MY_ENROLLMENTS_QUERY = gql`
  query myEnrollments {
    myEnrollments {
      items {
        id
        courseName
        courseType
        courseUrl
        expirationDateTime
        startDate
        closeDate
        score
        passingScore
        state
        result
        canClose
      }
    }
  }`

const CLOSE_COURSE_AND_PRINT_CERTIFICATE_MUTATION =
  gql`mutation closeCourseAndPrintCertificate($enrollmentId: String!) {
    closeCourseAndPrintCertificate(enrollmentId: $enrollmentId) {
      code,
      reason,
      url
    }
  }
  `

const CLOSE_COURSE_MUTATION =
  gql`mutation closeMyCourse($enrollmentId: String!) {
    closeMyCourse(enrollmentId: $enrollmentId) {
      code,
      reason
    }
  }
  `

const PRINT_MY_CERTIFICATE_MUTATION =
  gql`mutation printMyCertificate($enrollmentId: String!) {
    printMyCertificate(enrollmentId: $enrollmentId) {
      code,
      url
    }
  }
  `

interface CloseCourseAndPrintCertificateMutationResponse {
  closeCourseAndPrintCertificate: Api.CloseCourseAndPrintCertificateCommandResult
}

interface CloseCourseMutationResponse {
  closeMyCourse: Api.CloseMyCourseCommandResult
}

interface PrintMyCertificateMutationResponse {
  printMyCertificate: Api.PrintMyCertificateCommandResult
}

interface MyEnrollmentsResponse {
  myEnrollments: Api.MyEnrollmentsQueryResult
}

type InputProps = {}
type Props = ReactApollo.ChildDataProps<InputProps, MyEnrollmentsResponse> & {
  closeCourseAndPrintCertificate: ReactApollo.MutationFunc<CloseCourseAndPrintCertificateMutationResponse, Api.CloseCourseAndPrintCertificateCommand>
  closeMyCourse: ReactApollo.MutationFunc<CloseCourseMutationResponse, Api.CloseMyCourseCommand>
  printMyCertificate: ReactApollo.MutationFunc<PrintMyCertificateMutationResponse, Api.PrintMyCertificateCommand>
}

class EnrollmentsWithLoading extends WithLoading<MyEnrollmentsResponse> {
}

class MyCourses_ extends React.Component<Props> {

  componentDidMount () {
    this.props.data.startPolling(2000)
  }

  componentWillUnmount () {
    this.props.data.stopPolling()
  }

  render () {

    return <>

      <Breadcrumb end="Kurzusaim" />

      <PageTitle title="Kurzusaim" />

      <section className="section my-course-page profile-data">
        { this.enrollments() }
      </section>

    </>
  }

  private enrollments () {
    return <EnrollmentsWithLoading data={this.props.data}>
      {(data) => {
        return data.myEnrollments!.items.map((item, index) => {
          return this.courseItem(item, index)
        })
      }}
    </EnrollmentsWithLoading>
  }

  private courseItem (enrollment: Api.MyEnrollmentItem, index: number) {
    return <div className="column box" key={index}>
      <div className="content">
        { this.titlePanel(enrollment.courseName) }
        { this.summaryPanel(enrollment, index) }
        { this.infoBoxPanel(enrollment) }
        { this.commandsPanel(enrollment) }
      </div>
    </div>
  }

  private commandsPanel (enrollment: Api.MyEnrollmentItem) {

    let openCourseCommand = this.openCourseCommand(enrollment)
    let moreCommands = this.moreCommands(enrollment)

    let commandPanel = openCourseCommand === null && moreCommands === null ? null : (<>
        <div className="level-left">
          {openCourseCommand}
          {moreCommands}
        </div>
    </>)

    if (!commandPanel) {
      return null
    }

    return commandPanel
  }

  private openCourseCommand (enrollment: Api.MyEnrollmentItem) {
    if (enrollment.state === Api.EnrollmentViewState.enrolled) {
      return <div className="level-item">
        <a className="button is-info is-fullwidth" href={enrollment.courseUrl}>Kurzus megnyitása</a>
      </div>
    }

    return null
  }

  private moreCommands (enrollment: Api.MyEnrollmentItem) {

    let isHealthCourse = CourseApi.isHealthCourseType(enrollment.courseType)

    switch (enrollment.state) {
      case Api.EnrollmentViewState.enrolled:
      case Api.EnrollmentViewState.expired:
        {
          if (enrollment.result === Api.EnrollmentViewResult.succeeded) {
            let closeButton = isHealthCourse ? this.closeCourseAndRequestCertificateButton(enrollment) :
                              this.closeCourseAndPrintCertificateButton(enrollment)
            return (<>
              <div className="level-item">
                <div className="navlink-container">
                  <NavLink to="/adataim" className="button is-info is-fullwidth">Adataim ellenőrzése</NavLink>
                </div>
              </div>
              {closeButton}
            </>)
          }

          return null
        }
      case Api.EnrollmentViewState.closed:
        if (!isHealthCourse) {
          return this.printCertificateButton(enrollment)
        }

        return null
      default:
        return null
    }
  }

  private printCertificateButton (enrollment: Api.MyEnrollmentItem) {
    return (
      <div className="level-item">
        <button
          className="button is-info is-fullwidth"
          type="button"
          onClick={this.handlePrintMyCertificateButton.bind(this, enrollment)}
        >Tanúsítvány nyomtatása</button>
      </div>
    )
  }

  private async handlePrintMyCertificateButton (enrollment: Api.MyEnrollmentItem) {
    try {
      let response = await this.props.printMyCertificate({
        variables: { enrollmentId: enrollment.id }
      })

      switch (response.data.printMyCertificate.code) {
        case Api.PrintMyCertificateCommandResultCode.success:
          location.assign(response.data.printMyCertificate.url!)
          break
        case Api.PrintMyCertificateCommandResultCode.courseNotClosed:
          toast.warn('A kurzus még nincs lezárva, nem nyomtatható tanúsítvány.')
          break
      }

    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }

  private closeCourseAndPrintCertificateButton (enrollment: Api.MyEnrollmentItem) {
    return (
      <div className="level-item">
        <button
          className="button is-primary is-fullwidth"
          type="button"
          onClick={this.handleCloseCourseAndPrintCertificateButton.bind(this, enrollment)}
        >Kurzus lezárása és tanúsítvány nyomtatása</button>
      </div>
    )
  }

  private async handleCloseCourseAndPrintCertificateButton (enrollment: Api.MyEnrollmentItem) {
    try {
      let response = await this.props.closeCourseAndPrintCertificate({
        variables: { enrollmentId: enrollment.id }
      })

      switch (response.data.closeCourseAndPrintCertificate.code) {
        case Api.CloseCourseAndPrintCertificateCommandResultCode.success:
          toast.success('Sikeresen lezárta a kurzust')
          if (response.data.closeCourseAndPrintCertificate.url) {
            location.assign(response.data.closeCourseAndPrintCertificate.url)
          }
          break
        case Api.CloseCourseAndPrintCertificateCommandResultCode.failed:
          toast.warn('A kurzus lezárása nem sikerült. ' + this.courseCloseProblemInfo(response.data.closeCourseAndPrintCertificate.reason))
          break
      }

      this.refreshLater()

    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }

  private courseCloseProblemInfo (reason: Api.EnrollmentCanCloseState) {
    switch (reason) {
      case Api.EnrollmentCanCloseState.cannotCloseAlreadyClosed:
        return 'Már lezárta ezt a kurzust.'
      case Api.EnrollmentCanCloseState.cannotCloseFailed:
        return 'Nincs sikeres eredménye a kurzushoz.'
      case Api.EnrollmentCanCloseState.cannotCloseHealthDetailsRequired:
        return 'Meg kell adnia az egészségügyi szakdolgozói adatait'
      default:
        return ''
    }
  }

  private closeCourseAndRequestCertificateButton (enrollment: Api.MyEnrollmentItem) {

    let disabled = enrollment.canClose === Api.EnrollmentCanCloseState.cannotCloseHealthDetailsRequired

    return (
      <div className="level-item">
        <button disabled={disabled} className="button is-primary is-fullwidth" onClick={this.handleCloseCourseAndRequestCertificateButton.bind(this, enrollment)}>Kurzus lezárása és tanúsítvány igénylése</button>
      </div>
    )
  }

  private async handleCloseCourseAndRequestCertificateButton (enrollment: Api.MyEnrollmentItem) {
    try {
      let response = await this.props.closeMyCourse({
        variables: { enrollmentId: enrollment.id }
      })

      let result = response.data.closeMyCourse

      switch (result.code) {
        case Api.CloseMyCourseCommandResultCode.success:
          toast.success('Sikeresen lezárta a kurzust. A tanúsítványt postán küldjük.')
          break
        case Api.CloseMyCourseCommandResultCode.failed:
          toast.warn('A kurzus lezárása nem sikerült. ' + this.courseCloseProblemInfo(result.reason))
          break
      }

      this.refreshLater()

    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }

  private refreshLater () {
    let self = this
    setTimeout(() => {
      self.props.data.refetch()
    }, 2000)
  }

  private titlePanel (text: string) {
    return <>
      <p className="title is-5" style={{ 'marginBottom': '10px' }} >
        { text }
      </p>
    </>
  }

  private summaryPanel (enrollment: Api.MyEnrollmentItem, index: number) {

    let closeDate = null
    if (enrollment.state === Api.EnrollmentViewState.closed) {
      closeDate = this.dataPairHtml('Befejezési dátum', moment(enrollment.closeDate).format('LLL'))
    }

    return <>
      { this.dataPairHtml('Kezdési dátum', moment(enrollment.startDate).format('LLL')) }
      { this.remainingTime(enrollment) }
      { closeDate }
      {/* { this.dataPairHtml('Befejezési dátum', course.endDate) } */}
      { this.result(enrollment) }
      {/* { this.dataPairHtml('Kezdési dátum', course.startDate) }
          { this.dataPairHtml('Befejezési dátum', course.endDate) }
          { this.dataPairHtml('Eredmény', course.result, resultColor) } */}
    </>
  }

  private result (enrollment: Api.MyEnrollmentItem) {
    if (enrollment.result === Api.EnrollmentViewResult.none) {
      return null
    }

    let isSuccess = enrollment.result === Api.EnrollmentViewResult.succeeded
    let resultColor = isSuccess ? 'has-text-primary' : 'has-text-info'
    let message = isSuccess ? 'Sikeres' : 'Sikertelen'

    let formattedScore = Api.formatEnrollmentScore(enrollment.score)
    return this.dataPairHtml('Eredmény', `${formattedScore} ${message}`, resultColor)
  }

  private remainingTime (enrollment: Api.MyEnrollmentItem) {
    let label = ''
    switch (enrollment.state) {
      case Api.EnrollmentViewState.enrolled:
        label = 'Ennyi ideje van még elvégezni'
        break
      case Api.EnrollmentViewState.closed:
        label = ''
        break
      case Api.EnrollmentViewState.expired:
        label = 'Ennyi ideje lejárt'
        break
    }

    if (label === '') {
      return null
    }

    let dateTime = moment(enrollment.expirationDateTime)
    let value = `${dateTime.fromNow(true)} (${dateTime.format('LLL')})`

    return this.dataPairHtml(
      label,
      value, 'has-text-link')
  }

  private dataPairHtml (text: string, value: string, color?: string) {

    let col
    if (typeof color === 'undefined') {
      col = ''
    } else {
      col = color
    }

    return <>
      <div className="columns is-horizontal is-gapless" style={{ 'margin': '0 0 2px 0', 'padding': '0' }}>
        <div className="column is-one-third" style={{ 'width': '240px' }} >
          <span className="label"><strong>{ text }:</strong></span>
        </div>
        <div className="column">
          <span className={col}>{ value }</span>
        </div>
      </div>
    </>
  }

  private infoBoxPanel (enrollment: Api.MyEnrollmentItem) {
    if (enrollment.result === Api.EnrollmentViewResult.none) {
      return null
    }

    let isSuccess = enrollment.result === Api.EnrollmentViewResult.succeeded
    let isHealthCourse = CourseApi.isHealthCourseType(enrollment.courseType)

    let icon = fontAwesome('info-circle')

    /* let title = isSuccess ? 'Gratulálunk, sikeres vizsgát tett!' : `Teljesítménye sajnos nem érte el a ${enrollment.passingScore} %-ot` */
    let title: React.ReactNode = null
    let description: React.ReactNode = null
    switch (enrollment.canClose) {
      case Api.EnrollmentCanCloseState.cannotCloseHealthDetailsRequired:
        title = 'Gratulálunk, sikeres vizsgát tett!'
        description = this.healthCourseDetailsRequiredDescription()
        break
      case Api.EnrollmentCanCloseState.canClose:
        title = 'Gratulálunk, sikeres vizsgát tett!'
        if (isHealthCourse) {
          description = this.healthCourseSuccessDescription()
        } else {
          description = this.generalCourseSuccessDescription()
        }
        break
      case Api.EnrollmentCanCloseState.cannotCloseAlreadyClosed:
        title = 'Sikeresen elvégezte és lezárta a kurzust.'
        if (isHealthCourse) {
          description = this.healthCourseClosedDescription()
        } else {
          description = this.generalCourseClosedDescription()
        }
        break
      case Api.EnrollmentCanCloseState.cannotCloseFailed:
        title = `Teljesítménye sajnos nem érte el a ${enrollment.passingScore}%-ot`
        description = this.failedDescription()
    }

    return <>

      <div className="info-box content box">
        <span className="icon is-left has-text-info">
          <i className={icon + ' pull-left'}></i>
        </span>

        { title }
        <ul style={{ 'listStyle': 'none' }}>
          <li >
            { description }
          </li>
        </ul>
      </div>
    </>
  }

  private healthCourseSuccessDescription () {

    return <>
      <p>
        A <strong>Kurzus lezárása és tanúsítvány igénylése</strong> gombra kattintva igényelheti a tanúsítványát. A tanúsítványt postán fogjuk elküldeni.
      </p>
      <p>
        Tanúsítvány igénylése előtt kérjük, <b>ellenőrizze adatait</b> az <NavLink to="/adataim">Adataim</NavLink> oldalon, mivel ezek az adatok fognak rákerülni a tanúsítványra.
      </p>
    </>
  }

  private healthCourseClosedDescription () {

    return <>
      <p>
        A tanúsítványt <strong>postán</strong> küldjük.
      </p>
    </>
  }

  private healthCourseDetailsRequiredDescription () {

    return <>
      <p>
        <strong>Hiányoznak az egészségügyi szakdolgozói adatok</strong>. A tanúsítvány igénylése előtt, kérjük, az <NavLink to="/adataim">Adataim</NavLink> oldalon jelölje be, hogy <strong>Egészségügyi szakdolgozó</strong>, majd adja meg a szükséges adatokat.
      </p>
      <p>
        Ezután a <strong>Kurzus lezárása és tanúsítvány igénylése</strong> gombra kattintva igényelheti a tanúsítványát. A tanúsítványt postán fogjuk elküldeni.
      </p>
    </>
  }

  private generalCourseSuccessDescription () {
    return <>
      <p>
        A <strong>Kurzus lezárása és tanúsítvány nyomtatása</strong> gombra kattintva kinyomtathatja tanúsítványát.
      </p>
      <p>
        Tanúsítvány nyomtatása előtt kérjük, <b>ellenőrizze adatait</b> az <NavLink to="/adataim">Adataim</NavLink> oldalon, mivel ezek az adatok fognak rákerülni a tanúsítványra.
      </p>
    </>
  }

  private generalCourseClosedDescription () {
    return <>
      <p>
        A <strong>Tanúsítvány nyomtatása</strong> gombra kattintva újra kinyomtathatja tanúsítványát.
      </p>
    </>
  }

  private failedDescription () {
    return <>
      <p>
        Lehetősége van a tanfolyamot újból elindítani és a zárótesztet kitölteni, ehhez, kérjük, nyomja meg a <strong>Kurzus megnyitása</strong> gombot!
      </p>
    </>
  }
}

const withCloseAndPrintMutation = ReactApollo.graphql<{}, Props>(
  CLOSE_COURSE_AND_PRINT_CERTIFICATE_MUTATION, {
    name: 'closeCourseAndPrintCertificate'
  })

const withPrintMyCertificateMutation = ReactApollo.graphql<{}, Props>(
  PRINT_MY_CERTIFICATE_MUTATION, {
    name: 'printMyCertificate'
  })

const withCloseCourseMutation = ReactApollo.graphql<{}, Props>(
  CLOSE_COURSE_MUTATION, {
    name: 'closeMyCourse'
  })

const withQuery = ReactApollo.graphql<MyEnrollmentsResponse, Props>(MY_ENROLLMENTS_QUERY)

export const MyCourses = ReactApollo.compose(withCloseAndPrintMutation,
                                             withPrintMyCertificateMutation,
                                             withCloseCourseMutation,
                                             withQuery)(MyCourses_)
