import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import { ApolloClient } from 'apollo-client'
import { lazyInject } from 'ui/di'

import * as Api from '../api'
import * as WithAuthState from './with-auth-state'
import { UIConfig } from 'ui-config/ui'
import { toast } from 'ui/toast'
import * as ErrorHandler from 'error-handler/ui'

export interface LoginPanelApi {
  loginByPassword (email: string, password: string): Promise<Api.LoginByPasswordResultCode>
  logout: (e: any) => Promise<void>
  details: () => {
    fullName: string
  }
}

/* type ChildProps = ReactApollo.ChildProps<{}, AuthStateResponse> & {
 *   client: ApolloClient<any>
 * } */

type InputProps = {
  loggedInPanel: (api: LoginPanelApi) => React.ReactNode
  notLoggedInPanel: (api: LoginPanelApi) => React.ReactNode
}

type Props = InputProps & WithAuthState.WithAuthStateChildProps & {
  client: ApolloClient<any>
}

/* interface Props {
 *   data: ReactApollo.QueryProps & AuthStateResponse
 *   client: ApolloClient<any>
 *   loggedInPanel: (api: LoginPanelApi) => React.ReactNode
 *   notLoggedInPanel: (api: LoginPanelApi) => React.ReactNode
 * } */

/**
 * General component which can be used for presenting login and logout panels.
 * Login logic is implemented in this component, concrete visual representation can be
 * configured using @loggedInPanel and @notLoggedInPanel render functions.
 */
class LoginPanel_ extends React.Component<Props> {

  @lazyInject('UIConfig')
  private config: UIConfig

  private api: LoginPanelApi

  constructor (props: Props) {
    super(props)

    let self = this
    this.api = {
      logout: this.handleLogoutClick.bind(this),
      loginByPassword: this.handleLoginByPassword.bind(this),
      details: () => {
        if (!this.props.authState.isLoggedIn) {
          return { fullName: '' }
        }

        return {
          fullName: this.props.authState.details!.fullName
        }
      }
    }
  }

  render () {
    if (this.props.authState.isUnknown) {
      return null
    }

    if (this.props.authState.isLoggedIn) {
      return this.loggedInPanel()
    } else {
      return this.notLoggedInPanel()
    }
  }

  private loggedInPanel () {
    if (!this.props.loggedInPanel) {
      return <div>LoggedIn</div>
    }

    return this.props.loggedInPanel(this.api)
  }

  private notLoggedInPanel () {
    if (!this.props.notLoggedInPanel) {
      return <div>NotLoggedIn</div>
    }

    return this.props.notLoggedInPanel(this.api)
  }

  private async handleLoginByPassword (username: string, password: string) {

    try {

      let response = await fetch(this.config.publicUrl + '/login', {
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body: 'email=' +
              encodeURIComponent(username) +
              '&password=' +
              encodeURIComponent(password)
      })

      if (response.ok) {
        const result = await response.json()
        if (result.success) {
          await this.props.client.resetStore()
          return Api.LoginByPasswordResultCode.ok
        } else {
          let reason: string = result.reason
          return parseInt(reason, 10) as Api.LoginByPasswordResultCode
        }
      } else {
        ErrorHandler.handlePostError(response)
      }
    } catch (err) {
      ErrorHandler.handlePostError(err)
    }

    return Api.LoginByPasswordResultCode.unknownError
  }

  private async handleLogoutClick () {

    let result = await fetch(this.config.publicUrl + '/logout', {
      method: 'POST',
      credentials: 'same-origin'
    })
    toast.success('Sikeres kijelentkezés')
    // await this.props.client!.resetStore()

    try {
      // TODO: hard coded moodle url
      let moodleResult = await fetch(this.config.publicUrl + '/tananyagok/login/logout.php', {
        method: 'GET',
        credentials: 'same-origin'
      })
    } catch (err) {
      console.error(`Couldn't logout from moodle`, err)
    }

    let url = this.config.publicUrl
    setTimeout(() => { location.assign(url) }, 50)
  }
}

const WithAuthStateWrapper = WithAuthState.withAuthState(LoginPanel_)
export const LoginPanel = ReactApollo.withApollo(WithAuthStateWrapper)
