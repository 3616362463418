import * as React from 'react'
import { NavLink } from 'react-router-dom'
import * as classNames from 'classnames'
let Markdown = require('react-remarkable')

interface Props {
  name: string
  subtitle: string
  urlName: string
  imageUrl: string
  shortDescription: string
  isLoggedIn: boolean
}

enum DisplayMode {
  mobile,
  tablet
}

const imagesPath = require.context('./images')

export class FrontpageCourseBox extends React.PureComponent<Props> {

  render () {

    return (
      <div className="content">
        {this.courseBox(DisplayMode.mobile)}
        {this.courseBox(DisplayMode.tablet)}
      </div>
    )
  }

  private courseBox (mode: DisplayMode) {

    let courseColumnsClasses = classNames('columns course-box kk-box-shadow', {
      'is-hidden-mobile': mode === DisplayMode.tablet,
      'is-hidden-tablet': mode === DisplayMode.mobile
    })

    let courseColumnGapClasses = classNames('column is-one-third', {
      'is-paddingless': mode === DisplayMode.tablet
    })

    return <>
      <div className={courseColumnsClasses}>

        <div className={courseColumnGapClasses}>

            {this.courseTitleContainer(DisplayMode.tablet)}
            <div className="image">
              {this.getPic(this.props.imageUrl)}
            </div>

            {this.courseButtons(DisplayMode.mobile)}
            {this.courseButtons(DisplayMode.tablet)}

        </div>

        <div className="column">
          {this.courseTitleContainer(DisplayMode.mobile)}
          {this.courseShortDescription()}
        </div>

      </div>
    </>
  }

  private courseButtons (mode: DisplayMode) {

    let courseButtonsClasses = classNames('columns is-gapless', {
      'is-mobile is-multiline is-hidden-mobile': mode === DisplayMode.tablet,
      'is-mobile is-hidden-tablet is-marginless': mode === DisplayMode.mobile
    })

    let courseButtonClasses = classNames('column is-one-third-mobile', {
      'is-12': mode === DisplayMode.tablet
    })

    return (
      <div className={courseButtonsClasses}>
        <div className={courseButtonClasses}>
          {this.enrollButton()}
        </div>
        <div className={courseButtonClasses}>
          <NavLink to="/kapcsolat" className="button course-button is-contact is-fullwidth is-uppercase">
              {this.courseIconWithText(imagesPath('./course-icon-contact.png'), 'Érdeklődöm')}
          </NavLink>
        </div>
        <div className={courseButtonClasses}>
          <NavLink to={this.coursePath()} className="button course-button is-thematics is-fullwidth is-uppercase">
            {this.courseIconWithText(imagesPath('./course-icon-thematics.png'), 'Tematika')}
          </NavLink>
        </div>
      </div>
    )
  }

  private courseTitleContainer (mode: DisplayMode) {

    let subtitle = this.props.subtitle ? <NavLink to={this.coursePath()} className="subtitle is-6">({this.props.subtitle})</NavLink> : ''

    let courseTitleContainerClasses = classNames('course-title-container', {
      'is-hidden-mobile': mode === DisplayMode.mobile,
      'is-hidden-tablet': mode === DisplayMode.tablet
    })

    let courseTitleContainerGapClasses = classNames('', {
      'gap-tablet': mode === DisplayMode.mobile,
      'gap-mobile': mode === DisplayMode.tablet
    })

    return (
      <div className={courseTitleContainerClasses}>
        <div className={courseTitleContainerGapClasses}>
          <div className="media">
            <div className="media-left">
              <figure className="image is-32x32">
                <img src={imagesPath('./course-logo.png')} />
              </figure>
            </div>
            <div className="media-content">
              <NavLink to={this.coursePath()} className="title is-4">{this.props.name}</NavLink>
              <br/>
              {subtitle}
            </div>
          </div>
        </div>
      </div>
    )
  }

  private courseShortDescription () {

    return (
      <>
        <Markdown source={this.props.shortDescription} />
      </>
    )
  }

  private enrollButton () {
    let path = this.props.isLoggedIn ? '/kurzus-regisztracio' : '/regisztracio'

    return <>
      <NavLink to={path} className="field button course-button is-enroll is-fullwidth is-uppercase">
        {this.courseIconWithText(imagesPath('./course-icon-enroll.png'), 'Beiratkozom a kurzusra')}
      </NavLink>
    </>
  }

  private coursePath () {
    return `/kurzus/${this.props.urlName}`
  }

  private courseIconWithText (icon: string, text: string) {
    return <>
      <span className="has-text-center">
        <span className="course-icon">
          <img src={icon} />
        </span>
        <span>{text}</span>
      </span>
    </>
  }

  private getPic (imageUrl: string) {
    return <img src={imageUrl} />
  }

}
