import * as V from 'validation'
import { ChangePasswordValidationResult, ChangePasswordInput } from './change-password-input'

export interface ChangeMyPasswordInput {
  currentPassword: string
  newPassword: ChangePasswordInput
}

export type ChangeMyPasswordValidationResult = {
  currentPassword?: V.RequiredValidationResult | V.StringValidationResult
  newPassword?: ChangePasswordValidationResult
}

export type ChangeMyPasswordCommand = ChangeMyPasswordInput

export enum ChangeMyPasswordCommandResultCode {
  success,
  validationError,
  invalidCurrentPassword
}

export type ChangeMyPasswordCommandResult = {
  code: ChangeMyPasswordCommandResultCode,
  validation?: string
}
