export class ValidationError<T> {

  private errorObj: T
  private isErrorSet: boolean = false

  constructor (defaultValue: T) {
    this.errorObj = defaultValue
  }

  setError (error: T) {
    this.errorObj = error
    this.isErrorSet = true
  }

  setMember (key: keyof T, value: T[keyof T]) {
    this.errorObj[key] = value
    this.isErrorSet = true
  }

  get hasError () {
    return this.isErrorSet
  }

  get error () {
    return this.errorObj
  }
}
