import { ValidationError, InputResolver } from '../../../validation'
import { EducationIdValidationResult } from './education-id-validation-result'
import { validateOMCode } from './om-code-validator'

export class EducationIdInputResolver implements InputResolver<string, string, EducationIdValidationResult> {

  resolve (input: string) {

    const isValid = validateOMCode(input)
    if (isValid) {
      return input
    }

    return new ValidationError<EducationIdValidationResult>('invalid-education-id')
  }
}

export function educationId (): InputResolver<string, string, EducationIdValidationResult> {

  return new EducationIdInputResolver()
}
