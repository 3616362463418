import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as ErrorHandler from 'error-handler/ui'
import * as Icon from 'ui/icon'

type LoadingProps<TData, TGraphQLVariables = ReactApollo.OperationVariables> = {
  data: ReactApollo.DataValue<TData, TGraphQLVariables>
  children: (data: Partial<TData>) => React.ReactNode
  frame?: (children: React.ReactNode) => React.ReactNode
}

interface State {
  isRefetching: boolean
}

export class WithLoading<TData, TGraphQLVariables = ReactApollo.OperationVariables> extends React.Component<LoadingProps<TData, TGraphQLVariables>, State> {

  constructor (props: any) {
    super(props)

    this.state = {
      isRefetching: false
    }
  }

  componentDidUpdate (prevProps: LoadingProps<TData, TGraphQLVariables>, prevState: State) {
    if (!this.props.data.loading && prevState.isRefetching) {
      this.setState({
        isRefetching: false
      })
    }
  }

  render () {

    if (this.props.data.loading) {
      return this.loading()
    }

    if (this.props.data.error) {
      return this.error(this.props.data.error)
    }

    return this.props.children(this.props.data)
  }

  private error (err: Error) {
    if (this.props.frame) {
      return this.props.frame(this.errorMessage(err))
    }

    return this.errorMessage(err)
  }

  private errorMessage (err: Error) {
    return <ErrorHandler.ErrorDisplay
             loading={this.isLoading()}
             onRefresh={this.refetch.bind(this)}
             error={err} />
  }

  private loading () {
    if (this.props.frame) {
      return this.props.frame(this.loadingAnimation())
    }

    return this.loadingAnimation()
  }

  private loadingAnimation () {
    return <div className="columns is-centered">
      <div className="column is-narrow">
        <span className="icon has-text-info is-medium">
          <i className={Icon.fontAwesome('spinner', 'spin', '2x')}></i>
        </span>
      </div>
    </div>
  }

  private refetch () {
    this.props.data.refetch()
    this.setState({
      isRefetching: true
    })
  }

  private isLoading () {
    return this.state.isRefetching
  }
}
