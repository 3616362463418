import * as React from 'react'
import * as classNames from 'classnames'

export type FieldLayout = {
  label: string
  help?: string | React.ReactNode
  horizontal?: boolean
  leftIcon?: string
  rightIcon?: string
  validationDisplay?: (validation: FieldFeedback) => React.ReactNode
  validationErrorDisplay?: (error: string) => React.ReactNode
}

export type FieldFeedback = {
  error?: string
  warning?: string
  success?: string
}

type Props = FieldLayout & { feedback: FieldFeedback }

export class FieldDecorator extends React.PureComponent<Props> {

  render () {

    if (this.props.horizontal) {
      return this.horizontalField()
    } else {
      return this.normalField()
    }

  }

  private normalField () {
    let controlClasses = classNames('control', {
      'has-icons-left': !!this.props.leftIcon,
      'has-icons-right': !!this.props.rightIcon
    })

    return (
      <div className="field">
        <label className="label">{this.props.label}</label>
        <div className={controlClasses}>
          {this.props.children}
        </div>
        {this.help()}
        {this.feedback()}
      </div>
    )
  }

  private horizontalField () {

    let controlClasses = classNames('control is-expanded', {
      'has-icons-left': !!this.props.leftIcon,
      'has-icons-right': !!this.props.rightIcon
    })

    return (
      <div className="field is-horizontal">
        <div className="field-label is-normal">
          <label className="label">{this.props.label}</label>
        </div>
        <div className="field-body">
          <div className="field">
            <div className={controlClasses}>
              {this.props.children}
            </div>
            {this.help()}
            {this.feedback()}
          </div>
        </div>
      </div>
    )
  }

  private help () {
    if (!this.props.help) {
      return null
    }

    return <div className="help">{this.props.help}</div>
  }

  private feedback () {
    let feedback = this.props.feedback
    if (!feedback) {
      return null
    }

    if (this.props.validationDisplay) {
      return this.props.validationDisplay(feedback)
    }

    return this.defaultValidationDisplay(feedback)
  }

  private defaultValidationDisplay (feedback: FieldFeedback) {
    return <>
    {this.success(feedback)}
    {this.error(feedback)}
    {this.warning(feedback)}
    </>
  }

  private error (feedback: FieldFeedback) {
    if (!feedback.error) {
      return null
    }

    return <div className="help is-danger">{this.errorContent(feedback.error)}</div>
  }

  private errorContent (error: string) {
    if (this.props.validationErrorDisplay) {
      return this.props.validationErrorDisplay(error)
    }

    return error
  }

  private warning (feedback: FieldFeedback) {
    if (!feedback.warning) {
      return null
    }

    return <p className="help is-warning">{feedback.warning}</p>
  }

  private success (feedback: FieldFeedback) {
    if (!feedback.success) {
      return null
    }

    return <p className="help is-success">{feedback.success}</p>
  }
}
