import { CourseInput } from './course-input'

export enum CreateCourseResultCode {
  success,
  validationError
}

export interface CreateCourseCommandResult {
  code: CreateCourseResultCode,
  validation?: string
}

export interface CreateCourseCommand extends CourseInput {

}
