import { CourseEnrollmentsFilter } from './course-enrollments.query'

export interface ExportEnrollmentsCommand {
  courseId: string

  filter?: CourseEnrollmentsFilter
}

export enum ExportEnrollmentsCommandResultCode {
  success
}

export interface ExportEnrollmentsCommandResult {
  code: ExportEnrollmentsCommandResultCode
  url?: string
}
