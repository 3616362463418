import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as Api from '../api'
import * as ResolveCityQuery from './resolve-city.query'

type PublicProps = {
  countryId?: string
  postalCode?: string
}

type InputProps = Form.ComponentProps<string> & PublicProps

type Props = ReactApollo.ChildDataProps<InputProps, ResolveCityQuery.ResolveCityResponse, ResolveCityQuery.ResolveCityVariables>

class ResolveCityField_ extends React.Component<Props> {

  componentWillReceiveProps (nextProps: Props) {

    let nextCities = this.cities(nextProps)
    let currentValue = nextProps.fieldApi.value

    let nextValue: string | undefined = currentValue
    if (nextCities.length === 1) {
      nextValue = nextCities[0].id
    }
    if (currentValue && nextCities.findIndex(x => x.id === currentValue) < 0) {
      nextValue = undefined
    }

    if (nextValue !== currentValue) {
      this.props.fieldApi.setValue(nextValue)
    }
  }

  render () {
    return <Form.SelectFieldControl
             fieldApi={this.props.fieldApi}
             options={this.options()}
    />
  }

  private options () {
    let cities = this.cities(this.props)
    if (!cities) {
      return []
    }

    return cities.map(x => ({ value: x.id, label: x.name }))
  }

  private cities (props: Props): Api.CityInfo[] {
    if (props.data.resolveCity) {
      return props.data.resolveCity
    }

    return []
  }
}

const withResolveCity = ReactApollo.graphql<InputProps, ResolveCityQuery.ResolveCityResponse, ResolveCityQuery.ResolveCityVariables>(ResolveCityQuery.RESOLVE_CITY_QUERY, {
  options: (props) => ({ variables: { filter: {
    postalCode: props.postalCode,
    countryId: props.countryId }
  } })
})

const ResolveCityFieldWithData = withResolveCity(ResolveCityField_)

export const ResolveCityField = Form.withField<PublicProps & Form.FieldLayout, string>(
  Form.withFieldDecorator(ResolveCityFieldWithData))
