import * as ReactForm from 'react-form'

/**
 * Recursively walks the form and set validation error based on validation structure
 */
export function showValidationErrors (validation: any, api: ReactForm.FormApi<any>) {
  setErrorRecursive([], validation, api)
}

function setErrorRecursive (path: ReactForm.FieldPath, validation: any, api: ReactForm.FormApi<any>) {
  let keys = Object.keys(validation)

  for (let key of keys) {
    if (key.startsWith('__')) {
      continue
    }

    let value = validation[key]
    if (!value) {
      continue
    }

    let newPath = [path, key]

    if (typeof value === 'object') {
      setErrorRecursive(newPath, value, api)
    } else {
      api.setError(newPath, value)
    }
  }
}
