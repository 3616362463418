import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import * as Api from './api'

export class ExpectedInputResolver<T> implements InputResolver<T, T, Api.ExpectedValidationResult> {

  constructor (private expectedValue: T) {
  }

  resolve (input: T): ValidationError<Api.ExpectedValidationResult> | T {

    if (input !== this.expectedValue) {
      return new ValidationError<Api.ExpectedValidationResult>('value-not-equal-to-expected')
    }

    return input
  }
}

export function expected<T> (expected: T): InputResolver<T, T, Api.ExpectedValidationResult> {

  return new ExpectedInputResolver(expected)
}
