import * as React from 'react'
import * as Router from 'react-router'
import { Helmet } from 'react-helmet'
import { UIConfig } from 'ui-config/ui'
import { lazyInject } from 'ui/di'

type Type = 'website' | 'article'

interface Props {
  type: Type
  title: string
  subtitle?: string
  description?: string
  image?: string
  siteTitle?: string
}

class SiteMetaBase extends React.Component<Props & Router.RouteComponentProps<any>> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render() {
    return <Helmet>
      {this.subtitle()}
      {this.siteTitle()}
      <meta property="og:title" content={this.props.title} />
      {this.description()}
      {this.ogDescription()}
      <meta property="og:type" content={this.props.type} />
      <meta property="og:url" content={this.config.publicUrl + this.props.match.url} />
      <meta property="og:locale" content="hu_HU" />
      {this.image()}
    </Helmet>
  }

  private subtitle() {
    if (!this.props.subtitle) {
      return null
    }

    return <meta property="subtitle" content={this.props.subtitle} />
  }

  private description() {
    if (!this.props.description) {
      return null
    }

    return <meta property="description" content={this.props.description} />
  }

  private ogDescription() {
    if (!this.props.description) {
      return null
    }

    return <meta property="og:description" content={this.props.description} />
  }

  private image() {
    if (!this.props.image) {
      return null
    }

    return <meta property="og:image" content={this.props.image} />
  }

  private siteTitle() {
    if (!this.props.siteTitle) {
      return null
    }

    return <meta property="og:site_name" content={this.props.siteTitle} />
  }
}

export const SiteMeta = Router.withRouter<Props & Router.RouteComponentProps<any>>(SiteMetaBase)
