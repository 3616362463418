import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import gql from 'graphql-tag'
import { NavLink } from 'react-router-dom'
import * as RegistrationApi from 'registration/api'
import { RegistrationForm } from './registration-form'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { toast } from 'ui/toast'

// TODO: refactoring validation fragments
const registerMutation =
  gql`mutation register($input: RegisterInput!) {
    register(input: $input) {
      code
      validation {
        courseCode
        account { email, password }
        person {
          name { firstName, lastName, birthName }
          birth { birthDate, birthPlace }
          profession
        }
        healthPerson {
          name { firstName, lastName, birthName }
          birth { birthDate, birthPlace }
          motherName
          education {
            educationLevelId
            educationId
          }
          qualifications {
            professionGroupId
            qualificationId
          }
          institution {
            name
            operationArea {
              operationAreaId
              otherOperationArea
              surgeryId
            }
            address {
              countryId
              postalCode
              cityId
              cityName
              street
              streetTypeId
              houseNumber
              others
            }
          }
        }
        approveContract
        approvePrivacyPolicy
      }
    }
  }
  `

interface Props {
  mutate: ReactApollo.MutationFunc<{ register: RegistrationApi.RegisterCommandResult },
  { input: RegistrationApi.RegisterCommand }>
}

enum Phase {
  registration,
  onlyRegistrationSucceeded,
  registrationAndEnrollmentSucceeded
}

interface State {
  phase: Phase
}

export class Registration_ extends React.Component<Props, State> {

  constructor (props: Props) {
    super(props)
    this.state = {
      phase: Phase.registration
    }
  }

  render () {
    return <>

    <Breadcrumb end="Regisztráció" />

    <PageTitle title="Regisztráció" />

    <section className="section profile-data">
      <div className="columns is-centered">
        <div className="column is-four-fifths">
          {this.content()}
        </div>
      </div>
    </section>
    </>
  }

  private content () {
    switch (this.state.phase) {
      case Phase.registration:
        return this.form()
      case Phase.onlyRegistrationSucceeded:
        return this.onlyRegistrationSucceeded()
      case Phase.registrationAndEnrollmentSucceeded:
        return this.registrationAndEnrollmentSucceeded()
    }
  }

  private form () {
    return <Form.Form onSubmit={this.handleSubmit.bind(this)} onSubmitFailure={this.handleSubmitFailure.bind(this)}>
      { formApi => {
        return <>
          <RegistrationForm formApi={formApi} />
        </>
      }}
    </Form.Form>
  }

  private async handleSubmitFailure () {
    toast.warn('Kérem, ellenőrizze az űrlapon megadott adatokat!')
  }

  private async handleSubmit (model: any, e: any, formApi: Form.FormApi<any>) {

    let result = await this.props.mutate({
      variables: { input: model }
    })

    let data = result.data.register

    switch (data.code) {
      case RegistrationApi.RegisterCommandResultCode.validationError:
        toast.warn('Kérem, ellenőrizze az űrlapon megadott adatokat!')
        Form.showValidationErrors(data.validation, formApi)
        break
      case RegistrationApi.RegisterCommandResultCode.registrationSucceeded:
        this.setState({ phase: Phase.onlyRegistrationSucceeded })
        break
      case RegistrationApi.RegisterCommandResultCode.registrationAndEnrollmentSucceeded:
        this.setState({ phase: Phase.registrationAndEnrollmentSucceeded })
        toast.success('Sikeres regisztráció és kurzusregisztráció!')
        break
    }
  }

  private onlyRegistrationSucceeded () {
    return (
      <article className="message is-warning">
        <div className="message-header">
          <p>Sikertelen kurzusregisztráció</p>
        </div>
        <div className="message-body">
          <p>Sajnos a kurzusregisztráció nem sikerült, valószínűleg már felhasználták a kódot.</p>
        </div>
      </article>
    )
  }

  private registrationAndEnrollmentSucceeded () {
    return (
      <article className="message is-success">
        <div className="message-header">
          <p>Sikeres regisztráció</p>
        </div>
        <div className="message-body">
          <p>Hamarosan kap egy e-mailt, ami tartalmazza a fiók aktiválásához szükséges információkat. Ha pár percen belül nem érkezik meg levelezőrendszerébe a levél, kérjük, nézze meg a spam mappát is.</p>
          <p>A fiók aktiválása után a <NavLink to="/belepes">Bejelentkezés</NavLink> oldalon be tud jelentkezni a megadott email címmel és jelszóval.</p>
        </div>
      </article>
    )
  }
}

export const Registration = ReactApollo.graphql(registerMutation)(Registration_)
