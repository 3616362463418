import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'
import * as AccountUI from 'account/ui'
import * as ErrorHandler from 'error-handler/ui'
import * as Api from '../api'
import { toast } from 'ui/toast'
import { ChangePasswordModel } from './change-password-fields'

export const CHANGE_MY_PASSWORD_MUTATION = gql`
  mutation changeMyPassword($input: ChangeMyPasswordInput!) {
    changeMyPassword(input: $input) {
      code
      validation
    }
  }
`

interface ChangeMyPasswordResponse {
  changeMyPassword: Api.ChangeMyPasswordCommandResult
}

interface ChangeMyPasswordVariables {
  input: Api.ChangeMyPasswordCommand
}

interface Model {
  currentPassword: string
  newPassword: ChangePasswordModel
}

type InputProps = {}
type Props = ReactApollo.ChildMutateProps<InputProps, ChangeMyPasswordResponse, ChangeMyPasswordVariables>

class ChangeMyPassword_ extends React.Component<Props> {

  render () {
    return <>
      <Form.Form onSubmit={this.handleSubmit.bind(this)}>
        {formApi => {
          return <>
            <form onSubmit={formApi.submitForm} autoComplete="off" autoSave="off" autoCorrect="off">
              <Form.InputField
                label="Jelenlegi jelszó:"
                type="password"
                field="currentPassword"
                autoComplete="off"
                autoCorrect="off"
                placeholder="Jelenlegi jelszó"
                leftIcon={Icon.fontAwesome('lock')}
                horizontal={true}
                validate={Validation.requiredValidator}
                validationErrorDisplay={
                  Validation.compose(Validation.requiredErrorDisplay)}
              />
              <AccountUI.ChangePasswordFields required field="newPassword" />
              <Form.SubmitCancelButtons
                formApi={formApi}
                cancelDefaultPath="/kurzusaim">Mentés</Form.SubmitCancelButtons>
            </form>
          </>
        }}
      </Form.Form>
    </>
  }

  private async handleSubmit (model: Model,
                              e: any, formApi: Form.FormApi<any>) {

    try {
      let result = await this.props.mutate({
        variables: {
          input: model
        }
      })

      let response = result.data.changeMyPassword

      switch (response.code) {
        case Api.ChangeMyPasswordCommandResultCode.success:
          toast.success('Sikeres jelszómódosítás')
          // this.props.history.push('/kurzusaim')
          break
        case Api.ChangeMyPasswordCommandResultCode.invalidCurrentPassword:
          formApi.setError('currentPassword', 'A megadott jelenlegi jelszó nem megfelelő')
          break
        case Api.ChangeMyPasswordCommandResultCode.validationError:
          toast.warn('Kérem, ellenőrize az űrlapon megadott értékeket!')
          Form.showValidationErrors(JSON.parse(response.validation!), formApi)
          break
      }
    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }
}

const withMutation = ReactApollo.graphql<InputProps, ChangeMyPasswordResponse, ChangeMyPasswordVariables>(CHANGE_MY_PASSWORD_MUTATION)

export const ChangeMyPassword = withMutation(ChangeMyPassword_)
