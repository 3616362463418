
export enum ResetPasswordResultCode {
  success,
  invalidToken,
  expiredToken,
  invalidPassword
}

export type ResetPasswordResult = ResetPasswordResultCode

export interface ResetPasswordCommand {
  token: string
  password: string
}
