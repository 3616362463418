// Entry point for the frontend in the client side
import 'reflect-metadata'
import configModule from 'ui-config/client/di'
import apolloModule from './client/apollo/di'
import reactModule from './client/react/di'
import container from 'ui/di/container'
import { configureClientErrorReporter } from 'error-report/client-error-report'
require('frontend/ui/styles/frontend.scss')

import frontendBootstrapModule from './client/frontend/di'
import { FrontendClientBootstrap } from 'client/frontend/frontend-client-bootstrap'

configureClientErrorReporter('frontend-client')

container.load(configModule)
container.load(apolloModule)
container.load(reactModule)

container.load(frontendBootstrapModule)

let bootstrap = container.get(FrontendClientBootstrap)
bootstrap.start()
