import * as React from 'react'
import { Status } from 'ui/router'
import { NavLink } from 'react-router-dom'

export const NotFound = () => (<Status status={404}>
  <div>
    <div className="error-container">
			<div className="error-content">
				<h1>
					<strong>404</strong>
					<span className="desc">A keresett oldalt nem találjuk!</span>
				</h1>
				<p>
					Lehet, hogy hibás hivatkozásra kattintott, vagy szerverünkön az oldal nem létezik.
				</p>
			</div>
			<div>
				<div className="links">
					<NavLink to="/">Ugrás a főoldalunkra</NavLink>
				</div>
			</div>
		</div>
  </div>
</Status>)
