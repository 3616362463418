export interface UIConfig {
  publicUrl: string
  baseUrl: string
}

export function defaultUIConfig () {
  return {
    publicUrl: '',
    baseUrl: ''
  }
}
