import * as React from 'react'
import * as Router from 'react-router'
import * as RouterDOM from 'react-router-dom'

type NotAuthorizedErrorProps = Router.RouteComponentProps<any>

class NotAuthorizedError_ extends React.Component<NotAuthorizedErrorProps> {

  render () {
    if (window && window.location) {
      // on client side we redirect using window location, because if the admin is separate app,
      // it won't redirect to /belepes
      let redirect = this.props.history.createHref(this.props.location)
      let href = this.props.history.createHref({ pathname: '/belepes', search: `redirect_url=${redirect}` })
      window.location.replace(href)
      return null
    }

    // on server side, it works
    return <RouterDOM.Redirect
             to={{
               pathname: '/belepes',
               state: { redirectLocation: this.props.location }
             }} />
  }
}

export const NotAuthorizedHandler = Router.withRouter<NotAuthorizedErrorProps>(NotAuthorizedError_)
