import * as PersonUI from 'person/ui'
import * as HealthPersonUI from 'health-person/ui'
import * as React from 'react'
import * as Form from 'ui/form'

export type Model = PersonUI.ProfileFormModel

export interface Props {
  formApi: Form.FormApi
}

export class ProfileForm extends React.Component<Props> {

  render () {

    let birthFields = null
    let motherFields = null
    if (this.isHealthPerson()) {
      birthFields = <PersonUI.BirthFields field="birth" isRequired={this.isHealthPerson()} />
      motherFields = <PersonUI.MotherFields field="motherName" isRequired={this.isHealthPerson()}/>
    }

    return <>
      <h6 className="label">Személyes adatok</h6>
      <div className="box">
        <PersonUI.NameFields field="name" birthNameVisible={this.isHealthPerson()} />
        {birthFields}
        {motherFields}
        <PersonUI.ProfessionFields field="profession" />
      </div>
      <div className="box">
        <Form.CheckboxField
          field="isHealthPerson"
          label="Egészségügyi szakdolgozó:"
          horizontal={true}
        >
          &nbsp;Kérjük, csak akkor jelölje be, amennyiben Ön egészségügyi szakdolgozó ÉS kreditpontos tanfolyamon vesz részt!
        </Form.CheckboxField>
      </div>
      <Form.NestedField field="health">
        {this.healthFields()}
      </Form.NestedField>
      <h6 className="label">Adatok tárolása</h6>
      <div className="box">
        <Form.ColumnCheckboxField
          field="acceptStoringData"
          label=""
        >
          Hozzájárulok, hogy a személyes adataimat az Eventus Coaching Centrum Kft. az általa nyújtott szolgáltatás befejezését követően is tárolja, valamint a részemre a jövőben tájékoztatást nyújtson programjairól, tréningjeiről.
        </Form.ColumnCheckboxField>
      </div>
    </>
  }

  componentDidUpdate (prevProps: Props) {

    let api = prevProps.formApi
    let health = api.getValue(api.getFullField('health'))
    let isHealth = this.isHealthPerson()

    if (!isHealth && health !== undefined) {
      // clear the health info
      this.props.formApi.setError('health', undefined)
      this.props.formApi.reset('health')

      this.props.formApi.setError('birth', undefined)
      this.props.formApi.reset('birth')

      this.props.formApi.setError('motherName', undefined)
      this.props.formApi.reset('motherName')

      this.props.formApi.setError('name.birthName', undefined)
      this.props.formApi.reset('name.birthName')
    }
  }

  private healthFields () {

    if (!this.isHealthPerson()) {
      return null
    }

    return <>
      {this.healthInfoBox()}
      <h6 className="label">Nyilvántartási szám</h6>
      <div className="box">
        <HealthPersonUI.RegistrationNumberField
          field="registrationNumber"
        />
      </div>
      <h6 className="label">Tanulmányok</h6>
      <div className="box">
        <HealthPersonUI.EducationFields field="education" />
      </div>
      <h6 className="label">Szakképesítés</h6>
      <div className="box">
        <HealthPersonUI.QualificationListField />
      </div>
      <h6 className="label">Értesítési cím</h6>
      <div className="box">
        <HealthPersonUI.NotificationAddressFields field="notificationAddress" />
      </div>
      <h6 className="label">Foglalkoztató intézmény</h6>
      <div className="box">
        <HealthPersonUI.InstitutionFields
          field="institution"
          isAddressOptional={!this.isNotificationAddressSameAsInstitution()}
        />
      </div>
    </>
  }

  private isNotificationAddressSameAsInstitution() {
    const sameAsInstitution = this.props.formApi.getValue(['health',
                                                           'notificationAddress',
                                                           'sameAsInstitution'])
    return sameAsInstitution
  }

  private isHealthPerson () {
    let api = this.props.formApi
    return !!api.getValue('isHealthPerson')
  }

  private healthInfoBox () {
    return <article className="message is-primary">
      <div className="message-body">
        A <strong>kreditigazolás</strong> kiállításához szükségesek az alábbi adatok, kérjük, adja meg ezeket.
      </div>
    </article>
  }
}
