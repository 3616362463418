import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as ProfileForm from './profile-form'
import * as Router from 'react-router'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { toast } from 'ui/toast'
import * as ErrorHandler from 'error-handler/ui'
import * as ProfileApi from 'person/api'
import * as Api from '../../api'
import * as AccountUI from 'account/ui'
import { WithLoading } from 'ui/with-loading'
const omitDeep = require('omit-deep-lodash')

import * as PersonUI from 'person/ui'

const UPDATE_MY_PROFILE_MUTATION =
  gql`mutation updateMyProfile($input: ProfileInput!) {
    updateMyProfile(input: $input) {
      code, validation
    }
  }
  `

const MY_PROFILE_QUERY = gql`
  query myProfile {
    myProfile {
      ...Profile
    }
  }
  ${PersonUI.profileFragment}
`

type ProfileQueryResponse = {
  myProfile: ProfileApi.Profile
}

type UpdateMyProfileResponse = {
  updateMyProfile: Api.UpdateMyProfileCommandResult
}

type UpdateMyProfileVariables = Api.UpdateMyProfileCommand

interface PublicProps {
}

type InputProps = Router.RouteComponentProps<{}>
type Props = ReactApollo.ChildDataProps<InputProps, ProfileQueryResponse> &
             ReactApollo.ChildMutateProps<InputProps, UpdateMyProfileResponse, UpdateMyProfileVariables>

class ProfileWithLoading extends WithLoading<ProfileQueryResponse> {
}

class MyProfile_ extends React.Component<Props> {

  render () {

    return <>
      <Breadcrumb end="Adataim" />
      <PageTitle title="Adataim" />
        {this.form()}
    </>
  }

  private form () {
    return <ProfileWithLoading
    data={this.props.data}
    frame={(children) => <section className="section">{children}</section>}>
      {(data) => {

        let profile = omitDeep(data.myProfile, '__typename')

        return <>
          <section className="section profile-data">
            <Form.Form
              onSubmit={this.handleSubmit.bind(this)}
              onSubmitFailure={this.handleSubmitFailure.bind(this)}
              defaultValues={profile}>
              {formApi => {
                return <>
                  <form onSubmit={formApi.submitForm}>
                    <ProfileForm.ProfileForm formApi={formApi} />
                    <Form.SubmitCancelButtons
                      formApi={formApi}
                      cancelDefaultPath="/kurzusaim">Mentés</Form.SubmitCancelButtons>
                  </form>
                </>
              }}
            </Form.Form>
          </section>
          <section className="section profile-data">
            <h5 className="title is-5">Jelszómódosítás</h5>
            <AccountUI.ChangeMyPassword />
          </section>
        </>
      }}
    </ProfileWithLoading>
  }

  private async handleSubmitFailure () {
    toast.warn('Kérem, ellenőrize az űrlapon megadott adatokat!')
  }

  private async handleSubmit (model: ProfileForm.Model,
                              e: any, formApi: Form.FormApi<any>) {

    try {
      let result = await this.props.mutate({
        variables: {
          input: model
        }
      })

      let response = result.data.updateMyProfile

      switch (response.code) {
        case Api.UpdateMyProfileResultCode.success:
          toast.success('Sikeres módosítás')
          this.props.history.push('/kurzusaim')
          break
        case Api.UpdateMyProfileResultCode.validationError:
          toast.warn('Kérem, ellenőrize az űrlapon megadott értékeket!')
          Form.showValidationErrors(JSON.parse(response.validation!), formApi)
          break
      }
    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }
}

const withQuery = ReactApollo.graphql<InputProps, ProfileQueryResponse>(MY_PROFILE_QUERY, {
  options: (props) => ({
    fetchPolicy: 'network-only'
  })
})

const withMutation = ReactApollo.graphql<InputProps, UpdateMyProfileResponse, UpdateMyProfileVariables>(UPDATE_MY_PROFILE_MUTATION)

export const MyProfile = Router.withRouter(ReactApollo.compose(withQuery, withMutation)(MyProfile_))
