import * as React from 'react'
import * as Icon from 'ui/icon'
import * as classNames from 'classnames'

export type ErrorDisplayType = 'warning' | 'info' | 'error'

interface Props {
  type: ErrorDisplayType
  title: string
  icon: string
  loading?: boolean
  onRefresh?: () => void
  children: React.ReactNode
  refreshButton?: React.ReactNode
}

export const ErrorPanel = (props: Props) => {

  let frameClasses = classNames('message', {
    'is-danger': props.type === 'error',
    'is-warning': props.type === 'warning',
    'is-info': props.type === 'info'
  })

  let btn = props.refreshButton
  if (!btn) {
    let buttonClasses = classNames('button is-medium', { 'is-loading': props.loading })

    btn = <button type="button" className={buttonClasses} onClick={props.onRefresh}>
      <Icon.Icon name={Icon.fontAwesome('sync')} />
      <span>Újra</span>
    </button>
  }

  return <div className="columns is-centered">
    <article className={frameClasses}>
      <div className="message-header">
        <p>{props.title}</p>
      </div>
      <div className="message-body">
        <div className="media">
          <figure className="media-left">
            <Icon.Icon medium color="has-text-grey" name={Icon.fontAwesome(props.icon, '2x')} />
          </figure>
          <div className="media-content">
            <div className="content">
              {props.children}
            </div>
          </div>
        </div>
        <div className="has-text-centered" style={{ 'marginTop': '15px' }}>
          {btn}
        </div>
      </div>
    </article>
  </div>
}
