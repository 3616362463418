import { EnrollmentCanCloseState } from './enrollment-view'

export interface CloseCourseAndPrintCertificateCommand {
  enrollmentId: string
}

export enum CloseCourseAndPrintCertificateCommandResultCode {
  success,
  failed
}

export interface CloseCourseAndPrintCertificateCommandResult {
  code: CloseCourseAndPrintCertificateCommandResultCode,
  reason: EnrollmentCanCloseState,
  url?: string
}
