import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
let Mailto: any = require('react-mailto.js').default
import { ScrollToTopOnMount } from 'ui/scroll'
import { NavLink } from 'react-router-dom'

export class ContactPolicy extends React.Component {

  section = 0
  subSection = 0

  render () {

    return <div>

      <ScrollToTopOnMount />
      <Breadcrumb end="Adatkezelés - Kapcsolat" />

      <PageTitle title="Adatkezelés - Kapcsolat" />

      <section className="section">

        <div className="contactpolicy-page">

          {/* <h2 className="title">Adatkezelés - Kapcsolat</h2> */}

          <div className="content">
            
          {this.sectionHtml(++this.section, 'Általános jogi közlemény')}

            <p>Mi, az Eventus Coaching Centrum Korlátolt Felelősségű Társaság (továbbiakban: Adatkezelő) 
               a <NavLink to="/" target="_blank">www.kepzeskozpont.com </NavLink> internetes weboldalt (továbbiakban: Weboldal) üzemeltetjük, 
               amely Weboldalon Ön bármikor kapcsolatba léphet velünk.</p>

            <p>Kizárólag azon nagykorú (18. életévüket betöltött) személyek adatait kezeljük, 
              akik a Weboldalon a kapcsolat menüpont alatt felveszik velünk a kapcsolatot.</p>     

            <p>A kapcsolatfelvétel az Ön hozzájárulásán alapul, amelyet Ön bármikor költségmentesen jogosult visszavonni
               a 2. pontban meghatározott valamely elérhetőségre küldött kérelem útján. 
               A visszavonás nem érinti ezen jog gyakorlását megelőzően végzett adatkezelés jogszerűségét.</p>  

          {this.sectionHtml(++this.section, 'Az adatkezelő megnevezése:')}

            <p>Eventus Coaching Centrum Kft.</p>
            <p>Székhely: 1112 Budapest, Felső Határ út 2/C. C. ép. fszt. 2.</p>
            <p>Cégjegyzékszám: 01-09-194829</p>
            <p>Adószám: 24890221-2-43</p>
            <p>E- mail cím: <Mailto to = "info@kepzeskozpont.com" secure = {true} > info@kepzeskozpont.com</Mailto></p>

          {this.sectionHtml(++this.section, 'Az adatkezelés jogalapja:')}

            <p>Tájékoztatjuk, hogy a kapcsoltfelvételre kizárólag az Ön hozzájárulása alapján kerül sor.</p>

            <p>A fentiek alapján az adatkezelés az információs önrendelkezési jogról és az információszabadságról szóló 
               2011. évi CXII. törvény (Info tv.), illetve az EURÓPAI PARLAMENT ÉS A TANÁCS (EU) 2016/679 RENDELETE (2016. április 27.) 
               a természetes személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad áramlásáról,
               valamint a 95/46/EK rendelet hatályon kívül helyezéséről (GDPR) 6. cikk (1) bekezdésének a) pontja szerinti hozzájáruláson 
               alapul.</p>

          {this.sectionHtml(++this.section, 'Adatkezelés célja:')}

            <p>Az adatkezelés célja, hogy Önnel felvegyük a kapcsolatot az Ön által küldött üzenet megválaszolása érdekében.</p>

          {this.sectionHtml(++this.section, 'Személyes adatok köre:')}

            <p>A kapcsolatfelvétel érdekében az alábbi adatok megadását kérjük:</p>
            <ul>
              <li>NÉV;</li>
              <li>EMAIL CÍM; ÉS</li>
              <li>ÜZENET TARTALMA.</li>
            </ul> 

            <p>Ön a fenti adatokon kívül megadhatja a telefonszámát is. A telefonszám megadása a könnyebb és gyorsabb kapcsolattartást teszi lehetővé.
               Ezen adat hiánya esetén azonban Önnel e-mailen keresztül vesszük fel a kapcsolatot.</p>     

            <p>Kérjük, hogy minden esetben valós adatokat adjon meg.</p>   

            <p>A fentieken kívül azonban nem kérünk és nem is fogadunk el más adat megadását, így kérjük, hogy más személyes adatot, 
               vagy különleges adatot (így pl. egészséges, politikai vagy vallási hovatartozására vonatkozó adatot) ne küldjön, 
               ne adjon meg és ne osszon meg velünk.</p>  

            <p>A személyes adatok gyűjtése és kezelése kizárólag az Ön hozzájárulásával és beleegyezésével, önkéntes adatszolgáltatás útján valósul meg.</p>      

            <p>A kezelt személyes adatokat kizárólag a kapcsolatfelvételhez szükséges mértékig, az ehhez szükséges terjedelemben kezeljük.</p>

          {this.sectionHtml(++this.section, 'Adatfeldolgozás, adattovábbítás')}

            <p>A kapcsolatfelvétel érdekében másnak nem adjuk át az adatait, más nem fér hozzá az Ön által megadott személyes adatokhoz.</p>

          {this.sectionHtml(++this.section, 'Adatkezelés időtartama:')}

            <p>A kapcsolatfelvétel célját, annak alapját szolgáló eset elintézését követően haladéktalanul, legkésőbb 30 napon belül töröljük az Ön személyes adatait. A törlésről – személyes adatoktól mentesített – jegyzőkönyvet veszünk fel.</p>

            <p>Az adatkezelés az adatbiztonsági követelményeknek megfelel, az Ön magánszférájának védelmét szem előtt tartva alakítottuk ki és hajtjuk végre a belső adatvédelmi 
               szabályzatunkban meghatározott műszaki és szervezési intézkedéseknek megfelelően.</p>  

          {this.sectionHtml(++this.section, 'Adatok tárolása')}

            <p>Az Ön által megadott adatokat elektronikus úton az Invitel Datacenter, 1143 Budapest, Ilka utca 31. b. szám alatt található szerverén tároljuk .</p>

          {this.sectionHtml(++this.section, 'Az Ön jogai, jogorvoslati lehetőségei')}

            <p>Személyes adatai kezelése esetén Önt – a fent meghatározott hozzájárulás visszavonásán túl – számos jog megilleti, amely jogokat a 2. pontban meghatározott valamely elérhetőségre küldött kérelem útján gyakorolhatja.</p>
            
            <p><strong>A személyes adatokhoz való hozzáférés és azok helyesbítésének joga</strong></p>
            <p>Személyes adataihoz bármikor jogosult hozzáférni, másolatot kérni, továbbá azokat helyesbíteni vagy frissíteni.</p>
            
            <p>A hozzáférési jog alapján Ön jogosult arra, hogy az alábbiakról információt kapjon:</p>
            <ol className="alpha">
              <li>az adatkezelés céljai;</li>
              <li>az érintett személyes adatok kategóriái;</li>
              <li>azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket; </li>
              <li>adott esetben a személyes adatok tárolásának tervezett időtartama;</li>
              <li>az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen; és</li>
              <li>a valamely felügyeleti hatósághoz címzett panasz benyújtásának joga.</li>
            </ol>

            <p>Értjük ennek fontosságát, ezért amennyiben élni kíván ezen jogaival, vegye fel velünk a kapcsolatot 2. pontban meghatározott valamely elérhetőségen keresztül.</p>

            <p><strong>Adathordozhatósághoz való jog</strong></p>
            <p>Személyes adatai hordozhatóak, így Ön jogosult arra, hogy tagolt, széles körben használt, géppel olvasható formátumban megkapja azokat. Ez azt jelenti, 
               hogy Ön az általunk kezelt személyes adatait számítógép által olvasható 
               és Ön által más személynek elektronikus úton átadható formátumban kapja meg.</p>

            <p>Amennyiben gyakorolni kívánja az adathordozhatósághoz való jogát, vegye fel az Adatkezelővel a kapcsolatot a 2. pontban meghatározott valamely elérhetőségén keresztül.</p>   

            <p><strong>A személyes adatok törlésének joga</strong></p>
            <p>Ön jogosult adatai törlését kérni, amennyiben</p>
            <ol className="alpha">
              <li>személyes adataira már nincs szükség azon cél(ok)hoz, amely(ek)re tekintettel azokat begyűjtöttük; vagy</li>
              <li>visszavonja a személyes adatai kezeléséhez adott korábbi hozzájárulását, és nincs egyéb jogalapja az adatkezelésnek; vagy</li>
              <li>tiltakozik személyes adatai kezelése ellen; </li>
              <li>a személyes adatok kezelése nem jogszerűen történik; vagy</li>
              <li>személyes adatainak törlését a jogszabályoknak való megfelelés indokolja.</li>
            </ol>

            <p>Amennyiben törölni kívánja az általunk tárolt személyes adatait, vegye fel velünk a kapcsolatot a 2. pontban meghatározott valamely elérhetőségen keresztül.</p>

            <p><strong>Az adatkezelés korlátozásának joga</strong></p>
            <p>Ön abban az esetben korlátozhatja személyes adatainak kezelését, amennyiben</p>
            <ol className="alpha">
              <li>úgy véli, hogy az Önnel kapcsolatban tárolt személyes adatok nem pontosak; vagy</li>
              <li>a személyes adatok kezelése nem jogszerűen történik, de ahelyett, hogy a törlésüket kérné, inkább korlátozni szeretné a kezelésüket; vagy</li>
              <li>személyes adataira nincs többé szükségünk azon cél(ok)hoz, amely(ek) miatt eredetileg begyűjtöttük azokat, Ön azonban igény tart ezen adatokra valamely peres követelés benyújtása, érvényesítése vagy a követeléssel szembeni védekezés céljából vagy </li>
              <li>Ön tiltakozott személyes adatai kezelése ellen és várja az arra vonatkozó visszaigazolást, hogy az Ön tiltakozással kapcsolatos érdekei felülírják-e az adatkezelés jogalapját.</li>
            </ol>

            <p>Amennyiben korlátozni kívánja személyes adatai kezelését, kérjük vegye fel velünk a kapcsolatot 2. pontban meghatározott valamely elérhetőségen keresztül.</p>

            <p><strong>A tiltakozás joga</strong></p>
            <p>Ön személyes adatainak kezelése ellen bármikor tiltakozhat. Ilyen szándék esetén vegye fel velünk a kapcsolatot a 2. pontban meghatározott valamely elérhetőségen keresztül.</p>

            <p><strong>Felügyeleti hatósághoz fordulás joga</strong></p>
            <p>Ön bármikor jogosult a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.; postacím: 1530 Budapest, Pf.: 5.; telefonszám: +36 (1) 391-1400; email cím: <Mailto to = "ugyfelszolgalat@naih.hu" secure = {true} >ugyfelszolgalat@naih.hu</Mailto>), mint felügyeleti hatósághoz.</p>

          </div>

        </div>

      </section>

    </div>
  }

  private sectionHtml (section: number, text: string) {
    this.subSection = 0

    return <h3>{section + '. ' + text}</h3>
  }

  private subSectionHtml (section: number, subSection: number, text: string) {
    return <h5>{section + '.' + subSection + '. ' + text}</h5>
  }

}
