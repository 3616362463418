import * as React from 'react'
import { lazyInject } from 'ui/di'
import { UIConfig } from 'ui-config/ui'

interface Props {
  className: string
}

export class HelpNavbarItem extends React.Component<Props> {

  @lazyInject('UIConfig')
  private config: UIConfig

  render () {
    let link = this.config.baseUrl + '/assets/utmutato/'
    return  <a target="_blank" href={link} className={this.props.className}>
      <span className="fas fa-question" />
    </a>
  }
}
