import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
let Mailto: any = require('react-mailto.js').default
import { ScrollToTopOnMount } from 'ui/scroll'
import { NavLink } from 'react-router-dom'

export class PrivacyPolicy extends React.Component {

  section = 0
  subSection = 0

  render () {

    return <div>

      <ScrollToTopOnMount />
      <Breadcrumb end="Adatkezelés" />

      <PageTitle title="Adatvédelmi tájékoztató" />

      <section className="section">

        <div className="privacy-policy-page">
          
          <div className="content">
            
              <p><strong>Fogalmak</strong></p>

              <p><strong>Online Felület</strong> jelenti az Eventus Coaching Centrum Kft által biztosított,
                a <NavLink to="/" target="_blank">www.kepzeskozpont.com </NavLink> útján elérhető internetes weboldalt.</p>

              <p><strong>Szolgáltató</strong> jelenti az Eventus Coaching Centrum Kft.-t (1112 Budapest, Felsőhatár út 2/C, C épület,
                Fsz.2., Cg. 13 09 194829, adószáma: 24890221-2-43.; email cím: <Mailto to="kepzes@kepzeskozpont.com" secure={true} > kepzes@kepzeskozpont.com</Mailto> ) mint a
                Program biztosítóját, amely a Személyes Adatok adatkezelője.</p>

              <p><strong>Program</strong> jelenti az Eventus Kft. által kifejlesztett és az Online Felületen nyújtott képzéseket.</p>

              <p><strong>Személyes Adat</strong> jelenti az Európai Parlament és a Tanács (EU) 2016/679 rendelete a természetes
                személyeknek a személyes adatok kezelése tekintetében történő védelméről és az ilyen adatok szabad
                áramlásáról, valamint a 95/46/EK irányelv hatályon kívül helyezéséről (továbbiakban: Általános
                adatvédelmi rendelet) 4. cikk 1. pontjában foglalt fogalommeghatározást.</p>


              {this.sectionHtml(++this.section, 'Kezelt adatok köre')}

               <p>A Szolgáltató által szervezett Programon való részvétel érdekében szükségszerűen sor kerülhet 
               Személyes Adatok kezelésére tekintettel a Program jellegére, valamint a résztvevők speciális körére 
               (foglalkozás-specifikus képzések). A kezelt Személyes Adatok körét a Szolgáltató a nyújtott szolgáltatás 
               biztosításához (azaz a Programon való részvételhez) szükséges mértékben – a jogszabályban 
               meghatározott adatokra is figyelemmel – az alábbi adatokra korlátozza:</p>

               <p><strong>1. Egészségszakmai képzések esetén:</strong></p>

              <ol>
                  <li>Név<p> Az érintett beazonosítása miatt szükséges megadni, továbbá az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat, valamint a felnőttképzésről szóló 2013. évi LXXVII. törvény 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére is jelenteni.</p></li>

                  <li> Születési név<p> Az érintett beazonosítása miatt szükséges megadni, továbbá az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat, valamint a felnőttképzésről szóló 2013. évi LXXVII. törvény 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére is jelenteni.</p></li>

                  <li> Születési hely, idő<p> Az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat, valamint a felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatot továbbá a 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére is jelenteni.</p></li>

                  <li> Anyja születési neve<p> Az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat, valamint a felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatot továbbá a 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére is jelenteni.</p></li>

                  <li> Működési, ennek hiányában alapnyilvántartási számát,<p> Az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat.</p></li>

                  <li> Szakképesítését (szakképesítéseit),<p> Az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat</p></li>

                  <li> Szakmacsoportját,<p> Az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI. 29.) NEFMI rendelet alapján a Programon való részvételről kiállítandó igazolás kötelező adata, és ezen rendelet alapján kötelezően továbbítandó adat.</p></li>

                  <li> Legmagasabb iskolai végzettség,<p> A felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatot, továbbá a 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére jelenteni.</p></li>

                  <li> Oktatási azonosító szám ,<p> A felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatot.
                    Amennyiben ilyen adattal a résztvevő nem rendelkezik, az Oktatási Hivatal állapítja meg a résztvevő részére.</p></li>

                  <li> Értesítési cím (város, irányítószám, megye, cím)<p> A Programon való részvételről kiállítandó igazolás megküldéséhez szükséges.</p></li>

                  <li>E-mail cím<p> A Programon való részvételhez szükséges, tekintettel arra, hogy az online képzés, továbbá a felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatot továbbá a 15. § (1) bekezdés alapján a Szolgáltató, mint felnőttképző köteles ezen adatot a felnőttképzési államigazgatási szerv részére jelenteni.</p></li>

                  <li>Képzéssel összefüggő adatok<p> A felnőttképzésről szóló 2013. évi LXXVII. törvény 21.§ (1) bekezdés alapján a Szolgáltató köteles kezelni ezen adatokat (a képzésbe történő belépésével és a képzés elvégzésével, illetve a képzés elvégzése hiányában a képzésből történő kilépésével, a képzés során történő értékelésével és minősítésével, a képzéssel összefüggő fizetési kötelezettségeivel és az igénybe vett képzési hitellel).</p></li>
              </ol>


              <p><strong>2. Egyéb képzések esetén:</strong></p>

              <ol>
                 <li>Név<p> Az érintett beazonosítása miatt szükséges, valamint a Programon való részvételről a résztvevőnek kiállítandó tanúsítvány kiállítása miatt szükséges megadni.</p></li>
                 <li>E-mail cím<p> A Programon való részvételhez szükséges, tekintettel arra, hogy az online képzés.</p></li>
                 <li>Kurzuskód <p> A Programba való bejelentkezéshez szükséges adat.</p></li>
                 <li>Jelszó<p> A Programba való bejelentkezéshez szükséges adat.</p></li>
              </ol>

              <p><strong>3. hírlevél küldése</strong></p>

              <p>Az Ön kifejezett külön hozzájárulása esetén a Szolgáltató hírleveleket küld Önnek a jövőben induló képzési programjairól, tréningjeiről. Ezen hozzájárulást bármikor költségmentesen visszavonhatja a Szolgáltató 1112 Budapest, Felsőhatár út 2/C, C épület, Fsz.2. szám alatti székhelyére küldött postai levél, vagy a <Mailto to="kepzes@kepzeskozpont.com" secure={true} >kepzes@kepzeskozpont.com</Mailto> email címre küldött levél útján. A hozzájárulás visszavonása nem érinti a visszavonást megelőző adatkezelés jogszerűségét.</p>

              <p>Hírlevél küldése érdekében a Szolgáltató a hírlevél küldéséhez szükséges adatokat, mégpedig az alábbi Személyes Adatokat kezeli:</p>

              <ol>
                <li>Név<p> Az érintett beazonosítása miatt szükséges megadni.</p></li>
                <li>E-mail<p> A hírlevél küldéséhez szükséges.</p></li>
                <li>Szakképesítés/Foglakozás<p> A Szolgáltató a szakképesítés alapján eltérő tartalmú hírlevelet küld az érintettnek.</p></li>
              </ol>

              <p>A szakképesítése megadására azért van szükség, mert az egyes szakképesítések alapján eltérő tartalmú hírlevél kerül megküldésre (így például egészségügyi szakképesítéssel rendelkező személyeknek az egészségszakmai képzésekről szóló hírlevél kerül megküldésre).</p>

              <p>Tájékoztatjuk, hogy Ön nem köteles a szakképesítését megadni, csakúgy mint a hírlevélre sem köteles feliratkozni. Önt semmilyen hátrány nem éri, ha nem adja meg a szakképesítését, mivel ebben az esetben Ön minden hírlevelet, annak tárgyára tekintet nélkül meg fog kapni.</p>

              <p>Amennyiben Ön nem ért egyet a kapott hírlevél témájával és ezzel kapcsolatos álláspontját el kívánja mondani a Szolgáltatónak, vagy más okból, de kapcsolatban kíván vele lépni, kérjük ezt a Szolgáltató 1112 Budapest, Felsőhatár út 2/C, C épület, Fsz.2. szám alatti székhelyére küldött postai levél, vagy a <Mailto to="kepzes@kepzeskozpont.com" secure={true} >kepzes@kepzeskozpont.com</Mailto> email címre küldött levél útján tegye meg.</p>

              <p>A Személyes Adatok gyűjtése nem terjed ki un. különleges adatok gyűjtésére, kezelésére.</p>


              {this.sectionHtml(++this.section, 'Adatkezelés jogalapja')}

              <p><strong>1. egészségszakmai képzés esetén:</strong></p>

              <p>Az egészségügyről szóló 1997. évi CLIV törvény (továbbiakban: Eütv.) 118.§ (1) bekezdése akként rendelkezik, hogy jogszabály továbbképzést kötelezően előírhat, a továbbképzésben történő részvétel feltételeinek egyidejű meghatározásával. Ez alapján az egészségügyi szakdolgozók továbbképzésének szabályairól szóló 63/2011. (XI.29.) NEFMI rendelet (továbbiakban: Rendelet) 1.§ (1) bekezdése kötelezővé teszi a Rendeletben meghatározott szakképesítéssel vagy szakképzettséggel rendelkező egészségügyi dolgozó számára egészségügyi szakmai továbbképzésen való részvételt. A Rendelet 12/A.§ (1) bekezdése értelmében a továbbképzésre kötelezett a továbbképzésre az általa választott továbbképzés szervezőjéhez, jelen esetben a Szolgáltatóhoz jelentkezik be. A továbbképzésre kötelezett a bejelentkezéssel egyidejűleg nyilatkozik arról, hogy hozzájárul a Rendeletben meghatározott adatainak kezeléséhez és a működési nyilvántartás vezetése érdekében történő továbbításához. </p>

              <p>A felnőttképzésről szóló 2013. évi LXXVII. törvény értelmében pedig ezen törvény rendelkezéseit kell alkalmazni a jogi személy által célirányosan kompetenciakialakításra vagy -fejlesztésre irányuló és szervezetten megvalósuló - a köznevelési intézmény, a szakképző intézmény, illetve törvényben meghatározott oktatási és tehetséggondozó intézmény alapfeladatába vagy a felsőoktatási intézmény alaptevékenységébe nem tartozó - oktatás és képzés üzletszerű gazdasági tevékenység keretében történő megszervezésére. Tekintettel arra, hogy az egészségszakmai továbbképzést a Rendelet teszi kötelezővé, így a felnőttképzési törvény 1.§ (2) bekezdése alapján a Szolgáltató bejelentési és adatszolgáltatási kötelezettségnek köteles eleget tenni. A felnőttképzési törvény 21. § (1) bekezdés értelmében az alábbi adatok kezelése törvényen (azaz a felnőttképzési törvény 21.§ (1) bekezdésén alapul):</p>

              <p>A felnőttképző a képzés lebonyolítása érdekében kezeli</p>
                <ol className="alpha">
                    <li>A képzésben részt vevő személy
                        <ol className="alpha">
                            <li>Természetes személyazonosító adatait és - az oktatási azonosító szám kiadásával összefüggésben - oktatási azonosító számát,</li>
                            <li>Elektronikus levelezési címét és</li>
                            <li>Legmagasabb iskolai végzettségére vonatkozó adatát.</li>
                        </ol>
                    </li>
                    <li>A képzéssel összefüggő adatokat, amelyek a képzésben részt vevő személy
                    <ol className="alpha">
                            <li>Legmagasabb iskolai végzettségével, szakképesítésével, szakképzettségével és idegennyelv-ismeretével,</li>
                            <li>A képzésbe történő belépésével és a képzés elvégzésével, illetve a képzés elvégzése hiányában a képzésből történő kilépésével,</li>
                            <li>A képzés során történő értékelésével és minősítésével,</li>
                            <li>A képzéssel összefüggő fizetési kötelezettségeivel és az igénybe vett képzési hitellel
                            kapcsolatosak.</li>
                        </ol>
                    </li>
                </ol>

               <p> A Személyes Adatok kezelésére a fentiek alapján az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény (Info tv.) és az Általános adatvédelmi rendelet 6. cikk (1) bekezdésének a) pontja alapján, az Ön hozzájárulása alapján kerül sor. </p>

               <p> A Személyes Adat jogosultja bármikor megtagadhatja valamely Személyes Adatának átadását, ugyanakkor bizonyos, az azonosításhoz szükséges adatok hiánya a Programban történő részvételt kizárja.</p>

              <p><strong>2. Egyéb képzések esetén</strong></p>

                <p>Egyéb képzések esetén Ön, mint a Programban résztvevő személy és a Szolgáltató között a Programban való részvétel érdekében szerződés jön létre. </p>

                <p>Az I. pont 2. alpontban tételesen meghatározott Személyes Adatok a szerződés létrejöttéhez szükségesek. Ezen adatok hiánya esetén a szerződés nem jön létre. </p>

                <p>Egyéb képzés esetén tehát a Személyes Adatok kezelésére Info tv. és az Általános adatvédelmi rendelet 6. cikk (1) bekezdésének b) pontja alapján, a szerződés megkötése és annak teljesítése érdekében kerül sor.</p>

              <p><strong>3. hírlevél küldése esetén</strong></p>

                <p>Hírlevél küldésére az Ön kifejezett, külön hozzájárulása alapján kerül sor. </p>

                <p>A hírlevél küldése érdekében való Személyes Adatok kezelésére tehát Info tv. és az Általános adatvédelmi rendelet 6. cikk (1) bekezdésének a) pontja, azaz az Ön hozzájárulása alapján kerül sor.</p>

                <p>Társaságunk az adatkezelés során az Általános adatvédelmi rendelet és az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény rendelkezéseinek betartásával, továbbá a Nemzeti Adatvédelmi és Információszabadság Hatóság vonatkozó állásfoglalásának figyelembevételével végzi tevékenységét.</p>


              {this.sectionHtml(++this.section, 'Az adatkezelés célja')}

              <p><strong>1. Egészségszakmai képzés esetén:</strong></p>
              <p>A Szolgáltató a szolgáltatásai nyújtása során beszerzett Személyes Adatokat kizárólag a Programban történő részvétel biztosítása, mint szolgáltatás nyújtása érdekében használja fel, az ahhoz szükséges terjedelemben, valamint a Program teljesítéséhez kapcsolódó, jogszabályon alapuló jelentési és adatszolgáltatási kötelezettségek teljesítése érdekében. </p>

              <p><strong>2. Egyéb képzések esetén:</strong></p>
              <p>A Programban való részvétel érdekében beszerzett Személyes Adatokat a Szolgáltató kizárólag a Programban való részvételhez szükséges mértékben, ideértve a szerződés megkötése és annak teljesítése érdekében kezeli. </p>

              <p><strong>3. Hírlevél küldése esetén:</strong></p>
              <p>A Szolgáltató a hírlevél küldéséhez adott külön, kifejezett hozzájárulás alapján a Személyes Adatokat a hírlevelek küldéséhez szükséges mértékben kezeli. </p>


              {this.sectionHtml(++this.section, 'Az adatkezelés időtartama')}

              <p><strong>1. Egészségszakmai továbbképzés esetén:</strong></p>

              <p>A Rendelet 14. § (5) bekezdése alapján a pontérték alapjául szolgáló dokumentumokat a pontérték megállapításától számított 10 évig köteles a Szolgáltató megőrizni.</p>
              <p>A felnőttképzési törvény 21.§ (5) bekezdése alapján a Szolgáltató az adatokat a felnőttképzési szerződés megkötésétől számított nyolcadik év utolsó napjáig kezeli.</p>

              <p><strong>2. Egyéb képzés esetén:</strong></p>

              <p>Szolgáltató az adatkezelés célhoz kötöttségére tekintettel a Személyes Adatait a szerződés teljesítéséig, ezt követően pedig mivel a szerződés a számvitelről szóló 2000. évi C. törvény 169. § alapján számviteli bizonylatnak minősül, így a hivatkozott jogszabályhely alapján a Szolgáltatónak a szerződést (ekként az Ön által megadott személyes adatokat), a szerződés teljesítését követő 8 évig kell őriznie. </p>

              <p><strong>3. Hírlevél küldése esetén:</strong></p>

              <p>A hírlevél küldése érdekében tárolt Személyes Adatokat a Szolgáltató a hozzájárulás visszavonásáig, illetve az érintett törlésre irányuló kérelme beérkezéséig tárolja.</p>

              <p>Ön a hírlevélben található „leiratkozom” gombra kattintva is bármikor leiratkozhat a hírlevelekről, amely esetben a továbbiakban Önnek nem küldünk hírlevelet</p>


              {this.sectionHtml(++this.section, 'Adatfeldolgozás, adattovábbítás')}

              <p>A Személyes adatok tárolására a Szolgáltató a Neting Kft. (1135 Budapest, Róbert Károly krt. 96/100.), mint adatfeldolgozó szolgáltatását veszi igénybe.  </p>

              <p><strong>1. Egészségszakmai továbbképzés esetén:</strong></p>

              <p>A Szolgáltató a Rendelet 13. § bekezdése alapján elektronikus adatszolgáltatás útján köteles az AEEK mint az egészségügyi továbbképzési programok nyilvántartója részére megküldeni az alábbi Személyes Adatokat: név, születési név, születési hely, születési idő, anyja neve, email címe, működési nyilvántartási szám/alap nyilvántartási szám, szakképesítés, szakmacsoport, valamint a Program elvégzésének helye, ideje, megnevezése, nyilvántartási száma és pontértéke adatokat</p>
              <p>A felnőttképzési törvény 15.§ (1) bekezdése alapján a Szolgáltató az oktatás, képzés megnevezésére, jellegére, helyére, óraszámára, első képzési napjára és a képzésben részt vevő személyek természetes személyazonosító adataira, elektronikus levelezési címére, valamint legmagasabb iskolai végzettségére, továbbá a képzési díjra és annak költségviselőjére vonatkozó adatot szolgáltat a felnőttképzési államigazgatási szerv (Pest Megyei Kormányhivatal Szakképzési és Felnőttképzési Főosztály ) részére a felnőttképzés adatszolgáltatási rendszerében.</p>
              <p>Ha a résztvevő nem rendelkezik oktatási azonosító számmal, akkor az Oktatási Hivatal állapítja meg.</p>


              {this.sectionHtml(++this.section, 'Adatok tárolása')}

              <p>A Személyes Adatokat a Szolgáltató az Invitel Datacenter, 1143 Budapest, Ilka utca 31. b. szám alatt található szerverén tárolja, amelyet az Online Felület tárhelyét szolgáltató Neting Kft. biztosít. A Neting Kft. a Személyes Adatokhoz nem fér hozzá.</p>

              <p>Az adatkezelés az adatbiztonsági követelményeknek megfelel, a Szolgáltató az érintett magánszférájának védelmét szem előtt tartva alakította ki és hajtja végre a belső adatvédelmi szabályzatában meghatározott műszaki és szervezési intézkedéseknek megfelelően.</p>


              {this.sectionHtml(++this.section, 'Az érintettek jogai')}

              <p>Személyes adatok kezelése esetén Önt – az adatkezeléshez adott hozzájárulás visszavonásán kívül, ha az adatkezelés jogalapja az Ön hozzájárulása – számos jog megilleti, és azokat bármikor gyakorolhatja a Szolgáltató székhelyére, vagy <Mailto to="kepzes@kepzeskozpont.com" secure={true} >kepzes@kepzeskozpont.com</Mailto> email címre küldött kérelem útján.</p>

              <p><strong>A személyes adatokhoz való hozzáférés és azok helyesbítésének joga</strong></p>

              <p>Adataihoz bármikor jogosult hozzáférni, másolatot kérni, továbbá azokat helyesbíteni vagy frissíteni.</p>

             <p>A hozzáférési jog alapján Ön jogosult arra, hogy az alábbiakról információt kapjon:</p>

                  <ol className="alpha">
                    <li>Az adatkezelés céljai;</li>
                    <li>Az érintett személyes adatok kategóriái;</li>
                    <li>Azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket;</li>
                    <li>Adott esetben a személyes adatok tárolásának tervezett időtartama;</li>
                    <li>Az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen; </li>
                    <li>A valamely felügyeleti hatósághoz címzett panasz benyújtásának joga; és</li>
                    <li>Az automatizált döntéshozatal ténye (amely jelen esetben a szakképesítés megadása esetén a szakképesítésének megfelelő hírlevél küldését).</li>
                  </ol>
          
              <p><strong>Adathordozhatósághoz való jog</strong></p>

              <p>Személyes adatai hordozhatóak. Ez azt jelenti, hogy azok elektronikusan mozgathatók, másolhatók, vagy továbbíthatók.</p>

              <p><strong>A	személyes	adatok	törlésének	joga</strong></p>

                <p>Ön jogosult adatai törlését kérni, amennyiben</p>

                  <ol className="alpha">
                    <li>Személyes adataira már nincs szükség azon cél(ok)hoz, amely(ek)re tekintettel azokat begyűjtöttük; vagy</li>
                    <li>Visszavonja a személyes adatai kezeléséhez adott korábbi hozzájárulását, és nincs egyéb jogalapja az adatkezelésnek; vagy</li>
                    <li>Tiltakozik személyes adatai kezelése ellen;</li>
                    <li>A személyes adatok kezelése nem jogszerűen történik; vagy</li>
                    <li>Személyes adatainak törlését a jogszabályoknak való megfelelés indokolja.</li>
                  </ol>

                <p>A Szolgáltató nem köteles azonban teljesíteni az Ön kérelmet, amennyiben az adatkezelés szükséges:</p>

                  <ol className="alpha">
                    <li>Véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából;</li>
                    <li>A személyes adatok kezelését előíró, az adatkezelőre alkalmazandó uniós vagy tagállami jog szerinti kötelezettség teljesítése, illetve közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat végrehajtása céljából;</li>
                    <li>A népegészségügy területét érintő közérdek alapján;</li>
                    <li>A közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy statisztikai célból, amennyiben az adatok törlése valószínűsíthetően lehetetlenné tenné vagy komolyan veszélyeztetné ezt az adatkezelést; vagy</li>
                    <li>Jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez</li>
                  </ol>

                 <p>A fentiekben írtak figyelembevételével amennyiben élni kíván ezen jogával, vegye fel velünk a kapcsolatot a fenti elérhetőségek valamelyikén keresztül.</p>
              
              <p><strong>Az	adatkezelés	korlátozásának	joga</strong></p>

                <p>Ön abban az esetben korlátozhatja személyes adatainak kezelését, amennyiben</p>
              
                  <ol className="alpha">
                    <li>Úgy véli, hogy az Önnel kapcsolatban tárolt személyes adatok nem pontosak; vagy</li>
                    <li>A személyes adatok kezelése nem jogszerűen történik, de ahelyett, hogy a törlésüket kérné, inkább korlátozni szeretné a kezelésüket; vagy</li>
                    <li>Személyes adataira nincs többé szükségünk azon cél(ok)hoz, amely(ek) miatt eredetileg begyűjtöttük azokat, Ön azonban igény tart ezen adatokra valamely peres követelés benyújtása, érvényesítése vagy a követeléssel szembeni védekezés céljából vagy </li>
                    <li>Ön tiltakozott személyes adatai kezelése ellen és várja az arra vonatkozó visszaigazolást, hogy az Ön tiltakozással kapcsolatos érdekei felülírják-e az adatkezelés jogalapját.</li>              
                  </ol>

              <p><strong>A tiltakozás joga</strong></p>

                <p>Ön személyes adatainak kezelése ellen bármikor tiltakozhat.</p>

              <p><strong>Automatizált döntéshozatal</strong></p>

                <p>Mivel az Ön által megadott szakképesítés alapján meghatározzuk, hogy Ön milyen témájú hírlevelet kíván kapni, és Ön ilyen témájú hírlevelet is fog kapni, így Ön jogosult arra, hogy ha Ön nem ért egyet a kapott hírlevél témájával, ezt elmondja nekünk, álláspontját kifejtse, illetve hogy Ön felvegye velünk a kapcsolatot annak érdekében, hogy egy munkatársunk nézze át, hogy megfelelő hírlevél kerül illetve került Önnek megküldésre. </p>

                <p>Ha ezen jogát gyakorolni kívánja, kérjük vegye fel velünk a kapcsolatot a fenti elérhetőségek valamelyikén keresztül.</p>

              <p><strong>Felügyeleti hatósághoz fordulás joga</strong></p>

                <p>Ön bármikor jogosult a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (cím: 1055 Budapest, Falk Miksa utca 9-11.; postacím: 1363 Budapest, Pf.: 9.; telefonszám: +36 (1) 391-1400; email cím: ugyfelszolgalat@naih.hu), mint felügyeleti hatósághoz. </p>

                <p>Kelt: Budapest,2021. június </p>

          </div>

        </div>

      </section>

    </div>
  }

  private sectionHtml (section: number, text: string) {
    this.subSection = 0

    return <h3>{section + '. ' + text}</h3>
  }

  private subSectionHtml (section: number, subSection: number, text: string) {
    return <h5>{section + '.' + subSection + '. ' + text}</h5>
  }

}
