import * as React from 'react'
import { animateScroll as scroll } from 'react-scroll'

export class ScrollToTopOnMount extends React.Component {
  componentDidMount () {
    scroll.scrollToTop({
      duration: 200
    })
  }

  render () {
    return null
  }
}
