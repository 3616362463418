import * as React from 'react'

export interface ErrorDisplay {
  (input: string): React.ReactNode | string | null
}

export function compose (...viewers: ErrorDisplay[]) {
  return (input: string) => {

    for (let viewer of viewers) {
      let result = viewer(input)
      if (!result) {
        continue
      }

      return result
    }

    return input
  }
}
