import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as ConfigUI from 'config/ui'
import * as ConfigApi from 'config/api'
import * as ContactApi from 'contact/api'
import * as Form from 'ui/form'
import * as ErrorHandler from 'error-handler/ui'
import * as Api from '../../api'
import { SendEmailForm } from './send-email-form'
import { toast } from 'ui/toast'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { ScrollToTopOnMount } from 'ui/scroll'

let Mailto: any = require('react-mailto.js').default
import { ShowSimpleGoogleMap } from './show-map'
import gql from 'graphql-tag'

const defaultData = () => ({
  address: '',
  phone: '',
  email: '',
  mapUrl: '',
  captchaSiteKey: ''
})

interface SendContactMessageVariables {
  input: Api.SendContactMessageCommand
}

interface SendContactMessageResponse {
  sendContactMessage: Api.SendContactMessageCommandResult
}

type InputProps = {}
type Props = ReactApollo.ChildMutateProps<InputProps, SendContactMessageResponse, SendContactMessageVariables>

class Contact_ extends React.Component<Props> {

  render () {
    let keys: ContactApi.ConfigKeys[] = [
      'address', 'phone', 'email', 'mapUrl', 'captchaSiteKey'
    ]
    let variables: ConfigApi.GetConfigQuery = {
      domains: [{
        domain: 'contact',
        keys: keys
      }]
    }

    return <div>
      <ScrollToTopOnMount />
      <ConfigUI.GetConfigQuery query={ConfigUI.GET_CONFIG_QUERY} variables={variables}>
        {
          ({ loading, error, data }) => {

            let contact = defaultData()

            if (!loading && data) {
              contact = ConfigUI.mapConfigResult(data.getConfig, 'contact', keys)
            }

            if (error) {
              throw error
            }

            return this.contact(contact)
          }
        }
      </ConfigUI.GetConfigQuery>
    </div>
  }

  private contact (config: ContactApi.PublicConfigModel) {

    let availabilityData = [
      {
        icon: 'home',
        text: config.address
      },
      {
        icon: 'phone',
        text: config.phone
      },
      {
        icon: 'envelope',
        text: <Mailto to = { config.email } secure = {true}>{ config.email }</Mailto>
      }
    ]

    return <div>

      <Breadcrumb end="Kapcsolat" />

      <PageTitle title="Kapcsolatfelvétel" />

      <section className="section">
        <div className="contact-page">
          <div className="columns is-centered">

            <div className="column is-two-thirds">

              {/* <div className="title has-text-centered">Kapcsolatfelvétel</div> */}

              <div className="columns is-centered is-multiline">
                {/* <div className="column is-four-fifths"> */}
                <div className="column">
                  {this.form(config)}
                </div>
                </div>

              </div>

              <div className="column is-centered is-narrow">
                {/* <div className="title has-text-centered">Elérhetőség</div> */}

                <section className="section">
                  <div>
                    {
                      availabilityData.map((item, index) => {
                        return this.availabilityControl(item, index + this.contact.length + 1)
                      })
                    }
                  </div>

                </section>

                <ShowSimpleGoogleMap height={'54%'} mapUrl={config.mapUrl}/>

              </div>

            </div>
          </div>
      </section>

    </div>
  }

  private form (config: ContactApi.PublicConfigModel) {
    return <Form.Form onSubmit={this.handleSubmit.bind(this)} onSubmitFailure={this.handleSubmitFailure.bind(this)}>
      {
        formApi => {
          return <>
            <SendEmailForm formApi={formApi} captchaSiteKey={config.captchaSiteKey} />
          </>
        }
      }
    </Form.Form>
  }

  private async handleSubmitFailure () {
    toast.warn('Kérem, ellenőrizze az űrlapon megadott adatokat!')
  }

  private async handleSubmit (model: any, e: any, formApi: Form.FormApi<any>) {
    try {
      let result = await this.props.mutate({
        variables: {
          input: {
            ...model
          }
        }
      })

      let response = result.data.sendContactMessage

      switch (response.code) {
        case Api.SendContactMessageResultCode.success:

          setTimeout(() => {
          // reset the captcha
            (window as any).grecaptcha.reset()
            formApi.reset('captcha')
          }, 2000)

          toast.success('Sikeres küldés')
          break
        case Api.SendContactMessageResultCode.validationError:
          toast.warn('Kérem, ellenőrize az űrlapon megadott értékeket!')
          Form.showValidationErrors(JSON.parse(response.validation!), formApi)
          break
      }
    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }

  private availabilityControl (item: any, key: number) {

    if (!item) {
      return ''
    }

    let classes = 'fas fa-' + item.icon

    return (
      <div className="field level-left" key={key}>
        <div className="level-item">
          <span className="icon is-left">
            <i className={classes}></i>
          </span>
        </div>
        <div className="level-item">
          {item.text}
        </div>
      </div>
    )
  }
}

const SEND_CONTACT_MESSAGE_MUTATION =
  gql`mutation sendContactMessage($input: SendContactMessageInput!) {
    sendContactMessage(input: $input) {
      code, validation
    }
  }
  `

const WithMutation = ReactApollo.graphql<InputProps,
      SendContactMessageResponse,
      SendContactMessageVariables>(SEND_CONTACT_MESSAGE_MUTATION)
export const Contact = WithMutation((Contact_))
