import gql from 'graphql-tag'
import * as ReactApollo from 'react-apollo'

export const CHECK_RIGHT_QUERY = gql`query checkRight($action: String!, $subject: String) {
  checkRight(action: $action, subject: $subject)
}`

interface CheckRightVariables {
  action: string
  subject?: string
}

export type CheckRightResponse = boolean

export class CheckRightQuery extends ReactApollo.Query<CheckRightResponse, CheckRightVariables> {}
