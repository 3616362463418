import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'

export interface BirthModel {
  birthPlace: string
  birthDate: string
}

interface Props {
  isRequired: boolean
}

class BirthFields_ extends React.Component<Form.NestedComponentProps<BirthModel> & Props> {
  render () {

    let validator = this.props.isRequired ?
                    Validation.requiredValidator : Validation.onlyServerValidator
    let validatorDisplay = Validation.requiredErrorDisplay

    return <>
      <Form.InputField
        id="birthPlace"
        field="birthPlace"
        label="Születési hely:"
        type="text"
        maxLength={100}
        leftIcon={Icon.fontAwesome('birthday-cake')}
        horizontal={true}
        placeholder={this.props.isRequired ? 'Születési hely' : 'Születési hely (opcionális)'}
        validate={validator}
        validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay,
                                                   Validation.stringErrorDisplay)}
      />
      <Form.DatePickerField
        field="birthDate"
        label="Születési idő:"
        placeholder={this.props.isRequired ? 'Születési idő' : 'Születési idő (opcionális)'}
        horizontal={true}
        validate={validator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
    </>
  }
}

export const BirthFields =
  Form.withNestedField<Props, BirthModel>(BirthFields_)
