import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'

interface Props {
  field: string
}

export const RegistrationNumberField = (props: Props) => {

  return <Form.InputField
    field={props.field}
    label="Működési nyilvántartási szám:"
    help="Ennek hiányában alapnyilvántartási szám"
    type="text"
    maxLength={50}
    leftIcon={Icon.fontAwesome('user')}
    horizontal={true}
    placeholder="Működési nyilvántartási szám"
    validate={Validation.requiredValidator}
    validationErrorDisplay={Validation.compose(Validation.requiredErrorDisplay,
                                               Validation.stringErrorDisplay)}
  />
}
