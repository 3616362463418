import gql from 'graphql-tag'
import * as Api from '../../api'

export const QUALIFICATIONS_QUERY =
      gql`
query qualifications($professionGroupId: String!) {
  qualifications(professionGroupId: $professionGroupId) {
    id, label
  }
}`

export type QualificationsResponse = {
  qualifications: Api.QualificationsQueryResult
}

export type QualificationsVariables = {
  professionGroupId: string
}
