import { ValidationError } from './validation-error'
import { InputResolver, InputResolverFunc,
         AsyncInputResolver, AsyncInputResolverFunc } from './input-resolver'

type ResultMap<T> = {
  [K in keyof T]?: T[K]
}

export class ValidationCollector<TResult, TValidationResult extends Object> {
  private result: ResultMap<TResult> = {}
  private validation: ValidationError<TValidationResult>

  constructor (def: TValidationResult) {
    this.validation = new ValidationError<TValidationResult>(def)
  }

  async processAsync (key: keyof TResult,
                      errorKey: keyof TValidationResult,
                      validator: () => Promise<TResult[keyof TResult] | ValidationError<TValidationResult[keyof TValidationResult]>>) {

    let result = await validator()
    this.processResult(key, errorKey, result)
  }

  process2<TInput> (key: keyof TResult,
                    errorKey: keyof TValidationResult,
                    input: TInput,
                    validator: InputResolver<TInput, TResult[keyof TResult], TValidationResult[keyof TValidationResult]>) {

    let result = validator.resolve(input)
    this.processResult(key, errorKey, result)
  }

  process2Func<TInput> (key: keyof TResult,
                    errorKey: keyof TValidationResult,
                    input: TInput,
                    validator: InputResolverFunc<TInput, TResult[keyof TResult], TValidationResult[keyof TValidationResult]>) {

    let result = validator(input)
    this.processResult(key, errorKey, result)
  }

  async process2FuncAsync<TInput> (key: keyof TResult,
                    errorKey: keyof TValidationResult,
                    input: TInput,
                    validator: AsyncInputResolverFunc<TInput, TResult[keyof TResult], TValidationResult[keyof TValidationResult]>) {

    let result = await validator(input)
    this.processResult(key, errorKey, result)
  }

  async process2Async<TInput> (key: keyof TResult,
                         errorKey: keyof TValidationResult,
                         input: TInput,
                         validator: AsyncInputResolver<TInput, TResult[keyof TResult], TValidationResult[keyof TValidationResult]>) {

    let result = await validator.resolve(input)
    this.processResult(key, errorKey, result)
  }

  process (key: keyof TResult,
           errorKey: keyof TValidationResult,
           validator: () => TResult[keyof TResult] | ValidationError<TValidationResult[keyof TValidationResult]>) {

    let result = validator()
    this.processResult(key, errorKey, result)
  }

  setResult (key: keyof TResult, result: TResult[keyof TResult]) {
    this.result[key] = result
  }

  setError (errorKey: keyof TValidationResult,
            error: TValidationResult[keyof TValidationResult]) {
    this.validation.setMember(errorKey, error)
  }

  get hasError () {
    return this.validation.hasError
  }

  get error () {
    return this.validation.error
  }

  mapResult (mapper: (result: ResultMap<TResult>) => TResult): TResult | ValidationError<TValidationResult> {

    if (this.validation.hasError) {
      return this.validation
    }

    return mapper(this.result)
  }

  get theResult () {
    return this.result
  }

  processResult (key: keyof TResult,
                         errorKey: keyof TValidationResult,
                         result: TResult[keyof TResult] | ValidationError<TValidationResult[keyof TValidationResult]>) {

    if (result instanceof ValidationError) {
      this.setError(errorKey, result.error)
    } else {
      this.setResult(key, result)
    }
  }
}
