import gql from 'graphql-tag'
import * as HealthPersonUI from 'health-person/ui'

export const profileFragment = gql`
  fragment Profile on Profile {
      name { firstName, lastName, birthName }
      birth { birthPlace, birthDate }
      motherName
      profession
      acceptStoringData
      isHealthPerson
      health {
        registrationNumber
        education { educationLevelId, educationId }
        qualifications { professionGroupId, qualificationId }
        institution {
          ...Institution
        }
        notificationAddress {
          ...NotificationAddress
        }
      }
  }
  ${HealthPersonUI.institutionFragment}
  ${HealthPersonUI.notificationAddressFragment}
`
