// a) az 1. számjegy konstans 7-es szám,
// b) a 2-10. számjegyek összessége egy garantáltan egyedi, véletlenszerűen generált szám,
// c) a 11. számjegy az 1-10. számjegyek felhasználásával, matematikai módszerekkel képzett ellenőrző
// szám.
// 3. Az azonosító szám 11. számjegyét úgy kell képezni, hogy a 2. a)-b) pontok szerint képzett 10 számjegy
// mindegyikét szorozni kell azzal a sorszámmal, ahányadik helyet foglalja el az azonosító számon belül. (Első
// számjegy szorozva eggyel, második számjegy szorozva kettővel és így tovább.)
// Az így kapott szorzatok összegét el kell osztani 11-gyel, és az osztás maradéka a 11. számjeggyel lesz
// egyenlő.
// A 2. b) pont szerinti sorszám nem adható ki, ha a 11-gyel való osztás maradéka egyenlő tízzel
export function validateOMCode(code: string) {

	if (!code) {
		return false;
	}
	
	if (code.length != 11) {
		return false;
	}

	if (code[0] != '7') {
		return false;
	}

	let sum = 0;
	for (let i = 0; i < 10; i++) {

		let digit = parseInt(code[i]);
		if (isNaN(digit)) {
			return false;
		}

		sum += (i+1) * digit;
	}

	let checksum = sum % 11;
	if (parseInt(code[10]) != checksum) {
		// checksum mismatch
		return false;
	}

	return true;
}
