import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as OperationAreaQuery from './operation-areas.query'
import { SurgeryField } from './surgery-field'
import * as Validation from 'validation/ui'

export interface OperationAreaModel {
  operationAreaId: string
  otherOperationArea?: string
  surgeryId?: string
}

type PublicProps = {}
type InputProps = Form.NestedComponentProps<OperationAreaModel>
type Props = ReactApollo.ChildDataProps<InputProps, OperationAreaQuery.OperationAreasResponse>

class OperationAreaFields_ extends React.Component<Props> {

  render () {

    let key = this.selectedKey(this.props)

    return (
      <>
      <Form.SelectField
        field="operationAreaId"
        label="Működési terület:"
        options={this.operationAreaOptions()}
        horizontal={true}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
      {this.surgeryField(key)}
      {this.otherField(key)}
      </>
    )
  }

  componentDidUpdate (prevProps: Props) {

    let newKey = this.selectedKey(this.props)
    if (!newKey || !this.isOther(newKey)) {
      this.resetField('otherOperationArea')
    }
    if (!newKey || !this.isSurgery(newKey)) {
      this.resetField('surgeryId')
    }
  }

  private resetField (field: keyof OperationAreaModel) {
    let fullField = this.props.fieldApi.getFullField(field)

    let value = this.props.fieldApi.getValue(fullField)
    if (value !== undefined) {
      this.props.fieldApi.reset(fullField)
      this.props.fieldApi.setError(fullField, undefined)
    }
  }

  private surgeryField (key: string | null) {
    if (key === null) { return }
    if (!this.isSurgery(key)) {
      return null
    }

    return <SurgeryField />
  }

  private otherField (key: string | null) {
    if (key === null) { return }
    if (!this.isOther(key)) {
      return null
    }

    return (
      <Form.InputField
        field="otherOperationArea"
        label="Egyéb"
        type="text"
        maxLength={200}
        horizontal
        leftIcon={Icon.fontAwesome('medkit')}
        validate={Validation.requiredValidator}
        validationErrorDisplay={Validation.requiredErrorDisplay}
      />
    )
  }

  private isSurgery (key: string) {
    return key === 'surgery'
  }

  private isOther (key: string) {
    return key === 'other'
  }

  private selectedKey (props: Props): string | null {
    let areas = props.data.operationAreas
    let selectedId = this.selectedId(props)

    if (!areas || !selectedId) {
      return null
    }

    let found = areas.find(x => x.id === selectedId)
    return found ? found.key : null
  }

  private selectedId (props: Props): string | undefined {
    return props.fieldApi.getValue(
      props.fieldApi.getFullField('operationAreaId'))
  }

  private operationAreaOptions () {
    if (!this.props.data.operationAreas) {
      return []
    }

    return this.props.data.operationAreas
               .map(x => ({ value: x.id, label: x.label }))
  }
}

const WithData =
  ReactApollo.graphql<InputProps, OperationAreaQuery.OperationAreasResponse>(OperationAreaQuery.OPERATION_AREAS_QUERY)

export const OperationAreaFields = Form.withNestedField<PublicProps, OperationAreaModel>(WithData(OperationAreaFields_))
