import { EnrollmentCanCloseState } from './enrollment-view'

export interface CloseMyCourseCommand {
  enrollmentId: string
}

export enum CloseMyCourseCommandResultCode {
  success,
  failed
}

export interface CloseMyCourseCommandResult {
  code: CloseMyCourseCommandResultCode
  reason: EnrollmentCanCloseState
}
