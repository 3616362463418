
export interface Validator {
  (input?: string): string | null
}

export function composeValidator (...validators: Validator[]) {
  return (input: string) => {

    for (let validator of validators) {
      let result = validator(input)
      if (!result) {
        continue
      }

      return result
    }

    return null
  }
}
