
export function resolveParams () {
  let params = {
    release: process.env.CI_COMMIT_SHA,
    environment: process.env.NODE_ENV,
    tags: {
      release_date: process.env.BUILD_DATE || '',
      git_tag: process.env.CI_COMMIT_TAG || '',
      git_commit: process.env.CI_COMMIT_SHA || '',
      git_branch: process.env.CI_COMMIT_REF_NAME || ''
    }
  }

  return params
}
