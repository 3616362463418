import gql from 'graphql-tag'
import * as Api from '../api'

export const RESOLVE_CITY_QUERY = gql`
query resolveCity($filter: ResolveCityFilter!) {
  resolveCity(filter: $filter) {
    id, name, districtName, subcountyName, countyName, regionName
  }
}`

export interface ResolveCityVariables {
  filter: {
    postalCode?: string
    countryId?: string
  }
}

export type ResolveCityResponse = {
  resolveCity: Api.ResolveCityQueryResult
}
