import * as React from 'react'
import * as ReactForm from 'react-form'
import * as classNames from 'classnames'
import * as FieldDecorator from '../field-decorator'

interface CheckboxFieldProps {
}

function labelClasses(api: ReactForm.FieldApi<any>) {
  let classes = classNames('checkbox', {
    'has-text-success': !!api.success,
    'has-text-warning': !!api.warning,
    'has-text-danger': !!api.error
  })

  return classes
}

class CheckboxFieldControl extends
React.Component<ReactForm.ComponentProps<boolean> & CheckboxFieldProps> {

  render () {

    let api = this.props.fieldApi

    let classes = labelClasses(api)

    return (
      <label className={classes}>
        <input
          type="checkbox"
          checked={!!api.value}
          onChange={e => {
            api.setValue(e.target.checked)
          }}
          onBlur={e => {
            api.setTouched()
          }}
        />
        {this.props.children}
      </label>
    )
  }
}

export const CheckboxField = ReactForm
  .withField<CheckboxFieldProps & FieldDecorator.FieldLayout, boolean>(
    FieldDecorator.withFieldDecorator(CheckboxFieldControl))

export const BareCheckboxField = ReactForm
  .withField<CheckboxFieldProps, boolean>(CheckboxFieldControl)


class ColumnCheckboxFieldControl extends React.Component<ReactForm.ComponentProps<boolean> & CheckboxFieldProps> {

  render() {

    let api = this.props.fieldApi

    let classes = 'checkbox'
    /* let classes = labelClasses(api) */

    return <>
      <label className={classes}>
        <div className="columns is-1 is-variable">
          <div className="column is-narrow">
            <CheckboxFieldControl fieldApi={this.props.fieldApi} />
          </div>
          <div className="column">
            {this.props.children}
          </div>
        </div>
      </label>
    </>
  }
}

export const ColumnCheckboxField = ReactForm
  .withField<CheckboxFieldProps & FieldDecorator.FieldLayout, boolean>(
    FieldDecorator.withFieldDecorator(ColumnCheckboxFieldControl))
