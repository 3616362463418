import { InputResolver, AsyncInputResolver, InputResolver2 } from './input-resolver'
import { ValidationError } from './validation-error'

type Resolver<TI, TO, TV> = InputResolver<TI, TO, TV> | AsyncInputResolver<TI, TO, TV>

// TODO: implement
export class ComposeInputResolver<TLI, TLO, TLV, TRO, TRV> implements InputResolver2<TLI, TRO, TLV | TRV> {

  constructor (private left: Resolver<TLI, TLO, TLV>,
               private right: Resolver<TLO, TRO, TRV>) {
  }

  resolve (input: TLI) {

    let leftResult2 = this.left.resolve(input)
    if (leftResult2 instanceof Promise) {
      let promiseResult = this.asyncResult(leftResult2)
      return promiseResult
    }

    if (leftResult2 instanceof ValidationError) {
      return leftResult2
    }

    let rightResult = this.right.resolve(leftResult2)
    return rightResult
  }

  private async asyncResult (leftPromise: Promise<TLO | ValidationError<TLV>>): Promise<TRO | ValidationError<TLV | TRV>> {
    let leftResult = await leftPromise

    if (leftResult instanceof ValidationError) {
      return leftResult
    }

    let rightResult = this.right.resolve(leftResult)

    return rightResult
  }
}

export function compose3<TLI, TLO, TLV, TRO, TRV> (left: InputResolver<TLI, TLO, TLV>, right: InputResolver<TLO, TRO, TRV>): InputResolver<TLI, TLO, TLV | TRV>
export function compose3<TLI, TLO, TLV, TRO, TRV> (left: AsyncInputResolver<TLI, TLO, TLV>, right: InputResolver<TLO, TRO, TRV>): AsyncInputResolver<TLI, TLO, TLV | TRV>
export function compose3<TLI, TLO, TLV, TRO, TRV> (left: InputResolver<TLI, TLO, TLV>, right: AsyncInputResolver<TLO, TRO, TRV>): AsyncInputResolver<TLI, TLO, TLV | TRV>
export function compose3<TLI, TLO, TLV, TRO, TRV> (left: AsyncInputResolver<TLI, TLO, TLV>, right: AsyncInputResolver<TLO, TRO, TRV>): AsyncInputResolver<TLI, TLO, TLV | TRV> {
  throw new Error('NOT IMPL')
}

// export function compose2<TLI, TLO, TLV, TRO, TRV> (

//   left: InputResolver2<TLI, TLO, TLV>,
//   right: InputResolver2<TLO, TRO, TRV>) {

//   return new ComposeInputResolver(left, right)
// }

class ComposeResolver<TLI, TLO, TLV, TRO, TRV> implements InputResolver<TLI, TRO, TLV | TRV> {

  constructor (private left: InputResolver<TLI, TLO, TLV>,
               private right: InputResolver<TLO, TRO, TRV>) {
  }

  resolve (input: TLI): TRO | ValidationError<TLV | TRV> {
    let leftResult = this.left.resolve(input)
    if (leftResult instanceof ValidationError) {
      return leftResult
    }

    let rightResult = this.right.resolve(leftResult)
    return rightResult
  }
}

export function compose<TLI, TLO, TLV, TRO, TRV> (

  left: InputResolver<TLI, TLO, TLV>,
  right: InputResolver<TLO, TRO, TRV>): InputResolver<TLI, TRO, TLV | TRV> {

  return new ComposeResolver(left, right)
}
