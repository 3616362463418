import { UserProfileInput } from './user-profile-input'

export enum UpdateUserProfileResultCode {
  success,
  validationError
}

export type UpdateUserProfileCommand = UserProfileInput

export interface UpdateUserProfileCommandResult {
  code: UpdateUserProfileResultCode,
  validation?: string
}
