export enum DeleteCourseResultCode {
  success
}

export interface DeleteCourseCommand {
  id: string
}

export interface DeleteCourseCommandResult {
  code: DeleteCourseResultCode
}
