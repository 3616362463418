import gql from 'graphql-tag'
import * as React from 'react'
import * as ReactApollo from 'react-apollo'
import * as Router from 'react-router'
import * as Form from 'ui/form'
import * as CourseCode from './course-code-fields'
import * as ErrorHandler from 'error-handler/ui'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
import { toast } from 'ui/toast'
import * as Api from '../../api'
import { ScrollToTopOnMount } from 'ui/scroll'

interface Model {
  courseCode: string
}

const ENROLL_USING_CODE_MUTATION =
  gql`mutation enrollUsingCode($courseCode: String!) {
    enrollUsingCode(courseCode: $courseCode) {
      code, validation
    }
  }
  `

type EnrollUsingCodeResponse = {
  enrollUsingCode: Api.EnrollUsingCodeCommandResult
}
type EnrollUsingCodeVariables = Api.EnrollUsingCodeCommand

type InputProps = Router.RouteComponentProps<{}>
type Props = ReactApollo.ChildMutateProps<InputProps, EnrollUsingCodeResponse, EnrollUsingCodeVariables>

export class EnrollUsingCode_ extends React.Component<Props> {

  render () {
    return <>

      <ScrollToTopOnMount />
      <Breadcrumb end="Kurzusregisztráció" />

      <PageTitle title="Kurzusregisztráció" />

      <section className="section profile-data">
        <p className="label">Kérem, adja meg a kapott kódot a kurzusra való regisztrációhoz.</p>
        <div className="box">
          {this.form()}
        </div>
      </section>

    </>
  }

  private form () {
    return <>
      <Form.Form onSubmit={this.handleSubmit.bind(this)}>
        {formApi => (
          <form onSubmit={formApi.submitForm}>
            <CourseCode.CourseCodeFields
              field="courseCode"
              formApi={formApi as Form.FormApi<string>}
            />
            <Form.SubmitCancelButtons
              formApi={formApi}
              cancelDefaultPath="/kurzusaim">Regisztráció</Form.SubmitCancelButtons>
          </form>
        )}
      </Form.Form>
    </>
  }

  private async handleSubmit (model: Model, e: any, formApi: Form.FormApi<Model>) {
    try {
      let result = await this.props.mutate({
        variables: {
          courseCode: model.courseCode
        }
      })

      let response = result.data.enrollUsingCode

      switch (response.code) {
        case Api.EnrollUsingCodeCommandResultCode.succeeded:
          toast.success('Sikeres kurzusregisztráció')
          let self = this
          setTimeout(() => {
            self.props.history.push('/kurzusaim')
          }, 2000)
          break
        case Api.EnrollUsingCodeCommandResultCode.validationError:
          toast.warn('Kérem, ellenőrize az űrlapon megadott értékeket!')
          Form.showValidationErrors(JSON.parse(response.validation!), formApi)
          break
      }
    } catch (err) {
      ErrorHandler.handleMutationError(err)
    }
  }
}

const WithMutation = ReactApollo.graphql<InputProps, EnrollUsingCodeResponse, EnrollUsingCodeVariables>(ENROLL_USING_CODE_MUTATION)(EnrollUsingCode_)
export const EnrollUsingCode = Router.withRouter(WithMutation)
