import { ValidationError } from './validation-error'
import { InputResolver, AsyncInputResolver } from './input-resolver'

type AsyncDelegateType<TInput, TOutput, TValidationResult> = (input: TInput) => Promise<TOutput | ValidationError<TValidationResult>>
type DelegateType<TInput, TOutput, TValidationResult> = (input: TInput) => TOutput | ValidationError<TValidationResult>

export class AsyncDelegateInputResolver<TInput, TOutput, TValidationResult>
      implements AsyncInputResolver<TInput, TOutput, TValidationResult> {

  constructor (
    private delegate: AsyncDelegateType<TInput, TOutput, TValidationResult>
  ) {}

  resolve (input: TInput) {
    return this.delegate(input)
  }
}

export class DelegateInputResolver<TInput, TOutput, TValidationResult>
      implements InputResolver<TInput, TOutput, TValidationResult> {

  constructor (
    private delegate: DelegateType<TInput, TOutput, TValidationResult>
  ) {}

  resolve (input: TInput) {
    return this.delegate(input)
  }
}

export function asyncDelegate<TInput, TOutput, TValidationResult> (
  delegate: AsyncDelegateType<TInput, TOutput, TValidationResult>): AsyncInputResolver<TInput, TOutput, TValidationResult> {

  return new AsyncDelegateInputResolver(delegate)
}

export function delegate<TInput, TOutput, TValidationResult> (
  delegate: DelegateType<TInput, TOutput, TValidationResult>): InputResolver<TInput, TOutput, TValidationResult> {

  return new DelegateInputResolver(delegate)
}
