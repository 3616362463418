import * as React from 'react'
import * as FieldDecorator from '../field-decorator'
import Select from 'react-select'
import * as ReactSelect from 'react-select'
import * as ReactForm from 'react-form'

type SelectFieldProps = {
  disabled?: boolean
  options: {
    value: string;
    label: string;
  }[]
}

export class SelectFieldControl extends React.Component<
ReactForm.ComponentProps<string> & SelectFieldProps> {

  render () {

    let api = this.props.fieldApi

    return (
      <Select
        disabled={this.props.disabled}
        value={api.value}
        placeholder={'Kérjük, válasszon...'}
        noResultsText={'Nincs megjeleníthető adat'}
        onChange={this.handleChange.bind(this)}
        options={this.props.options}
      />
    )
  }

  private handleChange (option: ReactSelect.Option) {
    this.props.fieldApi.setValue(option ? (option.value as string) : undefined)
  }
}

export const SelectField = ReactForm.withField<SelectFieldProps & FieldDecorator.FieldLayout, string>(
  FieldDecorator.withFieldDecorator(SelectFieldControl))

export const BareSelectField = ReactForm.withField<SelectFieldProps, string>(SelectFieldControl)
