
export enum EnrollmentCanCloseState {
  none,
  canClose,
  cannotCloseAlreadyClosed,
  cannotCloseHealthDetailsRequired,
  cannotCloseFailed
}

export enum EnrollmentViewState {
  none,
  enrolled,
  expired,
  closed,
  deleted
}

export function formatEnrollmentViewState (state: EnrollmentViewState) {
  switch (state) {
    case EnrollmentViewState.enrolled:
      return 'folyamatban'
    case EnrollmentViewState.closed:
      return 'lezárt'
    case EnrollmentViewState.expired:
      return 'lejárt'
    case EnrollmentViewState.deleted:
      return 'törölt'
    default:
      return '?'
  }
}

export enum EnrollmentViewResult {
  none,
  succeeded,
  failed
}

export function formatEnrollmentViewResult (result: EnrollmentViewResult) {
  switch (result) {
    case EnrollmentViewResult.none:
      return ''
    case EnrollmentViewResult.succeeded:
      return 'sikeres'
    case EnrollmentViewResult.failed:
      return 'sikertelen'
    default:
      return '?'
  }
}
