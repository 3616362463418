import { ProfileInput } from 'person/api'

export enum UpdateMyProfileResultCode {
  success,
  validationError
}

export interface UpdateMyProfileCommand {
  input: ProfileInput
}

export interface UpdateMyProfileCommandResult {
  code: UpdateMyProfileResultCode,
  validation?: string
}
