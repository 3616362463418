import * as React from 'react'
import * as classNames from 'classnames'
import * as FieldDecorator from '../field-decorator'
import * as ReactForm from 'react-form'

type TextareaFieldProps = {
  id?: string
  cols?: number;
  rows?: number;
  maxLength?: number;
  minLength?: number;
  placeholder?: string
  autoComplete?: 'off'
  autoCorrect?: 'off'
  onChange?: (value: string) => void
}

class TextareaFieldControl extends React.Component<ReactForm.ComponentProps<string> & TextareaFieldProps> {

  render () {

    let api = this.props.fieldApi

    let classes = classNames('textarea', {
      'is-success': !!api.success,
      'is-warning': !!api.warning,
      'is-danger': !!api.error
    })

    return (
      <>
        <textarea
          id={this.props.id}
          className={classes}
          cols={this.props.cols}
          rows={this.props.rows}
          maxLength={this.props.maxLength}
          minLength={this.props.minLength}
          placeholder={this.props.placeholder}
          autoComplete={this.props.autoComplete}
          autoCorrect={this.props.autoCorrect}
          value={api.value || ''}
          onChange={e => {
            api.setValue(e.target.value)
            if (this.props.onChange) {
              this.props.onChange(e.target.value)
            }
          }}
          onBlur={e => {
            api.setTouched()
          }}
        />
      </>
    )
  }
}

export const TextareaField =
  ReactForm.withField<TextareaFieldProps & FieldDecorator.FieldLayout, string>(
    FieldDecorator.withFieldDecorator(
      TextareaFieldControl))

export const BareTextAreaField =
  ReactForm.withField<TextareaFieldProps, string>(TextareaFieldControl)
