import * as V from 'validation'

export interface SendContactMessageCommand {
  fullName: string
  email: string
  phone: string
  subject: string
  body: string
  approvePrivacyPolicy: boolean
  captcha: string
}

export enum SendContactMessageResultCode {
  success,
  validationError
}

export interface SendContactMessageCommandResult {
  code: SendContactMessageResultCode,
  validation?: string
}

export interface SendContactMessageValidationResult {
  fullName?: V.RequiredValidationResult | V.StringValidationResult
  email?: V.RequiredValidationResult | V.EmailValidationResult
  phone?: V.RequiredValidationResult | V.StringValidationResult
  subject?: V.RequiredValidationResult | V.StringValidationResult
  body?: V.RequiredValidationResult | V.StringValidationResult
  approvePrivacyPolicy?: V.RequiredValidationResult | V.ExpectedValidationResult
  captcha?: 'invalid-captcha'
}
