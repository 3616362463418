import * as React from 'react'
import * as Form from 'ui/form'
import * as Icon from 'ui/icon'
import * as Validation from 'validation/ui'
import { educationIdValidator, educationIdErrorDisplay } from './education-id-validator'
import * as Api from '../../api'

interface Props {
  field: string
}

export const EducationIdField = (props: Props) => {

  return <Form.InputField
           field={props.field}
           label="Oktatási azonosító:"
           help="7-essel kezdődő, 11 jegyű számsor"
           type="text"
           maxLength={11}
           horizontal={true}
           placeholder="oktatási azonosító"
           validate={Validation.composeValidator(Validation.optionalValidator(educationIdValidator, ''))}
           validationErrorDisplay={Validation.compose(Validation.stringErrorDisplay,
                                                      educationIdErrorDisplay)}
  />
}
