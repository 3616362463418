import { ValidationError } from './validation-error'
import { InputResolver } from './input-resolver'
import * as Api from './api'

export class StringInputResolver implements InputResolver<string, string, Api.StringValidationResult> {

  constructor (private minLength: number,
              private maxLength: number) {
  }

  resolve (input: string) {
    input = input ? input.trim() : ''
    if (input.length < this.minLength) {
      return new ValidationError<Api.StringValidationResult>('tooShort')
    }

    if (input.length > this.maxLength) {
      return new ValidationError<Api.StringValidationResult>('tooLong')
    }

    return input
  }
}

export function string (
  minLength: number,
  maxLength: number): InputResolver<string, string, Api.StringValidationResult> {

  return new StringInputResolver(minLength, maxLength)
}
