import * as React from 'react'
import { NavLink } from 'react-router-dom'
import * as classNames from 'classnames'

export const MenuLinkBottom: React.SFC<{ to: string, exact?: boolean, className?: string }> = (props) => {
  let classes = classNames('navbar-item', props.className)
  return <NavLink
    to={props.to}
    className={classes}
    exact={props.exact} activeClassName="is-active">{props.children}</NavLink>
}
