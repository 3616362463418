import * as React from 'react'
import { Helmet } from 'react-helmet'
const imagesPath = require.context('../../course/ui/frontend/images')
import { SiteMeta } from 'ui/site-meta'

interface Props {
  title: string
  subtitle?: string
  description?: string
  imageUrl?: string
}

export function PageTitle (props: Props) {

  let subtitleText = props.subtitle ? `(${props.subtitle})` : ''
  let subtitleElem = subtitleText ? <p className="content-subtitle">{subtitleText}</p> : null

  let metaTitle = `${props.title} ${subtitleText}`.trim()

  return (
    <div className="touch-view page-title">
      <Helmet>
        <title>{metaTitle}</title>
      </Helmet>
      <SiteMeta
        title={props.title}
        subtitle={props.subtitle}
        description={props.description}
        type="article"
        image={props.imageUrl}
      />
      <div className="media">
        <div className="media-left">
          <figure className="image">
            <img src={imagesPath('./course-logo.png')} />
          </figure>
        </div>
        <div className="media-content">
          <header>
            <h2 className="content-title">{props.title}</h2>
            {subtitleElem}
          </header>
        </div>
      </div>
    </div>
  )
}
