
export enum ValidatePasswordResetTokenResultCode {
  success,
  invalidToken,
  expiredToken
}

export interface ValidatePasswordResetTokenQuery {
  token: string
}

export type ValidatePasswordResetTokenResult = ValidatePasswordResetTokenResultCode
