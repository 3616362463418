import { injectable, inject } from 'inversify'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { ApolloClient } from 'apollo-client'
import { BrowserRouter } from 'react-router-dom'
import { ApolloProvider } from 'react-apollo'
import { UIConfig } from 'ui-config/ui'

@injectable()
export class ReactBootstrap {

  constructor (
    @inject('ApolloClient')
    private apolloClient: ApolloClient<any>,
    @inject('UIConfig')
    private config: UIConfig) {
  }

  start (App: React.ComponentClass<any>) {
    ReactDOM.hydrate(
      <BrowserRouter basename={this.config.baseUrl}>
        <ApolloProvider client={this.apolloClient}><App /></ApolloProvider>
      </BrowserRouter>,
      document.getElementById('app'))
  }
}
