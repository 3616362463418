import * as React from 'react'
import { ErrorPanel } from './error-panel'

interface Props {
  loading: boolean
  onRefresh: () => void
}

export const NetworkErrorDisplay = (props: Props) => {
  return <ErrorPanel
           title="Pillanatnyilag nem érhető el a szerver"
           type="info"
           icon="plug"
           loading={props.loading}
           onRefresh={props.onRefresh}>
      <p>Kérjük, ellenőrizze, hogy az internet kapcsolata nem szakadt-e meg.<br/>
      A lenti gombra kattintva frissítheti az oldalt. Ha a hiba csak pillanatnyi, ez megoldja a problémát.</p>
  </ErrorPanel>
}
