import * as Api from '../api'

export function aliasFieldErrorDisplay (error: string) {
  let asString: Api.AliasFieldValidationResult = error as Api.AliasFieldValidationResult
  if (asString === 'invalidAliasField') {
    return 'Érvénytelen formátum: csak kisbetű, számjegy és kötőjel ("-") engedélyezett!'
  }

  return null
}
