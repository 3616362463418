import * as React from 'react'
import * as classNames from 'classnames'
import { fontAwesome } from 'ui/icon'
import * as FieldDecorator from '../field-decorator'
import * as ReactForm from 'react-form'

type InputFieldProps = {
  id?: string
  type?: string
  maxLength?: number
  leftIcon?: string
  rightIcon?: string
  placeholder?: string
  autoComplete?: 'off' | 'new-password'
  autoCorrect?: 'off'
  onChange?: (value: string) => void
}

class InputFieldControl extends React.Component<ReactForm.ComponentProps<string> & InputFieldProps> {

  render () {

    let api = this.props.fieldApi

    let classes = classNames('input', {
      'is-success': !!api.success,
      'is-warning': !!api.warning,
      'is-danger': !!api.error
    })

    return (
      <>
      <input
        className={classes}
        type={this.props.type}
        maxLength={this.props.maxLength}
        placeholder={this.props.placeholder}
        value={api.value || ''}
        autoComplete={this.props.autoComplete}
        autoCorrect={this.props.autoCorrect}
        onChange={e => {
          api.setValue(e.target.value)
          if (this.props.onChange) {
            this.props.onChange(e.target.value)
          }
        }}
        onBlur={e => {
          api.setTouched()
        }}
      />
      {this.leftIcon()}
      {this.rightIcon(api)}
      </>
    )
  }

  private leftIcon () {
    if (!this.props.leftIcon) {
      return null
    }

    return (<span className="icon is-small is-left">
      <i className={this.props.leftIcon}></i>
    </span>)
  }

  private rightIcon (fieldApi: ReactForm.FieldApi<string>) {

    // TODO: isvalidating
    let isValidating = false
    let icon = null

    if (fieldApi && isValidating) {
      icon = fontAwesome('spinner', 'pulse')
    } else {
      icon = this.props.rightIcon
    }

    if (!icon) {
      return null
    }

    return (<span className="icon is-small is-right">
      <i className={icon}></i>
    </span>)
  }
}

export const InputField =
  ReactForm.withField<InputFieldProps & FieldDecorator.FieldLayout, string>(
    FieldDecorator.withFieldDecorator(
      InputFieldControl))

export const BareInputField =
  ReactForm.withField<InputFieldProps, string>(InputFieldControl)
