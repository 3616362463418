export interface DeleteCodeCommand {
  id: string
}

export enum DeleteCodeResultCode {
  success
}

export interface DeleteCodeCommandResult {
  code: DeleteCodeResultCode,
}
