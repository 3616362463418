import * as React from 'react'

import { ErrorDisplay } from 'error-handler/ui'

interface State {
  error?: Error
}

export class FrontendErrorBoundary extends React.Component<{}, State> {

  constructor (props: {}) {
    super(props)
    this.state = { error: undefined }
  }

  componentDidCatch (error: Error, info: React.ErrorInfo) {

    // TODO: log
    let state: State = {
      error: error
    }

    this.setState(state)
  }

  render () {

    if (this.state.error) {
      return (
        <section className="section">
          <ErrorDisplay
              error={this.state.error}
              loading={false}
              onRefresh={this.onRefresh.bind(this)}
          />
        </section>
      )
    }

    return this.props.children
  }

  private onRefresh () {
    window.location.reload(true)
  }
}
