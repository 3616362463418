import * as React from 'react'
import { FrontpageCourseList } from 'course/ui/frontend'
import { FacebookWidget } from 'facebook/ui'

const imagesPath = require.context('../../ui/frontend/images/ads/')

export class Home extends React.Component {

  advertisements = [
    {
      pic: 'ad-eq-wellness.png',
      link: 'https://www.kepzeskozpont.com/kurzus/2',
      tooltip: 'EQ wellness'
    }
    // {
    //   pic: '',
    //   link: '',
    //   tooltip: 'Másik reklám helye'
    // }
  ]

  render () {

    let adsHtml = this.advertisements.map((item, index) => {
      return this.advertisementBox(item, index)
    })

    return <div>

      <section className="section">
        <div className="columns is-variable is-4">
          {this.courseList()}
          <div className="column kk-ads">
            {adsHtml}
            {this.facebookBox()}
          </div>
        </div>
      </section>

    </div>
  }

  private courseList () {

    return (
      <div className="column is-three-quarters">
        {/* <div className="column is-four-fifths"> */}
        <FrontpageCourseList />
        </div>
    )
  }

  private advertisementBox (ad: any, key: number) {

    let picHtml = this.getPic(ad.pic, ad.tooltip)
    if (!picHtml) {
      return ''
    }

    let linkedPic
    if (ad.link) {
      linkedPic = <a target="_blank" href={ad.link}>{picHtml}</a>
    } else {
      linkedPic = picHtml
    }

    return (
      <div className="field card has-text-centered kk-box-shadow" key={key}>
        <div className="image">
          {linkedPic}
        </div>
      </div>
    )
  }

  private facebookBox () {

    return (
      <div className="field card has-text-centered kk-box-shadow">
        <FacebookWidget />
      </div>
    )
  }

  private getPic (pic: string, title: string) {

    let picHtml

    if (pic) {
      if (pic.indexOf('http://') === 0 || pic.indexOf('https://') === 0 || pic.indexOf('www.') === 0) {
        picHtml = <img src={pic} alt="image" title={title} />
      } else {
        picHtml = <img src={imagesPath('./' + pic)} alt="image" title={title}/>
      }
    } else {
      picHtml = <img src={imagesPath('./empty.png')} alt="image" title={title} />
    }

    return picHtml
  }
}
