export * from './nav-bar/nav-bar'
export * from './nav-bar/nav-menu'
export * from './privacy-policy/privacy-policy'
export * from './service-agreement/service-agreement'
export * from './newsletter/newsletter'
export * from './contact-policy/contact-policy'
export * from './partners/partners'
export * from './references/about-us'
export * from './references/articles'
export * from './home/home'

