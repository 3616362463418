
export enum ConfirmResultCode {
  success,
  invalidToken,
  alreadyConfirmed
}

export interface ConfirmResult {
  code: ConfirmResultCode
  userId: string
}

export interface ConfirmCommand {
  token: string
}
