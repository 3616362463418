import * as Api from '../api'

export function passwordMismatchValidationErrorDisplay (error: string) {
  let asString = error as Api.PasswordMismatchValidationResult
  if (asString === 'passwords-mismatch') {
    return 'A jelszavak nem egyeznek.'
  }

  return null
}
