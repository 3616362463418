import { InputResolver } from '../input-resolver'
import { ValidationError } from '../validation-error'

export function createValidator<TInput, TOutput, TValidationResult> (resolver: InputResolver<TInput, TOutput, TValidationResult>) {

  return function (input: TInput) {
    let result = resolver.resolve(input)
    if (result instanceof ValidationError) {
      return result.error
    }

    return null
  }
}
