import * as React from 'react'
import { Breadcrumb } from 'ui/breadcrumb'
import { PageTitle } from 'ui/page-title'
let Mailto: any = require('react-mailto.js').default
import { ScrollToTopOnMount } from 'ui/scroll'

export class NewsLetter extends React.Component {

  section = 0
  subSection = 0

  render () {

    return <div>

      <ScrollToTopOnMount />
      <Breadcrumb end="Hírlevél tajékoztató" />

      <PageTitle title="Hírlevél tájékoztató" />

      <section className="section">

        <div className="newsletter-page">

        <div className="content">
            
              <p>Az Ön kifejezett külön hozzájárulása esetén a Szolgáltató hírleveleket küld Önnek a jövőben
              induló képzési programjairól, tréningjeiről. Ezen hozzájárulást bármikor költségmentesen
              visszavonhatja a Szolgáltató 1112 Budapest, Felsőhatár út 2/C, C épület, Fsz. 2. szám alatti
              székhelyére küldött postai levél, vagy a <Mailto to = "kepzes@kepzeskozpont.com" secure = {true} >kepzes@kepzeskozpont.com</Mailto> email címre küldött levél útján. 
              A hozzájárulás visszavonása nem érinti a visszavonást megelőző adatkezelés jogszerűségét.</p>

              <p>Hírlevél küldése érdekében a Szolgáltató a hírlevél küldéséhez szükséges adatokat, mégpedig az
              alábbi Személyes Adatokat kezeli:</p>

                <ul>
                  <li>név,<p> Az érintett beazonosítása miatt szükséges megadni.</p></li>
                  <li>e mail<p> A hírlevél küldéséhez szükséges.</p></li>
                  <li>Szakképesítés/Foglakozás<p> A Szolgáltató a szakképesítés alapján eltérő tartalmú hírlevelet küld az érintettnek.</p></li>
                </ul>

              <p>A szakképesítése megadására azért van szükség, mert az egyes szakképesítések alapján eltérő
              tartalmú hírlevél kerül megküldésre (így például egészségügyi szakképesítéssel rendelkező
              személyeknek az egészségszakmai képzésekről szóló hírlevél kerül megküldésre).</p>

              <p>Amennyiben Ön nem ért egyet a kapott hírlevél témájával és ezzel kapcsolatos álláspontját el
              kívánja mondani a Szolgáltatónak, vagy más okból, de kapcsolatban kíván vele lépni, kérjük ezt a
              Szolgáltató 1112 Budapest, Felsőhatár út 2/C, C épület, Fsz.2. szám alatti székhelyére küldött
              postai levél, vagy a(z) <Mailto to = "kepzes@kepzeskozpont.com" secure = {true} >kepzes@kepzeskozpont.com </Mailto> 
              email címre küldött levél útján tegye meg.</p>

              <p>A Személyes Adatok gyűjtése nem terjed ki un. különleges, illeve egészségügyi adatok gyűjtésére,
              kezelésére.</p>

              <p>A hírlevél küldése érdekében való Személyes Adatok kezelésére tehát Info tv. és az Általános
              adatvédelmi rendelet 6. cikk (1) bekezdésének a) pontja, azaz az Ön hozzájárulása alapján kerül
              sor.</p>

              <p>Társaságunk az adatkezelés során az Általános adatvédelmi rendelet és az információs
              önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. törvény
              rendelkezéseinek betartásával, továbbá a Nemzeti Adatvédelmi és Információszabadság Hatóság
              vonatkozó állásfoglalásának figyelembevételével végzi tevékenységét.</p>

              <p>A Szolgáltató a hírlevél küldéséhez adott külön, kifejezett hozzájárulás alapján a Személyes
              Adatokat a hírlevelek küldéséhez szükséges mértékben kezeli.</p>

              <p>A hírlevél küldése érdekében tárolt Személyes Adatokat a Szolgáltató a hozzájárulás
              visszavonásáig, illetve az érintett törlésre irányuló kérelme beérkezéséig tárolja.</p>

              <p>Ön a hírlevélben található „leiratkozom” gombra kattintva is bármikor leiratkozhat a
              hírlevelekről, amely esetben a továbbiakban Önnek nem küldünk hírlevelet.</p>

              <p>Személyes adatok kezelése esetén Önt – az adatkezeléshez adott hozzájárulás visszavonásán
              kívül, ha az adatkezelés jogalapja az Ön hozzájárulása – számos jog megilleti, és azokat bármikor
              gyakorolhatja a Szolgáltató székhelyére, vagy <Mailto to = "kepzes@kepzeskozpont.com" secure = {true} >kepzes@kepzeskozpont.com</Mailto> email címre küldött kérelem útján.</p>

              <p><strong>A személyes adatokhoz való hozzáférés és azok helyesbítésének joga:</strong></p>

                <p>Adataihoz bármikor jogosult hozzáférni, másolatot kérni, továbbá azokat helyesbíteni vagy frissíteni.</p>

                <p>A hozzáférési jog alapján Ön jogosult arra, hogy az alábbiakról információt kapjon:</p>

                  <ol className="alpha">
                    <li>az adatkezelés céljai;</li>
                    <li>az érintett személyes adatok kategóriái;</li>
                    <li>azon címzettek vagy címzettek kategóriái, akikkel, illetve amelyekkel a személyes adatokat közölték vagy közölni fogják, ideértve különösen a harmadik országbeli címzetteket, illetve a nemzetközi szervezeteket;</li>
                    <li>adott esetben a személyes adatok tárolásának tervezett időtartama;</li>
                    <li>az érintett azon joga, hogy kérelmezheti az adatkezelőtől a rá vonatkozó személyes adatok helyesbítését, törlését vagy kezelésének korlátozását, és tiltakozhat az ilyen személyes adatok kezelése ellen;</li>
                    <li>a valamely felügyeleti hatósághoz címzett panasz benyújtásának joga; és</li>
                    <li>az automatizált döntéshozatal ténye (amely jelen esetben a szakképesítés megadása esetén szakképesítésének megfelelő hírlevél küldését).</li>
                  </ol>

              <p><strong>Adathordozhatósághoz való jog</strong></p>

                <p>Személyes adatai hordozhatóak. Ez azt jelenti, hogy azok elektronikusan mozgathatók, másolhatók, vagy továbbíthatók.</p>

              <p><strong>A személyes adatok törlésének joga</strong></p>

                <p>Ön jogosult adatai törlését kérni, amennyiben</p>

                  <ol className="alpha">
                    <li>személyes adataira már nincs szükség azon cél(ok)hoz, amely(ek)re tekintettel azokat begyűjtöttük; vagy</li>
                    <li>visszavonja a személyes adatai kezeléséhez adott korábbi hozzájárulását, és nincs egyéb jogalapja az adatkezelésnek; vagy</li>
                    <li>tiltakozik személyes adatai kezelése ellen;</li>
                    <li>személyes adatok kezelése nem jogszerűen történik; vagy</li>
                    <li>személyes adatainak törlését a jogszabályoknak való megfelelés indokolja.</li>
                  </ol>

                <p>A Szolgáltató nem köteles azonban teljesíteni az Ön kérelmet, amennyiben az adatkezelés szükséges:</p>

                  <ol className="alpha">
                    <li>véleménynyilvánítás szabadságához és a tájékozódáshoz való jog gyakorlása céljából;</li>
                    <li>a személyes adatok kezelését előíró, az adatkezelőre alkalmazandó uniós vagy tagállami jog szerinti kötelezettség teljesítése, illetve közérdekből vagy az adatkezelőre ruházott közhatalmi jogosítvány gyakorlása keretében végzett feladat végrehajtása céljából;</li>
                    <li>a népegészségügy területét érintő közérdek alapján;</li>
                    <li>a közérdekű archiválás céljából, tudományos és történelmi kutatási célból vagy tatisztikai célból, amennyiben az adatok törlése valószínűsíthetően lehetetlenné tenné vagy komolyan veszélyeztetné ezt az adatkezelést; vagy</li>
                    <li>jogi igények előterjesztéséhez, érvényesítéséhez, illetve védelméhez.</li>
                  </ol>

                <p>A fentiekben írtak figyelembevételével amennyiben élni kíván ezen jogával, vegye fel velünk a kapcsolatot a fenti elérhetőségek valamelyikén keresztül.</p>

              <p><strong>Az adatkezelés korlátozásának joga</strong></p>

                <p>Ön abban az esetben korlátozhatja személyes adatainak kezelését, amennyiben</p>

                <ol className="alpha">
                  <li>úgy véli, hogy az Önnel kapcsolatban tárolt személyes adatok nem pontosak; vagy</li>
                  <li>a személyes adatok kezelése nem jogszerűen történik, de ahelyett, hogy a törlésüket kérné, inkább korlátozni szeretné a kezelésüket; vagy</li>
                  <li>személyes adataira nincs többé szükségünk azon cél(ok)hoz, amely(ek) miatt eredetileg begyűjtöttük azokat, Ön azonban igény tart ezen adatokra valamely peres követelés benyújtása, érvényesítése vagy a követeléssel szembeni védekezés céljából vagy</li>
                  <li>Ön tiltakozott személyes adatai kezelése ellen és várja az arra vonatkozó visszaigazolást, hogy az Ön tiltakozással kapcsolatos érdekei felülírják-e az adatkezelés jogalapját.</li>
                </ol>

              <p><strong>A tiltakozás joga</strong></p>

                <p>Ön személyes adatainak kezelése ellen bármikor tiltakozhat.</p>

              <p><strong>Automatizált döntéshozatal</strong></p>

                <p>Mivel az Ön által megadott szakképesítés alapján meghatározzuk, hogy Ön milyen témájú hírlevelet kíván kapni, és Ön ilyen témájú hírlevelet is fog kapni, így Ön jogosult arra, hogy ha Ön nem ért egyet a kapott hírlevél témájával, ezt elmondja nekünk, álláspontját kifejtse, illetve hogy Ön felvegye velünk a kapcsolatot annak érdekében, hogy egy munkatársunk nézze át, hogy megfelelő hírlevél kerül illetve került Önnek megküldésre.</p>

                <p>Ha ezen jogát gyakorolni kívánja, kérjük vegye fel velünk a kapcsolatot a fenti elérhetőségek valamelyikén keresztül.</p>

              <p><strong>Felügyeleti hatósághoz fordulás joga</strong></p>

                <p>Ön bármikor jogosult a Nemzeti Adatvédelmi és Információszabadság Hatósághoz (cím: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.; postacím: 1530 Budapest, Pf.: 5.; telefonszám: +36 (1)391-1400; email cím: <Mailto to = "ugyfelszolgalat@naih.hu" secure = {true} >ugyfelszolgalat@naih.hu </Mailto>), mint felügyeleti hatósághoz.</p>


          </div>

        </div>

      </section>

    </div>
  }

  private sectionHtml (section: number, text: string) {
    this.subSection = 0

    return <h3>{section + '. ' + text}</h3>
  }

  private subSectionHtml (section: number, subSection: number, text: string) {
    return <h5>{section + '.' + subSection + '. ' + text}</h5>
  }
}
