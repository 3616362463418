import { EnrollmentCanCloseState } from './enrollment-view'

export interface CloseEnrollmentCommand {
  enrollmentId: string
}

export enum CloseEnrollmentResultCode {
  success,
  failed
}

export interface CloseEnrollmentCommandResult {
  code: CloseEnrollmentResultCode
  reason: EnrollmentCanCloseState
}
