export enum LoginByPasswordResultCode {
  ok,
  invalidPassword,
  missingUser,
  notConfirmed,
  unknownError
}

export interface LoginByPasswordResult {
  code: LoginByPasswordResultCode
  user: {}
}
